import React, { useState } from "react";
import styles from "./DashboardStepItem.module.css";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const DashboardStepItem = ({ step, info, list, link }) => {

    let infoString = ""
    if (link) {
        infoString = info.replace(link.text, "linkHere")
    } else {
        infoString = info
    }


    let infoArray = infoString.split(" ");
    for (let ctr = 0; ctr < infoArray.length; ctr++) {
        if (infoArray[ctr] == "linkHere") {
            infoArray[ctr] = link.text
        }
    }

    const infoText = useState(infoArray);


    return (
        <li className={styles.stepItem}>
            <div className={styles.stepNumber}>{step}</div>
            <div className={styles.itemContent}>
                {link ? 
                    link.src ? infoArray.map((info, index) => {
                    return (
                        <span key={index}>{info == link.text ? <Link to={link.src}>{link.text}</Link> : info}{" "}</span>
                    )
                }) : infoArray.map((info, index) => {
                    return (
                        <span key={index}>{info == link.text ? <span className={styles.blueText}>{link.text}</span> : info}{" "}</span>
                    )
                })
                : infoArray.map((info, index) => {
                    return (
                        <span key={index}>{info}{" "}</span>
                    )
                })}

            </div>
            {list ?
                <ul className={styles.list}>
                    {list.map((listItem, index) => {
                        return (
                            <li className="listItem" key={index}>
                                {listItem}
                            </li>
                        )
                    })}
                </ul>
                : ""}
        </li>
    )
}

export default DashboardStepItem;

DashboardStepItem.propTypes = {
    step: PropTypes.number.isRequired,
    info: PropTypes.string.isRequired,
    list: PropTypes.array,
    link: PropTypes.shape(
        {
            text: PropTypes.string,
            src: PropTypes.string
        }
    )
}