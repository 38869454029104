import PropTypes from "prop-types";
import styles from "./RequirementItem.module.css";
import classNames from 'classnames';

export default function RequirementItem({ info, onEdit, width, onDelete }) {
  return (
    <div className={styles.requirementRow}>
      <div className={classNames(styles.col)} style={{ width: `${width.nameWidth}px` }} title={info.name}>
        {info.name}
      </div>
      <div className={classNames(styles.col)} style={{ width: `${width.typeWidth}px` }} title={info.type}>
        {info.type}
      </div>
      <div className={classNames(styles.col)} style={{ width: `${width.frequencyWidth}px` }} title={info.freq}>
        {info.freq}
      </div>
      <div className={classNames(styles.col)} style={{ width: `${width.requiredByWidth}px` }} title={info.reqBy}>
        {info.reqBy}
      </div>
      <div className={classNames(styles.col)} style={{ width: `${width.requiredForWidth}px` }} title={info.reqFor}>
        {info.reqFor}
      </div>
      <div className={classNames(styles.col, styles.textCenter)} style={{ width: `${width.createdWidth}px` }} title={info.created}>
        {info.created}
      </div>
      <div className={classNames(styles.col, styles.textCenter)} style={{ width: `${width.retiredWidth}px` }} title={info.retired}>
        {info.retired}
      </div>
      <div className={classNames(styles.col, styles.textCenter)} style={{ width: `${width.statusWidth}px` }} title={info.status}>
        {info.status}
      </div>
      <div className={classNames(styles.col, styles.textCenter)} style={{ width: `${width.editWidth}px` }}>
        <button className={styles.button} onClick={()=> {onEdit(info.id)}}>EDIT</button>
      </div>
      <div className={classNames(styles.col, styles.textCenter)} style={{ width: `${width.deleteWidth}px` }}>
        <button className={styles.deleteBtn} onClick={()=> {onDelete(info.id)}}>DELETE</button>
      </div>
    </div>
  );
};

RequirementItem.propTypes = {
  info: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.string,
    freq: PropTypes.string,
    reqBy: PropTypes.string,
    reqFor: PropTypes.string,
    created: PropTypes.string,
    retired: PropTypes.bool,
    status: PropTypes.bool
  }).isRequired,
  onEdit: PropTypes.func.isRequired
};