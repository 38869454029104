import { useEffect, useState, useMemo } from 'react';
import UsersTable from 'components/DashboardUsersTable/UsersTable';
import { AdminContainer } from 'hooks/useAdmin';
import {Loading} from "@cpnw/ui";

const InactiveUsers = () => {
  const { adminUsersData, adminUsersDataLoading, refetchAdminUsers, updateUsersInactive } = AdminContainer.useContainer();
  const [postLoading, setPostLoading] = useState(false);

	const inactiveUsers = useMemo(() => {
	  return adminUsersData?.InActive ?? [];
	}, [adminUsersData]);

	const [users, setUsers] = useState([]);

	const handleUpdateInactiveUsers = async (updateUserSelections) => {
		setPostLoading(true);
	  if(updateUserSelections === null || updateUserSelections === undefined)
	    return;

	  const convertedData = Object.keys(updateUserSelections)
	    .filter((key) => updateUserSelections[key].selectedValue && updateUserSelections[key].selectedValue !== 'clear')
	    .map((key) => ({
	      inactive: updateUserSelections[key].selectedValue,
	      id: parseInt(key)
	    }));

	  if(convertedData.length === 0){
		setPostLoading(false);
		return;
	  }

	  try {
		const usersUpdateData = await updateUsersInactive(convertedData);
		setUsers(usersUpdateData?.InActive ?? []);
	  } catch (error) {
		console.error('Error updating users:', error);
	  } finally {
		//may not be needed
		// refetchAdminUsers();
		setPostLoading(false);
	  }
	}

	useEffect(() => {
	  if(inactiveUsers && inactiveUsers.length > 0) {
	    setUsers(inactiveUsers);
	} else {
		setUsers([]);
	}
    // Cleanup function
    return () => {
      setUsers([]);
    };
	}, [inactiveUsers]);

  const descriptionRender = (
    <>
    <p>When an account is deactivated it will appear here in an inactive state. You may reactivate accounts by selecting the appropriate option under the activate column and clicking Update. Deactivating removes a user from your active users list and reports.  Deactivation should only be used when a user is taking a break from school but is expected to return.</p>
    <p>User records should be archived when a user graduates or leaves permanent employment from your organization. It is coordinators responsibility to keep your schools records in CPNW up to date. </p>
    </>
  );
  
  return (
	<>
		{adminUsersDataLoading ? (
			<Loading />
		) : (
    		<UsersTable items={users ?? []} onUpdate={handleUpdateInactiveUsers} description={descriptionRender} tableType='inactive' isLoading={postLoading}/>
		)}
	</>
  );
}

export default InactiveUsers;