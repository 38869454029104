import React, { useEffect, useState, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import styles from "./UsersTable.module.css";
import classNames from "classnames";
import DashboardTablePagination from "components/DashboardTablePagination";
import DashboardSortTableHeader from "components/DashboardSortTableHeader";
import ReportUserItem from "components/DashboardReportUserItem";
import { DashboardItemsPerPage } from "components/DashboardItemsPerPage";
import DashboardSearch from "components/DashboardSearch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEraser,
  faUserPlus,
  faArchive,
  faStopCircle,
  faTurnUp,
  faThumbsDown,
} from "@fortawesome/free-solid-svg-icons";
import { Button } from "@cpnw/ui";
import DashboardProfileCorrectionModal from "components/DashboardProfileCorrectionModal";
import { Loading } from "@cpnw/ui";

const UsersTable = ({
  items,
  onUpdate,
  description = null,
  tableType = "active",
  reasonsData = [],
  isLoading,
}) => {
  const [dataLoading, setDataLoading] = useState(isLoading ? isLoading : null);
  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredItems, setFilteredItems] = useState(items);

  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });

  const lastItemIndex = currentPage * pageLimit;
  const firstItemIndex = lastItemIndex - pageLimit;

  const [selectedUserForCorrection, setSelectedUserForCorrection] =
    useState(null);

  const [selections, setSelections] = useState({});
  const handleSelect = (user, value) => {
    setSelections((prevSelections) => ({
      ...prevSelections,
      [user.ContactIDPK]: {
        ...prevSelections[user.ContactIDPK],
        selectedValue: value,
      },
    }));
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const debounce = (func, wait) => {
    let timeout;
    const debouncedFunction = (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };

    debouncedFunction.cancel = () => {
      clearTimeout(timeout);
    };

    return debouncedFunction;
  };

  const handleSearchQueryChange = useCallback(
    debounce((query) => {
      query = query?.toLowerCase();
      const filtered = items.filter((item) => {
        return (
          query === "" ||
          item.NameFirst?.toLowerCase().includes(query) ||
          item.NameLast?.toLowerCase().includes(query) ||
          item.IndividualID?.toLowerCase().includes(query) ||
          item.email?.toLowerCase().includes(query) ||
          item.BusinessUnitAbbrev?.toLowerCase().includes(query) ||
          item.ApprovalDate?.toLowerCase().includes(query) ||
          item.TypeContact?.toLowerCase().includes(query)
        );
      });
      setFilteredItems(filtered);
      setCurrentPage(1); // Reset to first page on new search
    }, 300),
    [items]
  );

  const sortedItems = useMemo(() => {
    let sortableItems = [...filteredItems];
    if (sortConfig.key) {
      sortableItems.sort((a, b) => {
        const aValue = a[sortConfig.key]?.toString().toLowerCase() ?? "";
        const bValue = b[sortConfig.key]?.toString().toLowerCase() ?? "";

        if (aValue < bValue) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [filteredItems, sortConfig]);

  const currentItems = useMemo(() => {
    return sortedItems.slice(firstItemIndex, lastItemIndex);
  }, [sortedItems, firstItemIndex, lastItemIndex]);

  useEffect(() => {
    const handleDebouncedSearch = handleSearchQueryChange(searchQuery);
    setCurrentPage(1); // Reset to first page on new search

    // Cleanup function
    return () => {
      if (handleDebouncedSearch?.cancel) {
        handleDebouncedSearch.cancel();
      }
    };
  }, [searchQuery, handleSearchQueryChange]);

  const handleSortChange = (key, direction) => {
    setSortConfig({ key, direction });
  };

  const handleUpdateBtnSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission

    setDataLoading(true); // Set loading state to true

    try {
      // Simulate loading delay with setTimeout
      await new Promise((resolve) => setTimeout(resolve, 500));

      // Perform the update operation
      await onUpdate(selections);

      // Reset view, clear selections, and change to page 1
      setSelections({});
      setCurrentPage(1);
    } catch (error) {
      // Handle any errors here
      console.error("Update failed:", error);
    } finally {
      // Set loading state to false, regardless of success or failure
      setDataLoading(false);
    }
  };

  const [isModelOpen, setIsModelOpen] = useState(false);

  const handleOpenReasonModel = (user) => {
    const reason = selections[user.ContactIDPK]?.reason || "";
    const additionalReason =
      selections[user.ContactIDPK]?.additionalReason || "";
    setSelectedUserForCorrection({ ...user, reason, additionalReason });
    setIsModelOpen(true);
  };

  const renderActionIcons = () => {
    switch (tableType) {
      case "active":
        return (
          <>
            <div>
              <FontAwesomeIcon icon={faStopCircle} />
              <span style={{ marginLeft: "10px" }}>Deactivate</span>
            </div>
            <div>
              <FontAwesomeIcon icon={faArchive} />
              <span style={{ marginLeft: "10px" }}>Archive</span>
            </div>
            <div>
              <FontAwesomeIcon icon={faEraser} />
              <span style={{ marginLeft: "10px" }}>Clear</span>
            </div>
          </>
        );
      case "inactive":
        return (
          <>
            <div>
              <FontAwesomeIcon icon={faUserPlus} />
              <span style={{ marginLeft: "10px" }}>Activate</span>
            </div>
            <div>
              <FontAwesomeIcon icon={faArchive} />
              <span style={{ marginLeft: "10px" }}>Archive</span>
            </div>
            <div>
              <FontAwesomeIcon icon={faEraser} />
              <span style={{ marginLeft: "10px" }}>Clear</span>
            </div>
          </>
        );
      case "newRequest":
      case "returned":
        return (
          <>
            <div>
              <FontAwesomeIcon icon={faUserPlus} />
              <span style={{ marginLeft: "10px" }}>Activate</span>
            </div>
            <div>
              <FontAwesomeIcon
                icon={faTurnUp}
                style={{ transform: "rotate(270deg)" }}
              />
              <span style={{ marginLeft: "10px" }}>Return</span>
            </div>
            <div>
              <FontAwesomeIcon icon={faThumbsDown} />
              <span style={{ marginLeft: "10px" }}>Decline</span>
            </div>
            <div>
              <FontAwesomeIcon icon={faEraser} />
              <span style={{ marginLeft: "10px" }}>Clear</span>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  const handleOnReasonSelectChange = (reason) => {
    //console.log('reason', reason);
  };

  const handleOnAdditionReasonChange = (e) => {
    //console.log(e.target.value);
  };

  const handleOnProfileCorrectionSet = (selectedReason, additionalReason) => {
    if (selectedUserForCorrection) {
      setSelections((prevSelections) => ({
        ...prevSelections,
        [selectedUserForCorrection.ContactIDPK]: {
          ...prevSelections[selectedUserForCorrection.ContactIDPK],
          reason: selectedReason,
          additionalReason: additionalReason,
        },
      }));
    }
    setIsModelOpen(false);
    setSelectedUserForCorrection(null);
  };

  const handleSetPageLimit = (limit) => {
    setPageLimit(limit);
    setCurrentPage(1);
  };

  return (
    <div className={styles.usersTableContainer}>
      <DashboardProfileCorrectionModal
        isOpen={isModelOpen}
        onClose={() => setIsModelOpen(false)}
        selectOptions={reasonsData?.map((reason) => reason.text)}
        onSelectChange={handleOnReasonSelectChange}
        onTextChange={handleOnAdditionReasonChange}
        onSet={handleOnProfileCorrectionSet}
        reason={selectedUserForCorrection?.reason || ""}
        additionalReason={selectedUserForCorrection?.additionalReason || ""}
      />
      <div className={styles.actionContainer}>
        <span>Key:</span>
        {renderActionIcons()}
      </div>

      <div className={styles.descriptionContainer}>{description ?? null}</div>

      <div className={styles.tableContainer}>
        {dataLoading && (
          <div className={styles.loadingContainer}>
            <Loading />
          </div>
        )}

        <div
          className={classNames(styles.table_upper_row)}
          style={dataLoading ? { pointerEvents: "none" } : {}}
        >
          <DashboardItemsPerPage
            disabled={false}
            limitOptions={[10, 25, 50, 100]}
            onLimitChange={handleSetPageLimit}
            limit={pageLimit}
          />
          <DashboardSearch onInput={setSearchQuery} />
        </div>
        <div
          className={styles.tableWrapper}
          style={dataLoading ? { pointerEvents: "none" } : {}}
        >
          <table className={classNames(styles.customTable)}>
            <thead className={styles.customTableHeader}>
              <tr className={styles.customTableHeaderRow}>
                <th className={styles.smallColumn}>
                  {tableType === "active" && (
                    <DashboardSortTableHeader
                      value={
                        sortConfig.key === "deactivate"
                          ? sortConfig.direction
                          : ""
                      }
                      onChange={(direction) =>
                        handleSortChange("deactivate", direction)
                      }
                      title={<FontAwesomeIcon icon={faStopCircle} />}
                      // width={10}
                    />
                  )}
                  {tableType !== "active" && (
                    <DashboardSortTableHeader
                      value={
                        sortConfig.key === "activate"
                          ? sortConfig.direction
                          : ""
                      }
                      onChange={(direction) =>
                        handleSortChange("activate", direction)
                      }
                      title={<FontAwesomeIcon icon={faUserPlus} />}
                      // width={10}
                    />
                  )}
                </th>
                <th className={styles.smallColumn}>
                  {(tableType === "active" || tableType === "inactive") && (
                    <DashboardSortTableHeader
                      value={
                        sortConfig.key === "archive" ? sortConfig.direction : ""
                      }
                      onChange={(direction) =>
                        handleSortChange("archive", direction)
                      }
                      title={<FontAwesomeIcon icon={faArchive} />}
                      // width={10}
                    />
                  )}
                  {(tableType === "newRequest" || tableType === "returned") && (
                    <DashboardSortTableHeader
                      value={
                        sortConfig.key === "returned"
                          ? sortConfig.direction
                          : ""
                      }
                      onChange={(direction) =>
                        handleSortChange("returned", direction)
                      }
                      title={
                        <FontAwesomeIcon
                          icon={faTurnUp}
                          style={{
                            transform: "rotate(270deg)",
                          }}
                        />
                      }
                      // width={10}
                    />
                  )}
                </th>
                {(tableType === "newRequest" || tableType === "returned") && (
                  <th>
                    <DashboardSortTableHeader
                      value={
                        sortConfig.key === "deny" ? sortConfig.direction : ""
                      }
                      onChange={(direction) =>
                        handleSortChange("deny", direction)
                      }
                      title={<FontAwesomeIcon icon={faThumbsDown} />}
                      // width={10}
                    />
                  </th>
                )}
                <th className={styles.smallColumn}>
                  <DashboardSortTableHeader
                    value={
                      sortConfig.key === "clear" ? sortConfig.direction : ""
                    }
                    onChange={(direction) =>
                      handleSortChange("clear", direction)
                    }
                    title={<FontAwesomeIcon icon={faEraser} />}
                    // width={10}
                  />
                </th>
                {(tableType === "newRequest" || tableType === "returned") && (
                  <th>
                    <DashboardSortTableHeader
                      title={"Return/Rejection Reason"}
                      value={
                        sortConfig.key === "Reason" ? sortConfig.direction : ""
                      }
                      onChange={(direction) =>
                        handleSortChange("Reason", direction)
                      }
                      width={220}
                    />
                  </th>
                )}
                <th>
                  <DashboardSortTableHeader
                    value={
                      sortConfig.key === "NameLast" ? sortConfig.direction : ""
                    }
                    onChange={(direction) =>
                      handleSortChange("NameLast", direction)
                    }
                    title="Last Name"
                    width={130}
                  />
                </th>
                <th>
                  <DashboardSortTableHeader
                    value={
                      sortConfig.key === "NameFirst" ? sortConfig.direction : ""
                    }
                    onChange={(direction) =>
                      handleSortChange("NameFirst", direction)
                    }
                    title="First Name"
                    width={130}
                  />
                </th>
                <th>
                  <DashboardSortTableHeader
                    value={
                      sortConfig.key === "IndividualID"
                        ? sortConfig.direction
                        : ""
                    }
                    onChange={(direction) =>
                      handleSortChange("IndividualID", direction)
                    }
                    title="SID-EID"
                    width={110}
                  />
                </th>
                <th>
                  <DashboardSortTableHeader
                    value={
                      sortConfig.key === "email" ? sortConfig.direction : ""
                    }
                    onChange={(direction) =>
                      handleSortChange("email", direction)
                    }
                    title="Email"
                    width={250}
                  />
                </th>
                <th>
                  <DashboardSortTableHeader
                    value={
                      sortConfig.key === "BusinessUnitIDFK"
                        ? sortConfig.direction
                        : ""
                    }
                    onChange={(direction) =>
                      handleSortChange("BusinessUnitIDFK", direction)
                    }
                    title="Program"
                    width={100}
                  />
                </th>
                <th className={styles.removePadding}>
                  {(tableType === "newRequest" || tableType === "returned") && (
                    <DashboardSortTableHeader
                      value={
                        sortConfig.key === "Created" ? sortConfig.direction : ""
                      }
                      onChange={(direction) =>
                        handleSortChange("Created", direction)
                      }
                      title="Created"
                      width={120}
                    />
                  )}
                  {(tableType === "active" || tableType === "inactive") && (
                    <DashboardSortTableHeader
                      value={
                        sortConfig.key === "ApprovalDate"
                          ? sortConfig.direction
                          : ""
                      }
                      onChange={(direction) =>
                        handleSortChange("ApprovalDate", direction)
                      }
                      title="Approval Date"
                      width={130}
                    />
                  )}
                </th>
                <th className={styles.removePadding}>
                  <DashboardSortTableHeader
                    value={
                      sortConfig.key === "TypeContactIDFK"
                        ? sortConfig.direction
                        : ""
                    }
                    onChange={(direction) =>
                      handleSortChange("TypeContactIDFK", direction)
                    }
                    title="User Type"
                    width={120}
                  />
                </th>
                <th>
                  <DashboardSortTableHeader
                    value={
                      sortConfig.key === "BackgroundCheck"
                        ? sortConfig.direction
                        : ""
                    }
                    onChange={(direction) =>
                      handleSortChange("BackgroundCheck", direction)
                    }
                    title="Background Check"
                    width={150}
                  />
                </th>
                <th>
                  <DashboardSortTableHeader
                    value={
                      sortConfig.key === "ReportStatus"
                        ? sortConfig.direction
                        : ""
                    }
                    onChange={(direction) =>
                      handleSortChange("ReportStatus", direction)
                    }
                    title="Report Status"
                    width={120}
                  />
                </th>
                <th>
                  <DashboardSortTableHeader
                    value={
                      sortConfig.key === "ReportETA" ? sortConfig.direction : ""
                    }
                    onChange={(direction) =>
                      handleSortChange("ReportETA", direction)
                    }
                    title="Report ETA"
                    width={120}
                  />
                </th>
                <th>
                  <DashboardSortTableHeader
                    value={
                      sortConfig.key === "BackgroundCheckURL"
                        ? sortConfig.direction
                        : ""
                    }
                    onChange={(direction) =>
                      handleSortChange("BackgroundCheckURL", direction)
                    }
                    title="Check URL"
                    width={100}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr></tr>
              {currentItems.length === 0 ? (
                <tr>
                  <td colSpan="13" className={styles.noData}>
                    No data available in table
                  </td>
                </tr>
              ) : (
                currentItems.map((item, index) => (
                  <tr
                    key={index}
                    className={classNames(
                      styles.customTableRow,
                      index % 2 === 1 && styles.even
                    )}
                  >
                    <ReportUserItem
                      user={item}
                      selected={
                        selections[item.ContactIDPK]?.selectedValue || ""
                      }
                      onSelect={handleSelect}
                      itemType={tableType}
                      onClickOpenReasonModel={(user) =>
                        handleOpenReasonModel(user)
                      }
                      reason={selections[item.ContactIDPK]?.reason || ""}
                      additionalReason={
                        selections[item.ContactIDPK]?.additionalReason || ""
                      }
                    />
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>

        <div
          className={styles.pagingContainer}
          style={dataLoading ? { pointerEvents: "none" } : {}}
        >
          <DashboardTablePagination
            onPageChange={handlePageChange}
            total={filteredItems.length}
            perPage={pageLimit}
            currentPage={currentPage}
          />
        </div>
      </div>
      <div
        className={classNames(styles.submitButtonContainer, {
          [styles.hover]: !isLoading,
        })}
      >
        <Button
          onClick={handleUpdateBtnSubmit}
          text={"UPDATE"}
          disabled={dataLoading}
        />
      </div>
    </div>
  );
};

export default UsersTable;

UsersTable.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      sid: PropTypes.string,
      email: PropTypes.string,
      program: PropTypes.string,
      approvalDate: PropTypes.string,
      userType: PropTypes.string,
      backgroundCheck: PropTypes.string,
      reportStatus: PropTypes.string,
      reportETA: PropTypes.string,
      backgroundCheckURL: PropTypes.string,
    })
  ).isRequired,
  onUpdate: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

/*



*/
