import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import * as XLSX from "xlsx";
import styles from "./DashboardOIGSAMAdminTable.module.css";
import { Button, SelectBox } from "@cpnw/ui";
import { DashboardItemsPerPage } from "components/DashboardItemsPerPage";
import DashboardSearch from "components/DashboardSearch";
import DashboardTablePagination from "components/DashboardTablePagination";
import DashboardSortTableHeader from "components/DashboardSortTableHeader";
import { DashboardOIGSAMAdminItem } from "components/DashboardOIGSAMAdminItem";
import { format, parseISO } from "date-fns";

export const DashboardOIGSAMAdminTable = ({
  items,
  filters
}) => {

  const [reports, setReports] = useState(items);
  const TITLE_COLUMN_SCAN_DATE = "Scan Date";
  const TITLE_COLUMN_NAME_INPUT = "NAME INPUT";
  const TITLE_COLUMN_PROGRAM = "Program";
  const TITLE_COLUMN_sidEid = "SID-EID";
  const TITLE_COLUMN_AGENCY = "AGENCY";
  const TITLE_COLUMN_SCAN_RESULT = "SCAN RESULT";
  const TITLE_COLUMN_MESSAGE = "MESSAGE";
  const TITLE_COLUMN_USER_TYPE = "USERTYPE";

  const TITLE_COLUMN_EMAIL = "Email";
  const TITLE_COLUMN_PHONE = "Phone";
  const TITLE_COLUMN_ADDRESS = "Address";
  const TITLE_COLUMN_EMERGENCY_CONTACT = "Emergency Contact";
  const TITLE_COLUMN_EMERGENCY_CONTACT_PHONE = "Emergency Contact Phone";
  const TITLE_COLUMN_SCHOOL = "School";

  // Add _isShowAll, _isChecked, _isUnCheckedInSelecteMode properties to each report
  const [rows, setRows] = useState([]);

  const [filterName, setFilterName] = useState("");
  const [filterProgram, setFilterProgram] = useState("");
  const [filterAgency, setFilterAgency] = useState("");
  const [filterScanResult, setFilterScanResult] = useState("");
  const [filterUserType, setFilterUserType] = useState("");

  const [disableShowSelectedButton, setDisableShowSelectedButton] =
    useState(true);

  const [showSelectedOnly, setShowSelectedOnly] = useState(false);

  const [sortingDirection, setSortingDirection] = useState("asc");
  const [sortingColumn, setSortingColumn] = useState(TITLE_COLUMN_NAME_INPUT);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [limit, setLimit] = useState(10);

  const scrollContainers = useRef([]);

  useEffect(() => {
    let updateAssignments = onFilter(filterName, filterProgram, filterAgency, filterScanResult, filterUserType);

    // Apply sorting to the filtered data
    updateAssignments = updateAssignments.sort((a, b) => {
      const replaceNullValue = (value) => (value ? value : "");

      switch (sortingDirection) {
        case "asc":
          switch (sortingColumn) {
            case TITLE_COLUMN_SCAN_DATE:
              return replaceNullValue(a.scanDate).localeCompare(
                replaceNullValue(b.scanDate)
              );
            case TITLE_COLUMN_NAME_INPUT:
              return replaceNullValue(a.nameInput).localeCompare(
                replaceNullValue(b.nameInput)
              );
            case TITLE_COLUMN_sidEid:
              return replaceNullValue(a.sidEid).localeCompare(
                replaceNullValue(b.sidEid)
              );
            case TITLE_COLUMN_USER_TYPE:
              return replaceNullValue(a.userType).localeCompare(
                replaceNullValue(b.userType)
              );
            case TITLE_COLUMN_PROGRAM:
              return replaceNullValue(a.program).localeCompare(
                replaceNullValue(b.program)
              );
            case TITLE_COLUMN_AGENCY:
              return replaceNullValue(a.agency).localeCompare(
                replaceNullValue(b.agency)
              );
            case TITLE_COLUMN_SCAN_RESULT:
              return replaceNullValue(a.scanResult).localeCompare(
                replaceNullValue(b.scanResult)
              );
            case TITLE_COLUMN_MESSAGE:
              return replaceNullValue(a.message).localeCompare(
                replaceNullValue(b.message)
              );
            default:
              return replaceNullValue(a.name).localeCompare(
                replaceNullValue(b.name)
              );
          }
        case "desc":
          switch (sortingColumn) {
            case TITLE_COLUMN_SCAN_DATE:
              return replaceNullValue(b.scanDate).localeCompare(
                replaceNullValue(a.scanDate)
              );
            case TITLE_COLUMN_NAME_INPUT:
              return replaceNullValue(b.nameInput).localeCompare(
                replaceNullValue(a.nameInput)
              );
            case TITLE_COLUMN_sidEid:
              return replaceNullValue(b.sidEid).localeCompare(
                replaceNullValue(a.sidEid)
              );
            case TITLE_COLUMN_USER_TYPE:
              return replaceNullValue(b.userType).localeCompare(
                replaceNullValue(a.userType)
              );
            case TITLE_COLUMN_PROGRAM:
              return replaceNullValue(b.program).localeCompare(
                replaceNullValue(a.program)
              );
            case TITLE_COLUMN_AGENCY:
              return replaceNullValue(b.agency).localeCompare(
                replaceNullValue(a.agency)
              );
            case TITLE_COLUMN_SCAN_RESULT:
              return replaceNullValue(b.scanResult).localeCompare(
                replaceNullValue(a.scanResult)
              );
            case TITLE_COLUMN_MESSAGE:
              return replaceNullValue(b.message).localeCompare(
                replaceNullValue(a.message)
              );
            default:
              return replaceNullValue(b.name).localeCompare(
                replaceNullValue(a.name)
              );
          }
        default:
          return 0;
      }
    });

    if (showSelectedOnly) {
      updateAssignments = updateAssignments.filter(
        (report) =>
          report._isChecked === true ||
          report._isUnCheckedInSelecteMode === true
      );
    }

    const seletedAssignments = updateAssignments.filter(
      (report) => report._isChecked === true
    );
    // Check ShowSelectedButton disabled state
    const hasSelectedRows = seletedAssignments.length > 0;
    setDisableShowSelectedButton(!hasSelectedRows);

    setTotalItems(updateAssignments.length);

    const startIndex = (currentPage - 1) * limit;
    const endIndex = Math.min(
      startIndex + limit - 1,
      updateAssignments.length - 1
    );
    setRows(updateAssignments.slice(startIndex, endIndex + 1));
  }, [
    reports,
    showSelectedOnly,
    filterName,
    filterProgram,
    filterAgency,
    filterScanResult,
    filterUserType,
    sortingColumn,
    sortingDirection,
    currentPage,
    limit,
  ]);

  const onLimitChange = (newLimit) => {
    setLimit(newLimit);
    // Reset current page to 1 when the limit changes
    setCurrentPage(1);
  };

  /** Function to filter reports based on name, userType, program, location, startDate */
  const onFilter = (name, program, agency, scanResult, userType) => {
    return reports.filter((report) => {
      // Convert filter values and report properties to lowercase for case-insensitive comparison
      const reportName = report.nameInput?.toLowerCase();
      const reportProgram = report.program?.toLowerCase();
      const reportAgency = report.agency?.toLowerCase();
      const reportScanResult = report.scanResult?.toLowerCase();
      const reportUserType = report.userType?.toLowerCase();
      // Check if name matches
      if (name && !reportName.includes(name.toLowerCase())) {
        return false;
      }
      if (program && !reportProgram.includes(program.toLowerCase())) {
        return false;
      }
      if (agency && !reportAgency.includes(agency.toLowerCase())) {
        return false;
      }
      if (scanResult && !reportScanResult.includes(scanResult.toLowerCase())) {
        return false;
      }
      if (userType && !reportUserType.includes(userType.toLowerCase())) {
        return false;
      }
      // If all conditions pass, return true to include the report
      return true;
    });
  };

  const onSortChange = (columnName, direction) => {
    // Update sorting direction state for the respective column
    setSortingColumn(columnName);
    setSortingDirection(direction);
  };

  function onSelect(data, isChecked) {
    const updatedAssignments = reports.map((report) =>
      report.id === data.id ? { ...report, _isChecked: isChecked } : report
    );
    setReports(updatedAssignments);
  }

  // show only the selected items when the "Show Selected" button is clicked
  const onShowSelected = () => {
    setShowSelectedOnly(true);
    setCurrentPage(1);
  };

  // show all items when the "Show All" button is clicked
  const onShowAll = () => {
    setShowSelectedOnly(false);
    setCurrentPage(1);
  };

  const onExportToExcel = () => {
    const headers = [
      " ",
      TITLE_COLUMN_SCAN_DATE,
      TITLE_COLUMN_NAME_INPUT,
      TITLE_COLUMN_PROGRAM,
      TITLE_COLUMN_sidEid,
      TITLE_COLUMN_AGENCY,
      TITLE_COLUMN_SCAN_RESULT,
      TITLE_COLUMN_MESSAGE,
      TITLE_COLUMN_USER_TYPE,
      TITLE_COLUMN_EMAIL,
      TITLE_COLUMN_PHONE,
      TITLE_COLUMN_ADDRESS,
      TITLE_COLUMN_EMERGENCY_CONTACT,
      TITLE_COLUMN_EMERGENCY_CONTACT_PHONE,
      TITLE_COLUMN_SCHOOL,
    ];

    const wb = XLSX.utils.book_new();
    const wsData = [
      headers,
      ...(showSelectedOnly
        ? reports.filter((report) => report._isChecked === true)
        : reports
      )
        .slice(0)
        .map((item) => [
          "",
          formatDate(item.scanDate),
          item.nameInput,
          item.program,
          item.sidEid,
          item.agency,
          item.scanResult,
          item.message,
          item.userType,
          item.contact.email,
          item.contact.phone,
          item.contact.address,
          item.contact.emergencyContact,
          item.contact.emergencyPhone,
          item.contact.facilityName,
        ]),
    ];
    const ws = XLSX.utils.aoa_to_sheet(wsData);

    // Adjust column widths
    const columnWidths = [
      { wpx: 30 }, // CheckBox column width
      { wpx: 80 }, // Scan Date column width
      { wpx: 150 }, // Name column width
      { wpx: 80 }, // Program column width
      { wpx: 80 }, // SID-EID column width
      { wpx: 80 }, // Agency column width
      { wpx: 80 }, // ScanResult column width
      { wpx: 100 }, // Message column width
      { wpx: 80 }, // UserType column width
      { wpx: 80 }, // Email column width
      { wpx: 80 }, // Phone column width
      { wpx: 80 }, // Address column width
      { wpx: 100 }, // Emergency Contact column width
      { wpx: 100 }, // Emergency Contact Phone column width
      { wpx: 80 }, // School column width
    ];
    ws["!cols"] = columnWidths;

    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'Exclusion Reports - CPNW.xlsx');
  }

  const onPageChange = (e) => {
    setCurrentPage(e);
  };

  const handleScroll = (index) => {
    const scrollLeft = scrollContainers.current[index].scrollLeft;
    scrollContainers.current.forEach((container, i) => {
      if (i !== index) {
        container.scrollLeft = scrollLeft;
      }
    });
  };

  function formatDate(dateString) {
    // Check if item.startDate is not null or undefined
    if (dateString) {
      const utcDateString = dateString;

      // Convert the UTC date to a Date object
      const date = new Date(utcDateString);

      // Extract components
      const month = String(date.getUTCMonth() + 1).padStart(2, "0");
      const day = String(date.getUTCDate()).padStart(2, "0");
      const year = date.getUTCFullYear();

      // Format to "MM-dd-yyyy"
      const formattedDate = `${month}-${day}-${year}`;
      return formattedDate;
    }
  }

  console.log(filters);

  return (
    <div className={styles.mainContainer}>
      <h1>OIG SAM Excluded Provider Check</h1>
      <div><ul className={styles.inlineList}>
        {filters.schoolName && (<li><strong>School Name:</strong> {filters.schoolName} </li>)}
        <li><strong>Program Name:</strong> {filters.programName} </li>
      </ul>
      </div>

      <div className={styles.filterTopContainer}>
        <div className={styles.itemsPerPageSelectBox}>
          <DashboardItemsPerPage
            disabled={false}
            limit={limit}
            limitOptions={[10, 25, 50, 100]}
            onLimitChange={onLimitChange}
          />
        </div>
        <div className={styles.buttons}>
          <Button
            onClick={() => onShowAll()}
            text="Show All"
            disabled={false}
          />
          <Button
            onClick={() => onShowSelected()}
            text="Show Selected"
            disabled={disableShowSelectedButton}
          />
          <Button
            onClick={() => onExportToExcel()}
            text="Export"
            disabled={false}
          />
        </div>
        <div className={styles.inputSearch}>
          <DashboardSearch
            onInput={(input) => {
              setFilterName(input);
              setCurrentPage(1);
            }}
            searchDelay={0}
            minChar={0}
          />
        </div>
      </div>

      <div className={styles.tableContainer}>
        <div
          className={classNames(styles.scrollContainer, styles.headerContainer)}
          style={{ overflow: "hidden" }}
          ref={(el) => (scrollContainers.current[0] = el)}
          onScroll={() => handleScroll(0)}
        >
          <div className={classNames(styles.columnCheckBox, styles.content)}>
            &nbsp;&nbsp;
          </div>
          <div
            className={classNames(
              styles.columnIndex0,
              styles.content,
              styles.header
            )}
          >
            <DashboardSortTableHeader
              title={TITLE_COLUMN_SCAN_DATE}
              value={
                sortingColumn == TITLE_COLUMN_SCAN_DATE ? sortingDirection : ""
              }
              onChange={(direction) =>
                onSortChange(TITLE_COLUMN_SCAN_DATE, direction)
              }
              className={classNames(
                sortingDirection === "asc" ? styles.active : styles.inactive
              )}
            />
          </div>
          <div
            className={classNames(
              styles.columnIndex1,
              styles.content,
              styles.header
            )}
          >
            <DashboardSortTableHeader
              title={TITLE_COLUMN_NAME_INPUT}
              value={
                sortingColumn == TITLE_COLUMN_NAME_INPUT ? sortingDirection : ""
              }
              onChange={(direction) =>
                onSortChange(TITLE_COLUMN_NAME_INPUT, direction)
              }
              className={classNames(
                sortingDirection === "asc" ? styles.active : styles.inactive
              )}
            />
          </div>
          <div
            className={classNames(
              styles.columnIndex2,
              styles.content,
              styles.header
            )}
          >
            <DashboardSortTableHeader
              title={TITLE_COLUMN_PROGRAM}
              value={
                sortingColumn == TITLE_COLUMN_PROGRAM ? sortingDirection : ""
              }
              onChange={(direction) =>
                onSortChange(TITLE_COLUMN_PROGRAM, direction)
              }
              className={classNames(
                sortingDirection === "asc" ? styles.active : styles.inactive
              )}
            />
          </div>
          <div
            className={classNames(
              styles.columnIndex3,
              styles.content,
              styles.header
            )}
          >
            <DashboardSortTableHeader
              title={TITLE_COLUMN_sidEid}
              value={
                sortingColumn == TITLE_COLUMN_sidEid ? sortingDirection : ""
              }
              onChange={(direction) =>
                onSortChange(TITLE_COLUMN_sidEid, direction)
              }
              className={classNames(
                sortingDirection === "asc" ? styles.active : styles.inactive
              )}
            />
          </div>
          <div
            className={classNames(
              styles.columnIndex4,
              styles.content,
              styles.header
            )}
          >
            <DashboardSortTableHeader
              title={TITLE_COLUMN_AGENCY}
              value={
                sortingColumn == TITLE_COLUMN_AGENCY ? sortingDirection : ""
              }
              onChange={(direction) =>
                onSortChange(TITLE_COLUMN_AGENCY, direction)
              }
              className={classNames(
                sortingDirection === "asc" ? styles.active : styles.inactive
              )}
            />
          </div>
          <div
            className={classNames(
              styles.columnIndex5,
              styles.content,
              styles.header
            )}
          >
            <DashboardSortTableHeader
              title={TITLE_COLUMN_SCAN_RESULT}
              value={
                sortingColumn == TITLE_COLUMN_SCAN_RESULT
                  ? sortingDirection
                  : ""
              }
              onChange={(direction) =>
                onSortChange(TITLE_COLUMN_SCAN_RESULT, direction)
              }
              className={classNames(
                sortingDirection === "asc" ? styles.active : styles.inactive
              )}
            />
          </div>
          <div
            className={classNames(
              styles.columnIndex6,
              styles.content,
              styles.header
            )}
          >
            <DashboardSortTableHeader
              title={TITLE_COLUMN_MESSAGE}
              value={
                sortingColumn == TITLE_COLUMN_MESSAGE ? sortingDirection : ""
              }
              onChange={(direction) =>
                onSortChange(TITLE_COLUMN_MESSAGE, direction)
              }
              className={classNames(
                sortingDirection === "asc" ? styles.active : styles.inactive
              )}
            />
          </div>
          <div
            className={classNames(
              styles.columnIndex7,
              styles.content,
              styles.header
            )}
          >
            <DashboardSortTableHeader
              title={TITLE_COLUMN_USER_TYPE}
              value={
                sortingColumn == TITLE_COLUMN_USER_TYPE ? sortingDirection : ""
              }
              onChange={(direction) =>
                onSortChange(TITLE_COLUMN_USER_TYPE, direction)
              }
              className={classNames(
                sortingDirection === "asc" ? styles.active : styles.inactive
              )}
            />
          </div>
        </div>
        <div
          className={styles.scrollContainer}
          ref={(el) => (scrollContainers.current[1] = el)}
          onScroll={() => handleScroll(1)}
        >
          {rows.length === 0 ? (
            <div className={classNames(styles.row, styles.textCenter)}>
              No data available in table
            </div>
          ) : (
            rows.map((report, index) => (
              <DashboardOIGSAMAdminItem
                key={report.id}
                selected={report._isChecked}
                onSelect={onSelect}
                report={report}
                isEvenRow={index % 2 !== 0}
              />
            ))
          )}
        </div>
        <div
          className={classNames(styles.scrollContainer, styles.footerContainer)}
          style={{ overflow: "hidden" }}
          ref={(el) => (scrollContainers.current[2] = el)}
          onScroll={() => handleScroll(2)}
        >
          <div className={classNames(styles.columnCheckBox, styles.content)}>
            &nbsp;&nbsp;
          </div>
          <div
            className={classNames(styles.columnIndex0, styles.content)}
          ></div>
          <div
            className={classNames(styles.columnIndex1, styles.content)}
          ></div>
          <div className={classNames(styles.columnIndex2, styles.content)}>
            <SelectBox
              onChange={(value) => {
                setFilterProgram(value);
                setCurrentPage(1);
              }}
              placeholder={""}
              options={[...new Set(reports.map((item) => item.program))]
                .filter(Boolean)
                .sort()}
            />
          </div>
          <div
            className={classNames(styles.columnIndex3, styles.content)}
          ></div>
          <div className={classNames(styles.columnIndex4, styles.content)}>
            <SelectBox
              onChange={(value) => {
                setFilterAgency(value);
                setCurrentPage(1);
              }}
              placeholder={""}
              options={[...new Set(reports.map((item) => item.agency))]
                .filter(Boolean)
                .sort()}
            />
          </div>
          <div className={classNames(styles.columnIndex5, styles.content)}>
            <SelectBox
              onChange={(value) => {
                setFilterScanResult(value);
                setCurrentPage(1);
              }}
              placeholder={""}
              options={[...new Set(reports.map((item) => item.scanResult))]
                .filter(Boolean)
                .sort()}
            />
          </div>
          <div
            className={classNames(styles.columnIndex6, styles.content)}
          ></div>
          <div className={classNames(styles.columnIndex7, styles.content)}>
            <SelectBox
              onChange={(value) => {
                setFilterUserType(value);
                setCurrentPage(1);
              }}
              placeholder={""}
              options={[...new Set(reports.map((item) => item.userType))]
                .filter(Boolean)
                .sort()}
            />
          </div>
        </div>
      </div>

      <div className={classNames(styles.paginationContainer)}>
        <div className={classNames(styles.columnPagination)}>
          <DashboardTablePagination
            onPageChange={(e) => onPageChange(e)}
            total={totalItems}
            currentPage={currentPage}
            isAutoSetPage={false}
            perPage={limit}
          />
        </div>
      </div>
    </div>
  );
};

DashboardOIGSAMAdminTable.propTypes = {
  reports: PropTypes.array.isRequired,
};

export default DashboardOIGSAMAdminTable;
