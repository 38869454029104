import React from "react";
import classNames from "classnames";
import styles from "./DashboardNavDropdown.module.css";
import PropTypes from "prop-types";
import DashboardNavItem from "components/DashboardNavItem";

const DashboardNavDropdown = ({
  className,
  header,
  links,
  onClick,
  droppedDown,
}) => {
  return (
    <div className={classNames([className, styles.dropdown])}>
      <a className={styles.header} onClick={onClick}>
        {header}
      </a>
      {droppedDown && (
        <ul>
          {links.map((link, index) => {
            return (
              <li key={index}>
                <DashboardNavItem
                  name={link.name}
                  src={link.src}
                  tip={link.tip}
                  active={link.active}
                />
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default DashboardNavDropdown;

DashboardNavDropdown.propTypes = {
  className: PropTypes.string,
  header: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      src: PropTypes.string.isRequired,
      tip: PropTypes.string,
      active: PropTypes.bool
    })
  ),
  onClick: PropTypes.func.isRequired,
  droppedDown: PropTypes.bool,
};
