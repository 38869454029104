import React, { useMemo } from 'react';
import styles from "./ELearning.module.css";

import { HeaderSection } from "@cpnw/ui"
import DashboardLearningList from '../../../../components/DashboardLearningList';
import { CpnwContainer } from 'hooks/useCpnw';

export default function ELearning(){
    const {learningModulesData, takeQuiz} = CpnwContainer.useContainer()

    const data = useMemo(()=>{
        if(!learningModulesData){
            return []
        }

        return learningModulesData.map(a=>{
            return {
                header: a.name,
                description: a.description,
                onClick: async ()=>{
                    const data = await takeQuiz(a.id)
                    const url = data.url
                    console.log("url", url)
                    window.open(url, "_blank")
                } 
            }
        })
    }, [learningModulesData])

return (
    <section className={styles.e_learning_wrapper}>
        <HeaderSection header={"e-Learning"} />
        <p>Please complete the modules listed below as they pertain to your school. To view your module scores click on the left the <span className={styles.e_learning_italics}>My Records</span> followed by the <span className={styles.e_learning_italics}>eLearning Results</span> link. There you will find a detailed list of your module scores which can also be downloaded as a PDF file.</p>
        <DashboardLearningList items={data} />
    </section>
);
};