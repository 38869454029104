import { DomainConf } from "./DomainConfig";

/**
 * Define configurations here to be loaded based on the domain
 * the app is being served from.
 */
const env = new DomainConf()
  .forDomain("localhost", {
    homeUrl: "https://dev.cpnw.org",
    apiBase: "https://dev-api.cpnw.org/v1",
    // apiBase: "http://localhost:7071",
    localDevMode: true, // Set to true to enable local login for developers.
    trainingModulesHostname: "https://stcpnwtrainingmodulesdev.blob.core.windows.net"
  })
  .forDomain("dev-dashboard.cpnw.org", {
    homeUrl: "https://dev.cpnw.org",
    apiBase: "https://dev-api.cpnw.org/v1",
    trainingModulesHostname: "https://stcpnwtrainingmodulesdev.blob.core.windows.net"
  })
  .forDomain("staging-ca-settings.wwdb.com", {
    apiBase: "https://app-management-team-api-staging.wwdb.com",
    coreApiUrl: "https://app-core-web-staging.wwdb.com",
  })
  .forDomain("dashboard.cpnw.org", {
    homeUrl: "https://beta.cpnw.org",
    apiBase: "https://api.cpnw.org/v1",
    trainingModulesHostname: "https://stcpnwtrainingmodprod.blob.core.windows.net"
  })

// Export the selected configuration from this file. You can import this wherever you need to use it.
export default env.current;
