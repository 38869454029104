import React, { useEffect } from 'react';
import { useState } from "react";
import PropTypes from "prop-types"
import classNames from 'classnames';
import styles from "./SelfPayPage.module.css";
import { Button, InputBox, SelectBox } from "@cpnw/ui";
import { AccountContainer } from '../../hooks/useAccount'

export default function SelfPayPage({ }) {
  const { selfPayData, selfPayGet, selfPayPost } = AccountContainer.useContainer();
  const [amount, setAmount] = useState(0);
  const [months, setMonths] = useState([]);
  const [currentMonth, setCurrentMonth] = useState('');
  const [years, setYears] = useState([]);
  const [currentYear, setCurrentYear] = useState('');
  const [message, setMessage] = useState('');

  const [cc, setCc] = useState(null);
  const [expMonth, setExpMonth] = useState(null);
  const [expYear, setExpYear] = useState(null);

  useEffect(() => {
    const months = [];
    const monthIndex = new Date().getMonth();
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    for (let i = 0; i < 12; i++) {
      months.push(`${monthNames[i]} (${i + 1})`);
    }
    setMonths(months);

    const years = [];
    const year = new Date().getFullYear();
    for (let i = year; i < year + 7; i++) {
      years.push(`${i}`);
    }
    setYears(years);

    setCurrentMonth(months[monthIndex]);
    setCurrentYear(year);
    setAmount(selfPayData?.body?.selfPayPrice ?? '0');

    selfPayGet();
  }, [selfPayGet, selfPayData?.body?.selfPayPrice]);


  const handlePay = async (event) => {
    if (!cc) {
      setMessage('Invalid account number');
    } else {
      const data = {
        cc: `${cc}`,
        ExpMonth: `${expMonth || currentMonth}`,
        ExpYear: `${expYear || currentYear}`
      };

      const response = await selfPayPost(data);

      if (response?.body?.success === true) {
        setMessage(response?.body?.alertMessage);
        setAmount(response?.body?.selfPayPrice ?? '0');
      }
    }
  };

  const currencyFormatter = (amount) => {
    const numberString = amount;
    const number = parseFloat(numberString);

    const formattedCurrency = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(number);

    return formattedCurrency;
  }
  return (
    <div className={styles.seflPayContainer}>
      <div className={styles.paymentForm}>
        <h2>{message}</h2>

        <InputBox id="amount" placeholder={""} label={"Amount"} value={currencyFormatter(amount)} readOnly />

        <InputBox id="cc" placeholder={""} label={"Credit Card(#)"} setValue={(e) => { setCc(e.target.value) }} />

        <SelectBox id="expMonth" className={styles.expirationMonth} onChange={(value) => { setExpMonth(value) }} placeholder={'Select a month'} selected={currentMonth} options={months} label={'Expiration Month'} />

        <SelectBox id="expYear" onChange={(value) => { setExpYear(value) }} placeholder={'Select a year'} selected={currentYear} options={years} label={'Expiration Year'} />

        <Button className={classNames(styles.button)} onClick={(e) => handlePay(e)} text={"Pay"} />
      </div>
    </div>
  );
};

SelfPayPage.propTypes = {};