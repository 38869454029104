import React, { useEffect, useState, useMemo, useCallback } from 'react';
import DashboardTablePagination from 'components/DashboardTablePagination';
import { DashboardItemsPerPage } from 'components/DashboardItemsPerPage';
import DashboardSearch from 'components/DashboardSearch';
import PropTypes from "prop-types"
import classNames from 'classnames';
import styles from "./RequirementTable.module.css";
import RequirementItem from "../RequirementItem";
import { Button, HeaderSection } from "@cpnw/ui";
import { Loading } from "@cpnw/ui";


export default function RequirementTable({ items, onAddRequirement, onEditRequirement, onDeleteRequirement, isLoading }) {
  const [colWidths] = useState({
    nameWidth: 200,
    typeWidth: 100,
    frequencyWidth: 90,
    requiredByWidth: 90,
    requiredForWidth: 90,
    createdWidth: 90,
    retiredWidth: 90,
    statusWidth: 90,
    editWidth: 70,
    deleteWidth: 100,
  })

  const [pageLimit, setPageLimit] = useState(10); 
  const [currentPage, setCurrentPage] = useState(1); 
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredItems, setFilteredItems] = useState(items); 

  const lastItemIndex = currentPage * pageLimit;
  const firstItemIndex = lastItemIndex - pageLimit;

  const debounce = (func, wait) => {
    let timeout;
    const debouncedFunction = (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  
    debouncedFunction.cancel = () => {
      clearTimeout(timeout);
    };
  
    return debouncedFunction;
  };

  const handleSearchQueryChange = useCallback(
    debounce(query => {
      query = query?.toLowerCase();
      const filtered = items.filter(item => {
        return (
          query === '' ||
          item.name?.toLowerCase().includes(query) ||
          item.type?.toLowerCase().includes(query) ||
          item.freq?.toLowerCase().includes(query) ||
          item.reqBy?.toLowerCase().includes(query) ||
          item.reqFor?.toLowerCase().includes(query) ||
          item.created?.toLowerCase().includes(query) ||
          item.retired?.toString().toLowerCase().includes(query) ||
          item.status?.toLowerCase().includes(query)
        );
      });
      setFilteredItems(filtered);
      setCurrentPage(1); // Reset to first page on new search
    }, 300),
    [items]
  );

  const currentItems = useMemo(() => {
    return filteredItems.slice(firstItemIndex, lastItemIndex);
  }, [filteredItems, firstItemIndex, lastItemIndex]);

  useEffect(() => {
    const handleDebouncedSearch = handleSearchQueryChange(searchQuery);
    setCurrentPage(1); // Reset to first page on new search
  
    // Cleanup function
    return () => {
      if (handleDebouncedSearch?.cancel) {
        handleDebouncedSearch.cancel();
      }
    };
  }, [searchQuery, handleSearchQueryChange]);

  const handlePageChange = page => {
    setCurrentPage(page);
  };
  
  const handleSetPageLimit = limit => { 
    setPageLimit(limit);
    setCurrentPage(1);
  };

  return (
    <>
       <div className={classNames(styles.requirementTableContainer, { [styles.isLoading]: isLoading })}>
        <HeaderSection header={"Requirements"} />
        <button className={styles.button} onClick={onAddRequirement}>Create Requirement</button>

        <div className={styles.table_upper_row}>
          <DashboardItemsPerPage
            disabled={false}
            limitOptions={[10, 25, 50, 100]}
            onLimitChange={handleSetPageLimit}
            limit={pageLimit}
          />
          <DashboardSearch onInput={setSearchQuery} />
        </div>
        {isLoading ? <div> <Loading /> </div>: (<section>
          
          <div className={styles.requirementTableHeader}>
            <div className={classNames(styles.requirementTableHeaderCol)}>
              <span className={styles.textCenter} style={{ width: colWidths.nameWidth + 'px' }}>Name</span>
            </div>
            <div className={classNames(styles.requirementTableHeaderCol)}>
              <span style={{ width: colWidths.typeWidth + 'px' }}>Type</span>
            </div>
            <div className={classNames(styles.requirementTableHeaderCol)}>
              <span className={styles.textCenter} style={{ width: colWidths.frequencyWidth + 'px' }} >Frequency</span>
            </div>
            <div className={classNames(styles.requirementTableHeaderCol)}>
              <span className={styles.textCenter} style={{ width: colWidths.requiredByWidth + 'px' }}>Required By:</span>
            </div>
            <div className={classNames(styles.requirementTableHeaderCol)}>
              <span className={styles.textCenter} style={{ width: colWidths.requiredForWidth + 'px' }} >Required For:</span>
            </div>
            <div className={classNames(styles.requirementTableHeaderCol)}>
              <span className={styles.textCenter} style={{ width: colWidths.createdWidth + 'px' }}  >Created</span>
            </div>
            <div className={classNames(styles.requirementTableHeaderCol)}>
              <span className={styles.textCenter} style={{ width: colWidths.retiredWidth + 'px' }} >Retired</span>
            </div>
            <div className={classNames(styles.requirementTableHeaderCol)}>
              <span className={styles.textCenter} style={{ width: colWidths.statusWidth + 'px' }} >Status</span>
            </div>
            <div className={classNames(styles.requirementTableHeaderCol)}>
              <span className={styles.textCenter} style={{ width: colWidths.editWidth + 'px' }}  >Edit</span>
            </div>
            <div className={classNames(styles.requirementTableHeaderCol)}>
              <span className={styles.textCenter} style={{ width: colWidths.deleteWidth + 'px' }}  >Delete</span>
            </div>
          </div>

          <div className={styles.requirementTableContent}>
            {currentItems.length > 0 ? (
              currentItems.map((requirement, index) => {
                return (
                  index % 2 === 0 ? (
                    <div key={requirement.id} className={classNames(styles.rowItem)}>
                      <RequirementItem info={requirement} width={colWidths} onEdit={onEditRequirement} onDelete={onDeleteRequirement}/>
                    </div>
                  ) : (
                    <div key={requirement.id} className={classNames(styles.rowItem, styles.requirementTableContentEven)}>
                      <RequirementItem info={requirement} width={colWidths} onEdit={onEditRequirement} onDelete={onDeleteRequirement}/>
                    </div>
                  )
                )
              })
            ) : (
              // No data row
              <div className={styles.requirementNoDataCol}>
                <p>No data available in Table</p>
              </div>
            )}
          </div>

          <div className={styles.pagingContainer}>
            <DashboardTablePagination
              onPageChange={handlePageChange}
              total={filteredItems.length}
              perPage={pageLimit}
              currentPage={currentPage}
            />
          </div>

        </section>
        )}
      </div>
    </>
  );
};

RequirementTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    freq: PropTypes.string,
    reqBy: PropTypes.string,
    reqFor: PropTypes.string,
    created: PropTypes.string,
    retired: PropTypes.bool,
    status: PropTypes.bool
  })).isRequired,
  onAddRequirement: PropTypes.func.isRequired
};