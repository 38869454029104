import { Auth } from "auth";
import environment from "environment";
import { createContainer } from "unstated-next";
import { useMutation, useQuery } from "react-query";
import { makeAuthedRequest, makeAuthedMultipartRequest, } from "utils/makeAuthedRequest";
import { useCallback, useMemo } from "react";
import { UserRegState } from "pages/enums";
import { StaticRoutes } from "pages/routes";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export function useAccount() {
  const history = useHistory()
  const { setToken } = Auth.useContainer();

  const login = async ({ email, password }) => {
    // TODO: Add back when handling real API
    return await fetch(environment.apiBase + `/cpnw/account/login`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ email, password }),
    })
      .then((res) => res.json())
      .then((res) => {
        let token = res.token;
        setToken(token);
        window.location.href = window.location.href;
        return token;
      });
  };

    const logout = async () => {
        return await fetch(environment.apiBase + `/cpnw/account/logout`, {
            method: "get",
            credentials: 'include',
        })
        .then(res=>{
            setToken(null)
            localStorage.removeItem("cpnw-token")
            window.location.href = environment.homeUrl
        })
        
    }

  const { data: meData, refetch: refetchMeData } = useQuery(
    ["MeGet"],
    async () =>
      await makeAuthedRequest(`/cpnw/me`, {
        method: "GET",
      }).then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const body = await res.json();
          throw new Error(body.message);
        }
      })
  );

  const userRoles = useMemo(() => {
    if (meData) {
      return meData.roles;
    }
    return [];
  }, [meData]);

  const conAssignments = useMemo(() => {
    if (meData) {
      return meData.conAssignments;
    }
    return [];
  }, [meData]);

  const canAdmin = useMemo(()=>{
    if (meData) {
      const conAssignments = meData.conAssignments;
      if (conAssignments && conAssignments.length > 0) {
        /*
        "CanAssign": true,
        "CanReport": false,
        "CanUpload": false,
        "CanFERPA": false,
        "CanHIPAA": false,
        "CanView": false,
        "CanCoordinate": false,
        "CanPlace": false,
        "CanDownload": false,
        "CanDelete": false,
        "CanOrgDocs": false,
        */
        return conAssignments.filter((a) => a.CanAssign || a.CanReport || a.CanUpload 
        || a.CanFERPA || a.CanHIPAA || a.CanView || a.CanCoordinate || a.CanPlace
        || a.CanDownload || a.CanDelete || a.CanOrgDocs).length > 0
      }
    }
    return false
  }, [meData])

  const hasCanPermissions = useCallback((...canPermissions)=>{
    if (meData) {
      const conAssignments = meData.conAssignments;
      if (conAssignments && conAssignments.length > 0) {
        return conAssignments.filter(a=> {
          var result = true
          for(const cp of canPermissions){
            result = result && a[cp]
          }
          return result
        }).length > 0
      }
    }
    return false
  }, [meData])


  const accountBackgroundCheckEnabledGet = async (email) => {
    return await makeAuthedRequest(`/cpnw/ContactBackgroundCheck`, {
      method: "post",
      body: JSON.stringify({
        email: email,
      }),
    }).then(async (res) => {
      if (res.ok) {
        return res.json();
      }
    });
  };

  const accountConsentGet = async () => {
    return await makeAuthedRequest(`/cpnw/account/consent`, {
      method: "GET",
    }).then(async (res) => {
      if (res.ok) {
        return res.json();
      }
    })
  };

  const accountConsentPost = async (agreed) => {

    return await makeAuthedRequest(`/cpnw/account/consent`, {
      method: "post",
      body: JSON.stringify({
        agreed: agreed,
      })
    }).then(async (res) => {
      if (res.ok) {
        return res.json();
      } else {
        const body = await res.json();
        throw new Error(body.message);
      }
    })
  };

  const { data: myProfile, refetch: refetchMyProfile } = useQuery(
    ["ProfileGet"],
    async () =>
      await makeAuthedRequest(`/cpnw/myprofile`, {
        method: "GET",
      }).then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const body = await res.json();
          throw new Error(body.error);
        }
      })
  );

  const myProfilePost = async (profile) =>
    await makeAuthedRequest(`/cpnw/myprofile`, {
      method: "POST",
      body: JSON.stringify(profile),
    }).then(async (res) => {
      if (res.ok) {
        return res.json();
      } else {
        const body = await res.json();
        throw body.error;
      }
    });

    const { data: learningResultsData, refetch: refetchLearningResults } = useQuery(
      ["LearningResultsGet"],
      async () =>
        await makeAuthedRequest(`/cpnw/LearningResults`, {
          method: "GET",
        }).then(async (res) => {
          if (res.ok) {
            return res.json();
          } else {
            const body = await res.json();
            throw new Error(body.message);
          }
        }),
        {
          enabled: false
        }
    );
  
    const { data: mySecuritySettingsData, refetch: refetchMySecuritySettings } = useQuery(
      ["MySecuritySettingsGet"],
      async () => {
        const res = await makeAuthedRequest(`/cpnw/MySecuritySettings`, {
          method: "GET",
        });
  
        if (res.ok) {
          const result = await res.json();
          return result;
        } else {
          const body = await res.json();
          throw new Error(body.message);
        }
      },
      {
        enabled: false
      }
    );

    const updateSecuritySettings = async (securitySettings) => {
      const res = await makeAuthedRequest(`/cpnw/MySecuritySettings`, {
        method: "POST",
        body: JSON.stringify(securitySettings),
      });
  
      if (res.ok) {
        const result = await res.json();
        return result;
      } else {
        const body = await res.json();
        throw new Error(body.message);
      }
    };
  
    const submitDemoGraphics = async (formData) => {
      const res = await makeAuthedMultipartRequest(
        `/cpnw/MyDemographics`,
        formData,
        {method: "POST"}
      );
  
      if (res.ok) {
        const result = await res.json();
        return result;
      } else {
        const body = await res.json();
        throw new Error(body.message);
      }
    };
  
    const { data: myDemoGraphicsData, isLoading: myDemoGraphicsDataLoading, refetch: refetchMyDemographics } = useQuery(
      ["MyDemoGraphicsGet"],
      async () =>
        await makeAuthedRequest(`/cpnw/MyDemographics`, {
          method: "GET",
        }).then(async (res) => {
          if (res.ok) {
            return res.json();
          } else {
            const body = await res.json();
            throw new Error(body.message);
          }
        }),
        {
          enabled: false
        }
    );
  
    const { data: oigSamData, refetch: oigSamRefetch } = useQuery(
      ["OIGSAM"],
      async () =>
        await makeAuthedRequest(`/cpnw/OIGSAM`, {
          method: "GET",
        }).then(async (res) => {
          if (res.ok) {
            return res.json();
          } else {
            const body = await res.json();
            throw new Error(body.message);
          }
        }),
      {
        enabled: false, // Prevent automatic fetching
      }
    );

    const downloadModuleResults = async () => {
      const res = await makeAuthedRequest(`/cpnw/DownloadModuleResultsPDF`, {
        method: "GET",
      });
  
      if (res.ok) {
        const result = await res.blob();
        return result;
      } else {
        const body = await res.json();
        throw new Error(body.message);
      }
    };

  const { data: selfPayData, refetch: selfPayGet } = useQuery(
    ["SelfPayGet"],
    async () =>
      await makeAuthedRequest(`/cpnw/Account/SelfPay`, {
        method: "GET",
      }).then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const body = await res.json();
          throw new Error(body.message);
        }
      }),
    {
      enabled: false
    }
  );

  const selfPayPost = async (data) => {
    const res = await makeAuthedRequest(`/cpnw/Account/SelfPay`, {
      method: "POST",
      body: JSON.stringify(data),
    });

    if (res.ok) {
      const result = await res.json();
      return result;
    } else {
      const body = await res.json();
      throw new Error(body.message);
    }
  };
  

  return {
    login,
    logout,
    meData,
    userRoles,
    conAssignments,
    canAdmin,
    hasCanPermissions,

    accountBackgroundCheckEnabledGet,

    accountConsentGet,
    accountConsentPost,

    myProfile,
    refetchMyProfile,
    myProfilePost,

    learningResultsData,
    refetchLearningResults,

    mySecuritySettingsData,
    refetchMySecuritySettings,

    updateSecuritySettings,
    submitDemoGraphics,
    
    myDemoGraphicsData,
    myDemoGraphicsDataLoading,
    refetchMyDemographics,

    oigSamData,
    oigSamRefetch,

    downloadModuleResults,

    selfPayData,
    selfPayGet,
    selfPayPost,
  };
}
export const AccountContainer = createContainer(useAccount);
