import React, { useState, useEffect } from 'react';
import DashboardRadio from 'components/DashboardRadio';
import styles from "./QuestionToggle.module.css";

export default function QuestionToggle ({label, onClick, value, disabled = false}) {
    const [answer, setAnswer] = useState(value);

    useEffect(() => {
      setAnswer(value);
  }, [value]);
    
    const handleChange = (newValue) => {
        setAnswer(newValue);
        onClick(newValue);
    };

  return (
    <div className={styles.container}>
      {label && <label className={styles.radio_label}>{label}</label>}
      <div className={styles.items}>
        <DashboardRadio
          isSelected={answer === 'yes'}
          onChange={handleChange}
          value="yes"
          label="Yes"
          disabled={disabled}
        />
        <DashboardRadio
          isSelected={answer === 'no'}
          onChange={handleChange}
          value="no"
          label="No"
          disabled={disabled}
        />
      </div>
    </div>
  );
}