import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DashboardPassportFileItem from "components/DashboardPassportFileItem";
import DashboardSortTableHeader from "components/DashboardSortTableHeader";
import styles from "./DashboardPassportFileTable.module.css";

const DashboardPassportFileTable = ({
  items,
  onChangeSelectedFiles,
  onDelete,
  showDelete
}) => {
  const [tableItems, setTableItems] = useState([]);
  const [sortCriteria, setSortCriteria] = useState({
    column: "name",
    direction: "asc",
  });

  const handleSort = (column) => {
    setSortCriteria((prevCriteria) => {
      if (prevCriteria.column === column) {
        const newDirection = prevCriteria.direction === "asc" ? "desc" : "asc";
        return { column, direction: newDirection };
      } else {
        return { column, direction: "asc" };
      }
    });
  };

  useEffect(() => {
    setSortCriteria({ column: "name", direction: "asc" });
  }, []);

  useEffect(() => {
    setTableItems([...items]);
  }, [items])

  const sortedItems = [...tableItems].sort((a, b) => {
    const aValue = a[sortCriteria.column];
    const bValue = b[sortCriteria.column];
    if (sortCriteria.direction === "asc") {
      return aValue > bValue ? 1 : -1;
    } else {
      return aValue < bValue ? 1 : -1;
    }
  });

  const [selectedItems, setSelectedItems] = useState([]);

  const handleSelect = (index) => {
    let newSelecteds = [];
    if (selectedItems.includes(index)) {
      newSelecteds = selectedItems.filter((itemIndex) => itemIndex !== index);
    } else {
      newSelecteds = [...selectedItems, index];
    }

    setSelectedItems([...newSelecteds]);
    onChangeSelectedFiles(newSelecteds.map((m) => tableItems[m]));
  };
  return (
    <div className={styles.tableContainer}>
      <table className={styles.fileTable}>
        <thead>
          <tr>
            <th style={{ width: "50%", paddingLeft: "33px" }}>
              <DashboardSortTableHeader
                title="File Name"
                onChange={() => handleSort("name")}
                value={
                  sortCriteria.column === "name" ? sortCriteria.direction : ""
                }
                width={550} // Adjust width as needed
              />
            </th>
            <th style={{ width: "30%" }}>
              <DashboardSortTableHeader
                title="Upload Date"
                onChange={() => handleSort("uploadDate")}
                value={
                  sortCriteria.column === "uploadDate"
                    ? sortCriteria.direction
                    : ""
                }
                width={220} // Adjust width as needed
              />
            </th>
            {showDelete && <th style={{ width: "20%" }}>Remove</th>}
          </tr>
        </thead>
        <tbody>
          {sortedItems.length === 0 ? (
            <tr>
              <td colSpan="3" className={styles.noData}>
                No data available in table
              </td>
            </tr>
          ) : (
            sortedItems.map((item, index) => (
              <DashboardPassportFileItem
                key={index}
                selected={selectedItems.includes(index)}
                onSelect={() => handleSelect(index)}
                onTrash={() => onDelete(index)}
                onClick={() =>
                  console.log(`File name clicked for item ${index}`)
                }
                info={item}
                showDelete={showDelete}
              />
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

DashboardPassportFileTable.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      uploadDate: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default DashboardPassportFileTable;
