import React from "react";
import styles from "./TakeQuizPage.module.css";
import environment from "environment";

export function TakeQuizPage() {
  const pathname = window.location.pathname
  const url = environment.trainingModulesHostname + pathname + window.location.search
  return (
    <iframe src={url} className={styles.container}>

    </iframe>
  )
}

export default TakeQuizPage;
