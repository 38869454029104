import React from 'react';
import PropTypes from "prop-types";
import styles from "./DashboardLearningItem.module.css";

import icon from "../../images/seed-brown.svg"

export default function DashboardLearningItem({header, description, src, onClick}){

return (
    <>
        <div className={styles.learning_item_wrapper}>
            <div className={styles.learning_item_icon_col}>
                {/* path of image */}
                <a href="/eLearning">
                    <img src={icon} alt="seed-icon" className={styles.learning_icon}/>
                </a>
            </div>
            <div className={styles.learning_item_content_col}>
                {/* content */}
                <a href={src} onClick={onClick}>{header}</a>
                <p>{description}</p>
            </div>
        </div>
    </>
);
};

DashboardLearningItem.propTypes = {
    header: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    src: PropTypes.string,
    onClick: PropTypes.func
}