import React, { useEffect, useState } from "react";
import moment from "moment";
import { CpnwContainer } from "hooks/useCpnw";
import DashboardOIGSAMAdminTable from "components/DashboardOIGSAMAdminTable";
import DashboardSelectBoxWithValue from "components/DashboardSelectBoxWithValue";
import { Button } from "@cpnw/ui";
import _ from "lodash";
import styles from "./EdOIGSAMCheck.module.css";

const EdOIGSAMCheck = () => {
  const {
    reportData,
    reportRefetch,
    getExclusionReports,
    getExclusionFilters,
  } = CpnwContainer.useContainer();

  const [showReports, setShowReports] = useState(false);
  const [reports, setReports] = useState([]);
  const [dateRuns, setDateRuns] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [selectedDateRun, setDateRun] = useState(null);
  const [selectedProgram, setSelectedProgram] = useState(null);
  
  const [filters, setFilters] = useState({
    schoolName: '',
    programName: '',
  });

  useEffect(() => {
    try {
      if (!reportData) reportRefetch();

      getExclusionFilters().then((res) => {
        setDateRuns(
          (res?.data?.dateRuns || []).map((m) => ({
            key: m,
            value: moment(m).format("MM-DD-YYYY"),
          }))
        );
      });
    } catch (error) {}
  }, []);

  useEffect(() => {
    if (reportData && reportData.data) {
      const newPrograms = _(reportData.data)
        .groupBy((m) => m.businessUnitId)
        .map((value, key) => ({
          id: key,
          name: `${value[0].businessUnitId} - ${value[0].businessUnit} (${value[0].businessUnitAbbrev})`,
        }))
        .value();

      setPrograms(newPrograms);
    }
  }, [reportData]);

  const getReports = async () => {
    if (!selectedProgram || !selectedDateRun) return;
    setShowReports(true);
    let reports = null;
    try {
      reports = await getExclusionReports(selectedProgram, selectedDateRun);
    } catch (error) {}

    setFilters({
      programName: programs.find((m) => m.id == selectedProgram)?.name,
    });

    setReports(reports?.data || []);
  };

  return (
    <div className={styles.container}>
      <h1>OIG SAM Excluded Provider Check</h1>
      <div>
        <div className={styles.description}>
          <div>
            <p>
              Excluded provider checks are performed bi-monthly on the 1st and
              15th of each month for all users in the clinical setting. Your
              schools' results will be posted on this page by the 5th and 20th
              of the month.
            </p>
            <br />
            <p>
              If there is a match of any kind, the school is responsible for
              further examining the finding.
            </p>
          </div>
          <div>
            <p>
              CPNW runs the screening against two federal excluded provider
              lists.The data sources for this check are:
            </p>
            <br />
            <ul className={styles.excludedProviderList}>
              <li>
                Office of Inspector General(OIG) list of Excluded Individuals
                and Entities(LEIE)
              </li>
              <li>
                General Services Administration(GSA) System for Award
                Management(SAM)
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.report}>
          <div className={styles.reportQuery}>
            <div className={styles.reportQueryDate}>
              <p>Report Date</p>
              <DashboardSelectBoxWithValue
                selected={selectedDateRun}
                onChange={(value) => {
                  setDateRun(value);
                }}
                placeholder={""}
                options={dateRuns}
              />
            </div>
            <div className={styles.reportQueryProgram}>
              <p>Program</p>
              <DashboardSelectBoxWithValue
                selected={selectedProgram}
                onChange={(value) => {
                  setSelectedProgram(value);
                }}
                placeholder={""}
                options={programs.map((m) => ({
                  key: m.id,
                  value: m.name,
                }))}
              />
              <div>
                <Button
                  onClick={() => getReports()}
                  text="Run Report"
                  disabled={!selectedDateRun || !selectedProgram}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {showReports && (
        <div className={styles.content}>
          <DashboardOIGSAMAdminTable items={mapReports(reports)}  filters={filters} />
        </div>
      )}
    </div>
  );
};

const mapReports = (reports) => {
  let result = (reports || []).map((m) => {
    return {
      id: m.id,
      scanDate: m.dateRun,
      nameInput: m.nameInput,
      program: m.contact?.businessUnitAbbrev || "",
      sidEid: m.contact?.individualId || "",
      agency: m.tableHIT,
      scanResult: m.likelyMatch,
      message: m.message,
      userType: m.contact?.typeContactName || "",
      contact: m.contact,
    };
  });

  return result;
};

export default EdOIGSAMCheck;
