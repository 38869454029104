import React, { useEffect, useState } from "react";
import { CpnwContainer } from "hooks/useCpnw";
import DashboardPassportTable from "components/DashboardPassportTable";
import DashboardPassportItemFields from "components/DashboardPassportItem/DashboardPassportItemFields";

import {Loading} from "@cpnw/ui";

import styles from "./HcPassport.module.css";
import { CanPermissions } from "pages/enums";
import { AccountContainer } from "hooks/useAccount";

const HcPassport = () => {
  const {
    learningModulesData,
    refetchLearningModules,
    reportData,
    reportRefetch,
    assignmentAccept,
    assignmentReject,
    getPassports,
    downloadPassports,
    uploadPassports,
    deletePassports
  } = CpnwContainer.useContainer();

  const {hasCanPermissions} = AccountContainer.useContainer()

  const showUpload = hasCanPermissions(CanPermissions.CanUpload)
  const showDownload = hasCanPermissions(CanPermissions.CanDownload)
  const showDelete = hasCanPermissions(CanPermissions.CanDelete)

  // endpoints are disabled by default, refetch both first
  useEffect(() => {
    reportRefetch();
    refetchLearningModules();
  }, [])

  const [modules, setModules] = useState([]);
  const [reports, setReports] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);

  useEffect(() => {
    if (learningModulesData && modules.length == 0) {
      setModules([...learningModulesData]);
    }
  }, [learningModulesData]);

  useEffect(() => {
    if (reportData && reportData.total > 0) {
      setReports([...reportData.data]);
    }
  }, [reportData]);

  useEffect(() => {
    if(reports.length>0 && modules.length>0){
      setDataLoading(false);
    }
  }, [reports, modules])

  const assignmentReview = async (assignmentId, reviewStatus, rejectionContent) => {
    try {
      if (reviewStatus == "Accepted") {
        await assignmentAccept(assignmentId);
        return true;
      }

      if (reviewStatus == "Rejected") {
        await assignmentReject(assignmentId, rejectionContent);
        return true;
      }
    } catch (error) {
      console.log(error);
    }

    return false;
  };

  const passportUpload = async (studentContactId, files) => {
    try {
      const rsp = await uploadPassports(studentContactId, files);
      return rsp?.data || [];
    } catch (error) {
      console.log(error);
    }

    return [];
  };

  const passportDelete = async (studentContactId, fileName) => {
    try {
      const rsp = await deletePassports(studentContactId, fileName);
      return rsp?.data == true;
    } catch (error) {
      console.log(error);
    }

    return false;
  };

  const onDownloadContactPassports = async (contactId, passports) => {
    try {
      let fileNames = passports?.map((m) => m.name);
      if (fileNames?.length > 0) await downloadPassports(contactId, fileNames);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {dataLoading && (
          <div>
            <Loading />
          </div>
        )}
        {reports?.length > 0 && modules?.length > 0 ? (
          <DashboardPassportTable
            data={getData(reports, modules)}
            columns={columns}
            passportsData={null}
            assignmentReview={assignmentReview}
            passportUpload={passportUpload}
            passportDelete={passportDelete}
            onGetPassportsData={getPassports}
            onDownloadContactPassports={(contactId, passports) =>
              onDownloadContactPassports(contactId, passports)
            }
            showDelete={showDelete}
            showDownload={showDownload}
            showUpload={showUpload}
          />
        ) : null}
      </div>
    </div>
  );
};

const getData = (data, modules) => {
  let result = (data || []).map((m) => {
    // console.log('m')
    // console.log(m);
    let lms = (modules || []).map((o) => {
      let module = m.contactModules.find((a) => a.moduleId == o.id);

      return {
        moduleName: o.name,
        status: module?.status,
        expirationDate: module?.expirationDate,
      };
    });

    return {
      id:m.assignment?.assignmentId || m.assignment?.assigmentId,
      assignmentId: m.assignment?.assignmentId || m.assignment?.assigmentId,
      assignmentStatus: getAssignmentStatusTypes(m.assignment?.status),
      rejectionContent: m.assignment?.rejectionReasons,
      contact: {
        id: m.id,
        name: m.name,
        email: m.email,
        phone: m.primaryPhone,
        address: m.address,
        emergencyContact: m.emergencyContact,
        emergencyContactPhone: m.emergencyPhone,
        school: m.facilityName,
        program: m.businessUnit,
        sIDEID: m.individualID,
        location: m.assignment?.hcpFacilityName,
        startDate: m.assignment?.startDate,
      },
      pp: m.id,
      lm: {
        status: lms.some((m) => m.status == "Incomplete")
          ? "Incomplete"
          : "Complete",
        data: lms,
      },
      oig: m.oig,
      sam: m.sam,
      userType: m.typeContactName,
    };
  });

  // console.log(result);
  return result;
};

const getAssignmentStatusTypes = (status) => {
  switch (status) {
    case 0:
      return "Accepted";
    case 1:
      return "Rejected";
    case 2:
      return "Review";
    default:
      return "";
  }
};

const columns = [
  {
    label: "Status",
    accessor: DashboardPassportItemFields.assignmentStatus,
    sortable: true,
  },
  {
    label: "Name",
    accessor: DashboardPassportItemFields.nameOfContact,
    sortable: true,
  },
  {
    label: "PP",
    accessor: DashboardPassportItemFields.passport,
    sortable: true,
    sortbyOrder: "desc",
  },
  {
    label: "LM",
    accessor: DashboardPassportItemFields.learningModule,
    sortable: true,
  },
  { label: "OIG", accessor: DashboardPassportItemFields.oig, sortable: true },
  { label: "SAM", accessor: DashboardPassportItemFields.sam, sortable: true },
  {
    label: "Type",
    accessor: DashboardPassportItemFields.userContactType,
    sortable: true,
  },
];

export default HcPassport;
