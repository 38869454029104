import React, { useCallback, forwardRef } from "react";
import { InputBox } from "@cpnw/ui";
import styles from "./SocialSecurityInput.module.css";

const SocialSecurityInput = forwardRef(({ value, setValue }, ref) => {

    const handleChange = useCallback((event) => {
        const input = event.target.value;

        //validate length is correct and/or modify input to standard social ###-##-####
        if (input.length <= 11) {
            const formattedInput = input
                .replace(/\D/g, '') // Remove non-digits
                .replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3'); // Insert dashes

            setValue(formattedInput); // Update the value using the passed setValue function
        }
    }, [])

    return (
        <div className={styles.social_security_input}>
            <InputBox
                id="ssn"
                label="Social Security *"
                type="text"
                placeholder="123-45-6789"
                value={value}
                setValue={handleChange}  // Assuming handleChange function updates the state
                ref={ref}
            />
        </div>
    );
});

export default SocialSecurityInput;