import React from 'react';
import styles from "./DashboardClinicalPassport.module.css";
import classNames from 'classnames';

import { HeaderSection } from "@cpnw/ui";
// import { LinkItem } from "@cpnw/ui";

export default function DashboardClinicalPassport(){

return (
    <>
        <HeaderSection header={"Clinical Passport"} />
        <div className={styles.passport_container}>
            <div className={classNames(styles.passport_col, styles.passport_col_left)}>
                <span className={styles.passport_col_subheader}>Required of ALL Users</span>
                <p>All students and faculty involved in clinical learning must complete mandatory statewide health and safety requirements. CPNW lists all these requirements in its Clinical Passport.</p>
                <p>On this page are links to download a fillable copy of the Clinical Passport and a tutorial on how to complete it.</p>
                <p>The Clinical Passport is intended to follow a student through their academic program and therefore updated when any field expires and/or from year to year. There is no need to introduce or start a new Passport each academic year.</p>
            </div>
            <div className={classNames(styles.passport_col, styles.passport_col_right)}>
                <span className={styles.passport_col_subheader}>Download the Passport</span>
                <p>Click the links below to download a copy of the Clinical Passport and a tutorial on Passport completion.</p>
                {/* <ul>
                    <LinkItem label="Clinical Passport (PDF)" src="https://cpnw.blob.core.windows.net/documents/clinicalPassport/Passport_fillable.pdf?638530379241430050" />
                    <LinkItem label="Clinical Passport Tutorial - Module" src="https://rise.articulate.com/share/Dr9KVHjmXs6CS2Dl7ah37AvhzMrP1a28#/" />
                    <LinkItem label="Clinical Passport Tutorial - Document" src="https://cpnw.blob.core.windows.net/documents/clinicalPassport/PassportTutorial_FINAL.pdf" />
                </ul> */}
                <div className={styles.link_container}>
                    <a href="https://cpnw.blob.core.windows.net/documents/clinicalPassport/Passport_fillable.pdf?638530379241430050" target='_blank' rel="noopener noreferrer" className={styles.passport_link_item}>Clinical Passport (PDF)</a>
                    <a href="https://rise.articulate.com/share/Dr9KVHjmXs6CS2Dl7ah37AvhzMrP1a28#/" target='_blank' rel="noopener noreferrer" className={styles.passport_link_item}>Clinical Passport Tutorial - Module</a>
                    <a href="https://cpnw.blob.core.windows.net/documents/clinicalPassport/PassportTutorial_FINAL.pdf" target='_blank' rel="noopener noreferrer" className={styles.passport_link_item}>Clinical Passport Tutorial - Document</a>
                </div>

                <p>If you have a positive TB Test result, you must have a separate medical evaluation by your healthcare provider. If you have a history of positive TB test results, you must complete the Tuberculosis Symptom Screening form as per Clinical Passport requirements. Click here to download the Tuberculosis Symptom Screening form.</p>
            </div>
        </div>
        <div className={classNames(styles.passport_container, styles.passport_row_mid)}>
            <div className={classNames(styles.passport_col, styles.passport_col_mid)}>
                    <span className={styles.passport_col_subheader}>Submitting Your Documents</span>
                    <p>It is the sole responsibility of the participating school to collect each users clinical passport documentation. Schools should provide you information on the process it follows.</p>
            </div>
        </div>
    </>
);
};