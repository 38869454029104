import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./DashboardAffiliationRequestModal.module.css";
import { Modal, HeaderSection, Button } from "@cpnw/ui";
import DashboardSelectBoxWithValue from "components/DashboardSelectBoxWithValue";
import { InputBox } from "@cpnw/ui";

export const DashboardAffiliationRequestModal = ({ isOpen, onClose, locationOptions, onSubmit, coordinator }) => {

    const onCloseModal = () => {
        onClose();
    }

    const AffiliationRequestContent = ({ onSubmit }) => {
        const [hcFacility, setHCFacility] = useState('');
        const [programState, setProgram] = useState('');
        const [isEmptyProgram, setIsEmptyProgram] = useState(false);
        const [isEmptyHC, setIsEmptyHC] = useState(false);

        const validateForm = () => {
            let isValid = true;

            let hcFacilityValue = hcFacility?.trim();
            let programStateValue = programState?.trim();

            if (!hcFacilityValue) {
                setIsEmptyHC(true);
                isValid = false;
            } else {
                setIsEmptyHC(false);
            }
            if (!programStateValue) {
                setIsEmptyProgram(true);
                isValid = false;
            } else {
                setIsEmptyProgram(false);
            }
            return isValid;
        }

        const onClickSubmitRequest = (e) => { 
            if(!validateForm()) return;

            const healthcare = locationOptions.options.find(option => option.key === hcFacility)?.value;
            const school = coordinator?.FacilityName ?? '';
            const coordinatorEmail = coordinator?.email ?? '';
            const program = programState;
            const dataSubmit = {
                healthcare,
                school,
                coordinatorEmail,
                program
            };
            onSubmit(dataSubmit);
        };

        const handleProgramChange = (e) => {
            setProgram(e.target.value);
        };

        return (
            <>
                <div className={styles.flexContainer}>
                    <div className={classNames(styles.header, styles.flexItem)}><HeaderSection header={"Affiliation Request"} /></div>
                    <div className={classNames(styles.close, styles.flexItem)} onClick={() => onCloseModal()}><div className={styles.timeIcon}>x</div></div>
                    <div className={classNames(styles.help, styles.row)}>
                        When you have a CONFIRMED placement at a healthcare facility not listed in your locations list, please use the form below to request a facility be added to your select list.
                    </div>
                    <div className={classNames(styles.help, styles.row)}>
                        If you need assistance contact technical support at <a href="mailto:support@cpnw.org">support@cpnw.org</a>
                    </div>
                    <div className={styles.errorContainer}>
                        <ul>
                            {isEmptyProgram && <li>Program/Degree is required.</li>}
                            {isEmptyHC && <li>Healthcare Facility is required.</li>}
                        </ul>
                    </div>
                    <div className={classNames(styles.help, styles.row)}>
                        Request From: {coordinator?.FacilityName ?? ''}
                    </div>
                    <div className={classNames(styles.row)}>
                        <label htmlFor="program"><b>Program Requesting Affiliation:</b>
                            <InputBox 
                                placeholder={"Name of program/degree."} 
                                onChange={handleProgramChange} 
                                value={programState}
                            />
                        </label>
                    </div>
                    <div className={classNames(styles.row)}>
                        <label htmlFor="Clinical"><b>Clinical Site:</b>
                             <DashboardSelectBoxWithValue
                                selected={hcFacility}
                                onChange={(value) => {
                                    setHCFacility(value);
                                }}
                                placeholder={"-Select Clinical Site-"}
                                options={locationOptions.options}
                            />
                        </label>
                    </div>
                    <div className={classNames(styles.help, styles.row)}>
                        <b>Submitted by: </b> <a>{coordinator?.email ?? ''}</a>
                    </div>
                    <div className={classNames(styles.row)}>
                        <Button onClick={(e) => onClickSubmitRequest(e)} text="SUBMIT REQUEST" />
                    </div>
                </div>
            </>
        )
    }

    return (
        <Modal isOpen={isOpen} onRequestClose={onCloseModal} className={classNames(styles.dashboardAffiliationRequestModal)} children={<AffiliationRequestContent onSubmit={onSubmit} />} />
    );
};

DashboardAffiliationRequestModal.propTypes = {
    onSubmit: PropTypes.func.isRequired,
}

export default DashboardAffiliationRequestModal;


