import React, { useEffect, useMemo } from 'react';
import styles from "./ELearningResults.module.css";

import { HeaderSection } from "@cpnw/ui";
import DashboardResultsTable from '../../../../components/DashboardResultsTable';
import { CpnwContainer } from 'hooks/useCpnw';
import { AccountContainer } from 'hooks/useAccount';

export default function ELearningResults(){
    const {learningResultsData, refetchLearningResults, myProfile, downloadModuleResults} = AccountContainer.useContainer()

    useEffect(()=>{
        refetchLearningResults()
    }, [])

    const data = useMemo(()=>{
        if(!learningResultsData){
            return []
        }

        return learningResultsData.map(a=>{
            return {
                subject: a.moduleName,
                status: a.moduleStatus,
                date: a.moduleTestedOn,
                style: a.moduleStyles
            }
        })
    }, [learningResultsData])

    const handleDownload = async () => {
        console.log("Downloading PDF", myProfile);
        const blob = await downloadModuleResults();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `${myProfile.NameFirst ?? ''}_${myProfile.NameLast ?? ''}_eLearningResults.pdf`; // Set the desired file name
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    };

    return (
        <section className={styles.e_learning_results_wrapper}>
            <HeaderSection header={"e-Learning Results"} />
            <p>When you successfully complete the eLearning modules your passing quiz score will be listed below. You may download a copy for your records.</p>
            {/* name, id, and src should be populated based on the user */}
            <DashboardResultsTable name={myProfile ? `${myProfile.NameLast}, ${myProfile.NameFirst}` : ""} id={myProfile ? myProfile.IndividualID : ""} src='#' onDownloadResults={handleDownload} results={data} />
        </section>
    );
};