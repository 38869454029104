import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import styles from "./DashboardPassportFileItem.module.css";

const DashboardPassportFileItem = ({
  selected,
  onSelect,
  onTrash,
  info,
  onClick,
  showDelete
}) => {
  return (
    <tr>
      <td className={styles.fileNameUploadDateCol}>
        <input
          type="checkbox"
          checked={selected}
          onChange={onSelect}
          id={`file-${info.name}`}
          className={styles.checkbox}
        />
        <label htmlFor={`file-${info.name}`} onClick={onClick}>
          {info.name}
        </label>
      </td>
      <td className={styles.uploadDateCol}>{new Date(info.uploadDate).toLocaleString().replace(',','')}</td>
      {showDelete && <td className={styles.removeCol}>
        <button className={styles.removeButton} onClick={onTrash}>
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </td>}
    </tr>
  );
};

DashboardPassportFileItem.propTypes = {
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  onTrash: PropTypes.func.isRequired,
  info: PropTypes.shape({
    name: PropTypes.string.isRequired,
    uploadDate: PropTypes.string.isRequired,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default DashboardPassportFileItem;
