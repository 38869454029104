import PropTypes from "prop-types";
import styles from "./PassportAdminReviewItem.module.css";
import classNames from 'classnames';

export default function PassportAdminReviewItem({ info, onView, width }) {

  return (
    <div className={styles.reviewItemRow}>
      <div className={classNames(styles.col)} style={{ width: `${width.nameWidth}px` }}>
        <a className={styles.link} onClick={onView}>{info.name}</a>
      </div>
      <div className={classNames(styles.col)} style={{ width: `${width.idWidth}px` }}>
        {info.sid}
      </div>
      <div className={classNames(styles.col)} style={{ width: `${width.schoolWidth}px` }}>
        {info.school}
      </div>
      <div className={classNames(styles.col)} style={{ width: `${width.programWidth}px` }}>
        {info.program}
      </div>
      <div className={classNames(styles.col)} style={{ width: `${width.statusWidth}px` }}>
      {info.reviewStatus || ""}
      </div>
      <div className={classNames(styles.col, styles.textCenter)} style={{ width: `${width.viewWidth}px` }}>
        <button className={styles.button} onClick={onView}>VIEW</button>
      </div>
    </div>
  );
};

PassportAdminReviewItem.propTypes = {
  info: PropTypes.shape({
    name: PropTypes.string,
    sid: PropTypes.string,
    school: PropTypes.string,
    program: PropTypes.string,
    status: PropTypes.string,
    reviewStatus: PropTypes.string,
  }).isRequired,
};