import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Button, Modal } from "@cpnw/ui";
import * as XLSX from "xlsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideo, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useSortableTable } from "components/DashboardPassportTable/useSortableTable";
import { DashboardItemsPerPage } from "components/DashboardItemsPerPage";
import { DashboardContactInformationModal } from "components/DashboardContactInformationModal";
import DashboardPassportItem from "components/DashboardPassportItem";
import DashboardTablePagination from "components/DashboardTablePagination";
import DashboardSearch from "components/DashboardSearch";
// import DashboardReviewModal from "components/DashboardReviewModal";
// import DashboardLearningStatusModal from "components/DashboardLearningStatusModal";
import DashboardPassportFileModal from "components/DashboardPassportFileModal";
import DashboardPassportReportItemModal from "components/DashboardPassportReportItemModal";
import DashboardPassportTableKey from "components/DashboardPassportTableKey";
import DashboardSelect from "components/DashboardSelect";
import { format } from 'date-fns';

import styles from "./DashboardPassportTable.module.css";

export function DashboardPassportTable({
  className,
  data,
  columns,
  assignmentReview,
  passportUpload,
  passportDelete,
  onGetPassportsData,
  onDownloadContactPassports,
  showDelete,
  showDownload,
  showUpload
}) {
  const [videoUrl, setVideoUrl] = useState(undefined);
  const [isOpenVideoTutorialModal, setOpenVideoTutorialModal] = useState(false);
  const VideoTutorialModal = ({ isOpen, videoUrl }) => {
    const closeModal = () => {
      onCloseVideoTutorialModal();
    };

    return (
      <div>
        <Modal
          isOpen={isOpen}
          onRequestClose={closeModal}
          className={styles.customModal}
          modalClassName={styles.modalBox}
          children={
            <>
            <div className={styles.modal_row}>
              <FontAwesomeIcon icon={faXmark} style={{fontSize: "28px", transform: "translateY(-15px) translateX(15px)", color: "#dddddd", cursor: "pointer"}} onClick={closeModal}/>
            </div>
            <div className={styles.videoWrapper}>
              <iframe
                src={videoUrl}
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                allowfullscreen
              ></iframe>
            </div>
            </>
          }
        />
      </div>
    );
  };
  const [tableData, handleSorting] = useSortableTable(data, columns);

  const [filterName, setFilterName] = useState("");
  const [dataToShow, setDataToShow] = useState(tableData);
  const [sortField, setSortField] = useState("");
  const [order, setOrder] = useState("asc");

  const [currentPage, setCurrentPage] = useState(1);
  const [selectedItems, setSelectedItems] = useState([]);

  const [totalItems, setTotalItems] = useState(tableData.length);
  const [isShowSelected, setIsShowSelected] = useState(false);

  const [limit, setLimit] = useState(10); // Default limit
  const limitOptions = [10, 25, 50, 100]; // Options for items per page

  const startIndex = (currentPage - 1) * limit;
  const endIndex = Math.min(startIndex + limit - 1, tableData.length - 1);

  const [currentItemInfo, setCurrentItemInfo] = useState();
  const [reviewAssignmentId, setReviewAssignmentId] = useState(false);
  const [isContactInfoModalOpen, setIsContactInfoModalOpen] = useState(false);
  const [isFileManagementModalOpen, setIsFileManagementModalOpen] =
    useState(false);
  const [dataLearningModuleModal, setDataLearningModuleModal] = useState([]);
  const [isSummaryReportItemModalOpen, setIsSummaryReportItemModalOpen] =
    useState(false);
  const [contactDataWithPassports, setContactDataWithPassports] =
    useState(null);

  const [locationsOptions, setLocationsOptions] = useState([])
  const [schoolsOptions, setSchoolsOptions] = useState([])
  const [startDatesOptions, setStartDatesOptions] = useState([])
  useEffect(() => {
    if(tableData && tableData.length>0){
      let locations = tableData.map((item, index) => {
        return item.contact.location;
      })
      let uniqueLocations = [...new Set(locations)];
      setLocationsOptions(uniqueLocations);

      let schools = tableData.map((item, index) => {
        return item.contact.school;
      })
      let uniqueSchools = [...new Set(schools)];
      setSchoolsOptions(uniqueSchools);

      let dates = tableData.map((item, index) => {
        let ret = format(item.contact.startDate, 'MM/dd/yyyy')
        return ret;
      })
      let uniqueDates = [...new Set(dates)];
      setStartDatesOptions(uniqueDates);
    }

  }, [tableData])
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const handleFilter = (location, school, startDate) => {
    let newDataAgain = tableData.filter(item => {
      // Check if the item matches the selected location, or if no location is selected
      const matchesLocation = location ? item.contact.location === location : true;
      // Check if the item matches the selected date, or if no date is selected
      const matchesSchool = school ? item.contact.school === school : true;
      // Check if the item matches the selected school, or if no school is selected
      let dateToFormat = format(item.contact.startDate, 'MM/dd/yyyy')
      const matchesDate = startDate ? dateToFormat === startDate : true;
      // Only include the item if it matches all the selected criteria
      return matchesLocation && matchesDate && matchesSchool;
    });

    setDataToShow(newDataAgain);
  }
  const [passportFileModalLoading, setPassportFileModalLoading] = useState(false);


  const onSelectLocation = (value) => {
    setSelectedLocation(value); // Update the selected state
    handleFilter(value, selectedSchool, selectedStartDate); // Call the handleFilter function with the updated filter values
  };
  const onSelectSchool = (value) => {
    setSelectedSchool(value); // Update the selected  state
    handleFilter(selectedLocation, value, selectedStartDate); // Call the handleFilter function with the updated filter values
  };
  const onSelectStartDate = (value) => {
    setSelectedStartDate(value); // Update the selected location state
    handleFilter(selectedLocation, selectedSchool, value); // Call the handleFilter function with the updated filter values
  };

  useEffect(() => {
    if (tableData) {
      const newData = tableData
        .filter((data) => {
          return data.contact.name
            .toLowerCase()
            .includes(filterName.toLowerCase());
        })
        .filter((data) => {
          if (isShowSelected) {
            return selectedItems.includes(data.id);
          }
          return true;
        });
      setTotalItems(newData.length);

      setDataToShow(newData);
    }
  }, [tableData, filterName, selectedItems, isShowSelected]);
  const handleSortingChange = (accessor) => {
    const sortOrder =
      accessor === sortField && order === "asc" ? "desc" : "asc";
    setSortField(accessor);
    setOrder(sortOrder);
    handleSorting(accessor, sortOrder);
  };

  const onLimitChange = (newLimit) => {
    setLimit(newLimit);
    // Reset current page to 1 when the limit changes
    setCurrentPage(1);
  };
  const onExportToExcel = () => {
    const headers = [
      "",
      "Status",
      "Name",
      "PP",
      "LM",
      "OIG",
      "SAM",
      "Type",
      "Email",
      "Phone",
      "Address",
      "Emergency Contact",
      "Emergency Phone",
      "SID/EID",
      "School",
      "Location",
      "Start Date",
      "Program",
    ];

    
    const wb = XLSX.utils.book_new();
    const searchData = tableData.filter((data) =>
      data.contact.name.toLowerCase().includes(filterName.toLowerCase())
  );
    const convertStatusDisplay = (status) => status === "Incomplete" ? "-" : status === "Complete" ? "+" : "";
    const wsData = [
      headers,
      ...(isShowSelected
        ? searchData.filter((data) => selectedItems.includes(data.id))
        : searchData
      )
        .slice(0)
        .map((item) => [
          "",
          item.assignmentStatus,
          item.contact.name,
          "",
          convertStatusDisplay(item.lm.status),
          convertStatusDisplay(item.oig),
          convertStatusDisplay(item.sam),
          item.userType,
          item.contact.email,
          item.contact.phone,
          item.contact.address,
          item.contact.emergencyContact,
          item.contact.emergencyContactPhone,
          item.contact.sIDEID,
          item.contact.school,
          item.contact.location || "",
          format(item.contact.startDate, 'MM/dd/yyyy'),
          item.contact.program,
        ]),
    ];
    console.log(wsData)
    const ws = XLSX.utils.aoa_to_sheet(wsData);

    // Adjust column widths
    const columnWidths = [
      { wpx: 20 }, // Selected column width,
      { wpx: 100 }, // Status column width,
      { wpx: 200 }, // Name column width,
      { wpx: 50 }, // PP column width,
      { wpx: 50 }, // LM column width,
      { wpx: 50 }, // OIG column width,
      { wpx: 50 }, // SAM column width,
      { wpx: 100 }, // Type column width,

      { wpx: 150 }, // Email column width
      { wpx: 100 }, // Phone column width
      { wpx: 200 }, // Address column width
      { wpx: 150 }, // Emergency Contact column width
      { wpx: 150 }, // Emergency Contact Phone column width
      { wpx: 150 }, // School column width
      { wpx: 150 }, // Name column width
      { wpx: 100 }, // SID-EID column width
      { wpx: 100 }, // UserType column width
      { wpx: 150 }, // Program column width
      { wpx: 150 }, // Location column width
      { wpx: 100 }, // Start Date column width
      { wpx: 100 }, // End Date column width
    ];
    ws["!cols"] = columnWidths;

    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "Passports - CPNW.xlsx");
  };

  const onCloseVideoTutorialModal = () => {
    setOpenVideoTutorialModal(false);
    setVideoUrl(undefined);
  };
  const onOpenVideoTutorialModal = (isOpen, videoUrl) => {
    setOpenVideoTutorialModal(isOpen);
    setVideoUrl(videoUrl);
  };

  const handleGetPassports = async (contactId) => {
    setIsFileManagementModalOpen(true);
    setPassportFileModalLoading(true);

    try {
      const rsp = await onGetPassportsData(contactId);
      const passports = rsp?.data || [];
      setContactDataWithPassports({
        contactId: contactId,
        passports: passports,
      });
    } catch (error) {
      console.error('Error fetching passports:', error);
      // Optionally, show user feedback here
    } finally {
      setPassportFileModalLoading(false);
    }
  };

  const handleAssignmentReview = async (assignmentId, reviewStatus, textForRejection) => {
    try {
      const success = await assignmentReview(assignmentId, reviewStatus, textForRejection);
      if (success) {
        setReviewAssignmentId(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const VideoPlayerButton = ({ label, videoUrl }) => {
    return (
      <>
        <div>
          {label && <strong>{label}: </strong>}
          <a
            className={styles.videoPopup}
            onClick={() => onOpenVideoTutorialModal(true, videoUrl)}
          >
            <FontAwesomeIcon icon={faVideo} size="lg" />
          </a>
        </div>
      </>
    );
  };
  return (
    <div>
      <div className={styles.selectItems}>
        <div className={styles.selectItem}>
          <DashboardSelect
            placeholder="Locations - Select All"
            options={locationsOptions}
            tip="Click here to filter results by location"
            onSelect={(value) => onSelectLocation(value)}
          />
        </div>
        <div className={styles.selectItem}>
          <DashboardSelect
            placeholder="Schools - Select All"
            options={schoolsOptions}
            tip="Click here to filter results by school"
            onSelect={(value) => onSelectSchool(value)}
          />
        </div>
        <div className={styles.selectItem}>
          <DashboardSelect
            placeholder="Clinical Start Date - Select All"
            options={startDatesOptions}
            tip="Click here to filter results by clinical start date"
            onSelect={(value) => onSelectStartDate(value)}
          />
        </div>
      </div>
      <div className={styles.table_key}>
        <DashboardPassportTableKey
          onClick={() => setIsSummaryReportItemModalOpen(true)}
        />
      </div>
      <div className={styles.tutorialHeaderText}>Tutorials: </div>
      <div className={styles.tutorialItem}>
        <VideoPlayerButton
          label={"Reporting"}
          videoUrl={
            "https://cpnw.blob.core.windows.net/documents/CoordinatorTutorials/Instructions/reportingHealthcare.mp4"
          }
        />
        <span style={{ padding: "0px 10px" }}> | </span>
        <div>
          <a
            href="https://cpnw.blob.core.windows.net/documents/CoordinatorTutorials/Instructions/HealthcareGuide.pdf"
            target="_blank"
            className={styles.healthcareGuideText}
          >
            Healthcare Guide (PDF)
          </a>
        </div>
      </div>
      <div className={styles.tableOptionContainer}>
        <div className={styles.itemsPerPageContent}>
          <DashboardItemsPerPage
            disabled={false}
            limit={limit}
            limitOptions={limitOptions}
            onLimitChange={onLimitChange}
          />
        </div>
        <div className={styles.buttonContent}>
          <Button
            text="Show All"
            className={styles.button}
            onClick={() => {
              setIsShowSelected(false);
              setCurrentPage(1);
            }}
          ></Button>
          <Button
            text="Show Selected"
            className={styles.button}
            onClick={() => {
              setIsShowSelected(true);
              setCurrentPage(1);
            }}
            disabled={selectedItems.length === 0}
          ></Button>
          <Button
            text="Export"
            className={styles.button}
            onClick={() => {
              onExportToExcel();
            }}
          ></Button>
        </div>
        <div className={styles.searchContent}>
          <DashboardSearch
            onInput={(input) => {
              setFilterName(input);
              setCurrentPage(1);
            }}
            searchDelay={0}
            minChar={0}
          />
        </div>
      </div>
      <div className={styles.table_wrapper}>
      <table className={styles.table}>
        <thead className={styles.theadContent}>
          <tr>
            <th className={styles.thStyle}> </th>
            {columns.map(({ label, accessor, sortable }) => {
              const cl = sortable
                ? sortField === accessor && order === "asc"
                  ? "up"
                  : sortField === accessor && order === "desc"
                  ? "down"
                  : "default"
                : "";
              return (
                <th
                  key={accessor}
                  onClick={
                    sortable ? () => handleSortingChange(accessor) : null
                  }
                  className={classNames(styles.thStyle, styles[cl])}
                  // style={{ width: widthByAccessor(accessor) }}
                >
                  {label}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {dataToShow.slice(startIndex, endIndex + 1).map((data) => {
            return (
              <DashboardPassportItem
                key={data.id}
                report={data}
                columns={columns}
                onContact={(info) => {
                  setCurrentItemInfo(info);
                  setIsContactInfoModalOpen(true);
                }}
                onLmClick={(data) => setDataLearningModuleModal(data)}
                onSelect={(id) => {
                  if (!selectedItems.includes(id)) {
                    setSelectedItems([...selectedItems, id]);
                  } else {
                    setSelectedItems(
                      selectedItems.filter((idSelected) => idSelected !== id)
                    );
                  }
                }}
                onReview={(assignmentId, status, textForRejection) => handleAssignmentReview(assignmentId, status, textForRejection)}
                selected={selectedItems.includes(data.id)}
                onFileClick={(contactId) => handleGetPassports(contactId)}
              />
            );
          })}
        </tbody>
      </table>
      </div>
      <div className={classNames(styles.assignmentPaginationRow, styles.row)}>
        <div className={classNames(styles.columnPagination, styles.column)}>
          <DashboardTablePagination
            onPageChange={(e) => setCurrentPage(e)}
            total={totalItems}
            perPage={limit}
          />
        </div>
      </div>
      <VideoTutorialModal
        isOpen={isOpenVideoTutorialModal}
        videoUrl={videoUrl}
      />

      {/* All modals */}
      {/* {!!reviewAssignmentId && (
        <DashboardReviewModal
          onSave={(reviewStatus) =>
            handleAssignmentReview(reviewAssignmentId, reviewStatus)
          }
          onClose={() => {
            setReviewAssignmentId(null);
          }}
          isOpen={true}
        />
      )} */}
      <DashboardContactInformationModal
        onClose={() => {
          setIsContactInfoModalOpen(false);
        }}
        contact={currentItemInfo}
        isOpen={isContactInfoModalOpen}
        headerTitle={"Contact Information"}
      />
      {/* <DashboardLearningStatusModal
        isOpen={dataLearningModuleModal?.length > 0}
        onClose={() => {
          setDataLearningModuleModal([]);
        }}
        modules={dataLearningModuleModal}
      /> */}
      {isFileManagementModalOpen && (
        <DashboardPassportFileModal
          isOpen={true}
          onClose={() => setIsFileManagementModalOpen(false)}
          contactDataWithPassports={contactDataWithPassports}
          passportUpload={passportUpload}
          passportDelete={passportDelete}
          onDownloadContactPassports={onDownloadContactPassports}
          isLoading={passportFileModalLoading}
          showDelete={showDelete}
          showDownload={showDownload}
          showUpload={showUpload}
        />
      )}
      <DashboardPassportReportItemModal
        children={
          <>
            <div>
              <ul class="no-bullet">
                <li>
                  <strong>PP</strong> - Passport Documents
                </li>
                <li>
                  <strong>LM</strong> - Learning Modules
                </li>
              </ul>
            </div>
            <div>
              <ul class="no-bullet">
                <li>
                  <strong>OIG</strong> - OIG Excluded Provider Check
                </li>
                <li>
                  <strong>SAM</strong> - SAM Excluded Provider Check
                </li>
              </ul>
            </div>
          </>
        }
        isOpen={isSummaryReportItemModalOpen}
        onClose={() => setIsSummaryReportItemModalOpen(false)}
      />
    </div>
  );
}

DashboardPassportTable.propTypes = {
  //Boolean to determine if modal is open
  data: PropTypes.array,
  //Passed in to make changes to the modals style
  columns: PropTypes.array,
};
