import { useMemo, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Assignments.module.css';
import { HeaderSection } from '@cpnw/ui';
import { DashboardAssignmentTable } from 'components/DashboardAssignmentTable';
import { AdminContainer } from 'hooks/useAdmin';
import { DashboardAffiliationRequestModal } from 'components/DashboardAffiliationRequestModal';

const Assignments = () => {
	const { assignmentsData, refetchAssignments, addOrUpdateAssignment, deleteAssignment, affiliationRequestPost } = AdminContainer.useContainer();

	useEffect(()=>{
		refetchAssignments()
	}, [])

	const assignments = useMemo(() => {
		const formatedData = convertApiResponseToAssignments(assignmentsData);
		return formatedData;
	}, [assignmentsData]);

	const selectOptions = useMemo(() => {
		const options = convertApiResponseToSelectBoxOptions(assignmentsData);
		return options;
	}, [assignmentsData]);

	const selectAllHCOptions = useMemo(() => { 
		const options = convertApiResponseToAllHCSelectBoxOptions(assignmentsData);
		return options;
	}, [assignmentsData]);

	const [assignmentsState, setAssignmentsState] = useState([]);
	const [affiliationListOptions, setAffiliationListOptions] = useState([]);
	const [isOpenModal, setOpenModal] = useState(false);

	useEffect(() => {
		if (assignments && assignments.length > 0) {
			setAssignmentsState(assignments);
		} else {
			setAssignmentsState([]);
		}

		// Cleanup function
		return () => {
			setAssignmentsState([]);
		};
	}, [assignments]);

	useEffect(() => {	
		if (selectOptions && selectOptions.length > 0) {
			setAffiliationListOptions(selectOptions);
		} else {
			setAffiliationListOptions([]);
		}

		// Cleanup function
		return () => {
			setAffiliationListOptions([]);
		};
	}, [selectOptions]);

	const formatDate = (dateStr) => {
		const [month, day, year] = dateStr.split('/');
		// Pad month and day with leading zeros if necessary
		const paddedMonth = month.padStart(2, '0');
		const paddedDay = day.padStart(2, '0');
		return `${year}-${paddedMonth}-${paddedDay}`;
};


	const handleAddAssignment = async (newAssignment) => {
		const bodyData = {
			cid: newAssignment.cid,
			hid: newAssignment.hid,
			start: formatDate(newAssignment.startDate),
			end: formatDate(newAssignment.endDate)
		}

		const result = await addOrUpdateAssignment(bodyData);

		newAssignment.aid = result.aid;
		newAssignment.edbuid = result.eid;
		//newAssignment.id = `${newAssignment.cid}__${result.aid}`;

		setAssignmentsState((prevAssignments) => {
				const newState = prevAssignments.map((assignment) =>
					assignment.id === newAssignment.id ? newAssignment : assignment
				);

				return newState;
			}
		);
	};

	const handleEditAssignment = async (updatedAssignment, isNewAssignmentCreated=false) => {
		const bodyData = {
			aid: updatedAssignment.aid,
			cid: updatedAssignment.cid,
			hid: updatedAssignment.hid,
			start: formatDate(updatedAssignment.startDate),
			end: formatDate(updatedAssignment.endDate),
			isNewAssignmentCreated: isNewAssignmentCreated,
		}

		const result = await addOrUpdateAssignment(bodyData);
		
		updatedAssignment.edbuid = result.eid;

		setAssignmentsState((prevAssignments) =>
				prevAssignments.map((assignment) =>
						assignment.id === updatedAssignment.id ? updatedAssignment : assignment
				)
		);
	};

	const handleDeleteAssignment = async (removeAssignment) => {
		await deleteAssignment(removeAssignment.cid, removeAssignment.aid, removeAssignment.edbuid);
	
		// Count the number of assignments with the same cid
		const assignmentCount = assignmentsState.filter(assignment => assignment.cid === removeAssignment.cid).length;
	
		// Check if there is more than one assignment with the same cid
		if (assignmentCount > 1) { 
			setAssignmentsState((prevAssignments) =>
				prevAssignments.filter((assignment) => assignment.id !== removeAssignment.id)
			);
		} else {
			setAssignmentsState((prevAssignments) => {
				const contactRowToReplace = {
					...removeAssignment, 
					id: `${removeAssignment.cid}__${null}`, 
					aid: null, 
					hid: null, 
					edbuid: null,
					location: '', 
					startDate: '', 
					endDate: ''
				};
				const newState = prevAssignments.map((assignment) =>
					assignment.id === removeAssignment.id ? contactRowToReplace : assignment
				);
	
				return newState;
			});
		}
	};
	
	const allHCOptions = () => {
		return {
			options: selectAllHCOptions,
			defaultValue: '' 
		};
	};

	const handleSubmitAffiliationRequest = async (data) => { 
		if(data.healthcare === '' || data.program === '') {
			setOpenModal(false);
			return;
		}

		try {
			const result = await affiliationRequestPost(data);
		} catch (error) {
			console.error(error);
		} finally {
			setOpenModal(false);
		}
	};

	return (
		<section className={styles.dashboardAssignmentsPage}>
			<HeaderSection header={'Clinical Assignments'} />
			<div className={classNames(styles.row)}>
				<div className={classNames(styles.column)}>
					<p>
						<a href='#' onClick={() => {setOpenModal(true)}}>Clinical Site Affiliation Request</a>
					</p>
				</div>
			</div>
			<hr />
			<div className={classNames(styles.row)}>
				<DashboardAssignmentTable
					assignments={assignmentsState ?? []}
					setAssignments={setAssignmentsState}
					onAddAssignment={handleAddAssignment}
					onEditAssignment={handleEditAssignment}
					onDeleteAssignment={handleDeleteAssignment}
					selectOptions={affiliationListOptions}
					refetchAssignments={refetchAssignments}
				/>
			</div>
		<DashboardAffiliationRequestModal 
			isOpen={isOpenModal} 
			onClose={() => setOpenModal(false)} 
			locationOptions={allHCOptions()} 
			onSubmit={handleSubmitAffiliationRequest} 
			coordinator={assignmentsData?.coordinator}/>
		</section>
	);
};

Assignments.propTypes = {};

export default Assignments;

const convertApiResponseToAssignments = apiResponse => {
	const assignments = [];

	const formatDateFromApi = (dateStr) => {
		const date = new Date(dateStr);
		const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
		const day = date.getUTCDate().toString().padStart(2, '0');
		const year = date.getUTCFullYear();
		return `${month}/${day}/${year}`;
	};

	apiResponse?.canAssignStudents?.forEach(student => {
		if (student.Assignments && student.Assignments.length > 0) {
			student.Assignments.forEach(assignment => {
				assignments.push({
          id: `${student.ContactIDPK}__${assignment.AssignmentIDpk}`,
					cid: student.ContactIDPK,
					aid: assignment.AssignmentIDpk,
					name: `${student.NameLast},${student.NameFirst}`,
					email: student.email || '',
					phone: student.PrimaryPhone || '',
					address: `${student.Address1 || ''} ${
						student.Address2 || ''
					}`,
					emergencyContact: student.EmergencyContact || '',
					emergencyContactPhone: student.EmergencyPhone || '',
					school: student.EDFacilityName,
					sIDEID: student.IndividualID || '',
					userType: student.TypeContact,
					edbuid: assignment.EDBusinessUnitidfk,
					program: student.BusinessUnitAbbrev,
					hid: assignment.HCPBusinessUnitIDFK,
					location: assignment.HCFacilityName, // Use facility map to get the FacilityName
					startDate: assignment.StartDate
						? formatDateFromApi(assignment.StartDate)
						: '',
					endDate: assignment.EndDate
						? formatDateFromApi(assignment.EndDate)
						: ''
				});
			});
		} else {
			assignments.push({
        id: `${student.ContactIDPK}__${null}`,
				cid: student.ContactIDPK,
				aid: null,
				name: `${student.NameLast},${student.NameFirst}`,
				email: student.email || '',
				phone: student.PrimaryPhone || '',
				address: `${student.Address1 || ''} ${student.Address2 || ''}`,
				emergencyContact: student.EmergencyContact || '',
				emergencyContactPhone: student.EmergencyPhone || '',
				school: student.EDFacilityName,
				sIDEID: student.IndividualID || '',
				userType: student.TypeContact,
				program: student.BusinessUnitAbbrev,
				hid: null,
				location: '',
				startDate: '',
				endDate: ''
			});
		}
	});

	return assignments;
};

const convertApiResponseToSelectBoxOptions = (response) => {
	if (!response || !response.canAssignAffiliationList) {
			return [];
	}

	return response.canAssignAffiliationList.map((affiliation) => ({
			key: affiliation.hcpBusinessUnitIDfk.toString(),
			value: affiliation.HCFacilityName,
	}));
};

const convertApiResponseToAllHCSelectBoxOptions = (response) => {
	if (!response || !response.allHealthCareFacilities) {
			return [];
	}

	return response.allHealthCareFacilities.map((hcFacility) => ({
			key: hcFacility.FacilityIDPK.toString(),
			value: hcFacility.FacilityName,
	}));
};