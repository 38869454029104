import React, { useEffect, useState } from "react";
import { CpnwContainer } from "hooks/useCpnw";
import DashboardClinicalRosterTable from "components/DashboardClinicalRosterTable";

import styles from "./HcClinicalRoster.module.css";

const HcClinicalRoster = () => {
  const {
    reportData,
  } = CpnwContainer.useContainer();

  const [reports, setReports] = useState([]);

  useEffect(() => {
    if (reportData && reportData.total > 0) {
      setReports([...reportData.data]);
    }
  }, [reportData]);


  return (
    <div className={styles.container}>
      <h1>Clinical Rosters</h1>
      <div className={styles.content}>
        {reports?.length > 0 ? (
          <DashboardClinicalRosterTable items={mapReports(reports)} />
        ) : null}
      </div>
    </div>
  );
};

const mapReports = (reports) => {
  let result = (reports || []).map((m) => {
    return {
      id: m.assignment?.assignmentId || m.assignment?.assigmentId,
      name: m.name,
      location: m.assignment?.hcpFacilityName,
      startDate: m.assignment?.startDate,
      endDate: m.assignment?.endDate,
      school: m.facilityName,
      program: m.businessUnit,
      type: m.typeContactName,
      contact: {
        id: m.id,
        name: m.name,
        email: m.email,
        phone: m.primaryPhone,
        address: m.address,
        emergencyContact: m.emergencyContact,
        emergencyContactPhone: m.emergencyContactPhone,
        school: m.facilityName,
        program: m.businessUnit,
        sIDEID: m.individualID,
        location: m.assignment?.hcpFacilityName,
        startDate: m.assignment?.startDate,
      },
    };
  });

  return result;
};

export default HcClinicalRoster;
