import PropTypes from "prop-types";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFolderOpen,
  faMinus,
  faPlus,
  faCaretDown,
} from "@fortawesome/free-solid-svg-icons";
import IconStatusHelper from "components/_Helpers/IconStatusHelper";
import DashboardPassportItemFields from "components/DashboardPassportItem/DashboardPassportItemFields";
import DashboardRadio from "components/DashboardRadio";
import styles from "./DashboardPassportItem.module.css";
import { useState, useRef, useEffect } from "react";

const DashboardPassportItem = ({
  report,
  columns,
  onContact,
  onSelect,
  onLmClick,
  onReview,
  selected,
  onFileClick,
}) => {
  const [reviewModalHidden, setReviewModalHidden] = useState(true);
  const reviewModalRef = useRef(null);
  const [lmModalHidden, setLmModalHidden] = useState(true);
  const lmModalRef = useRef(null);
  const [rejectionText, setRejectionText] = useState(
    report.rejectionContent || ""
  );
  const [selectedStatus, setSelectedStatus] = useState(
    report[DashboardPassportItemFields.assignmentStatus] || ""
  );
  const handleSave = () => {
    onReview(
      report[DashboardPassportItemFields.assignmentId],
      selectedStatus,
      rejectionText
    );
    setReviewModalHidden(true);
  };
  const handleLmClick = () => {
    setLmModalHidden(!lmModalHidden);
  };
  const handleClickOutside = (event) => {
    if (
      reviewModalRef.current &&
      !reviewModalRef.current.contains(event.target)
    ) {
      setReviewModalHidden(true);
    }
    if (lmModalRef.current && !lmModalRef.current.contains(event.target)) {
      setLmModalHidden(true);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleOnSelect = () => {
    onSelect(report.id);
  };
  const handleClear = () => {
    setSelectedStatus("")
  };
  const handleRenderTd = (accessor) => {
    const tData = report[accessor];
    if (tData != null) {
      switch (accessor) {
        case DashboardPassportItemFields.assignmentStatus:
          return (
            <>
              <a
                // onClick={() => onReview(report[DashboardPassportItemFields.assignmentId])}\
                onClick={() => setReviewModalHidden(!reviewModalHidden)}
                className={classNames([
                  styles.statusContent,
                  selected ? styles.selectedStatusContent : "",
                  styles[`status${tData}`],
                ])}
              >
                {tData} <FontAwesomeIcon icon={faCaretDown} size="sm" />
              </a>
              <div
                ref={reviewModalRef}
                className={
                  reviewModalHidden
                    ? styles.hidden_half_modal
                    : styles.half_modal
                }
              >
                <div className={styles.status}>Status:</div>
                <div className={styles.radioGroup}>
                  <DashboardRadio
                    isSelected={selectedStatus === "Accepted"}
                    onChange={setSelectedStatus}
                    value="Accepted"
                    label="Accepted"
                  />
                  <DashboardRadio
                    isSelected={selectedStatus === "Rejected"}
                    onChange={setSelectedStatus}
                    value="Rejected"
                    label="Rejected"
                  />
                </div>
                {selectedStatus === "Rejected" && (
                  <>
                    <label htmlFor="rejectionArea">Rejection Message:</label>
                    <textarea
                      name="rejectionArea"
                      id="rejectionArea"
                      onChange={(e) => setRejectionText(e.target.value)}
                      style={{ marginBottom: "10px" }}
                      className={styles.textareaStyle}
                      value={rejectionText}
                    ></textarea>
                  </>
                )}
                <div className={styles.alignRight}>
                  <button
                    className={`${styles.saveButton}`}
                    onClick={handleClear}
                  >
                    CLEAR
                  </button>
                  <button
                    className={`${styles.saveButton}`}
                    onClick={handleSave}
                  >
                    SAVE
                  </button>
                </div>
              </div>
            </>
          );

        case DashboardPassportItemFields.nameOfContact:
          return (
            <a
              onClick={() => onContact(tData)}
              className={classNames(
                styles.nameContentMain,
                selected ? styles.selectedNameContent : styles.nameContent
              )}
            >
              {tData.name}
            </a>
          );

        case DashboardPassportItemFields.passport:
          return (
            <FontAwesomeIcon
              icon={faFolderOpen}
              className={styles.faFolderIcon}
              size="sm"
              onClick={() => onFileClick(tData)}
            />
          );

        case DashboardPassportItemFields.learningModule:
          return tData && tData.status == "Complete" ? (
            <>
              <FontAwesomeIcon
                icon={faPlus}
                className={styles.faPlus}
                size="sm"
                // onClick={() => onLmClick(tData.data || [])}
                onClick={() => handleLmClick(tData.data || [])}
                style={{ cursor: "pointer" }}
              />
              <div
                className={
                  lmModalHidden
                    ? styles.hidden_lm_half_modal
                    : styles.half_lm_modal
                }
              >
                <p>test</p>
              </div>
            </>
          ) : (
            <>
              <FontAwesomeIcon
                icon={faMinus}
                className={styles.faMinusIcon}
                size="sm"
                onClick={() => handleLmClick(tData?.data || [])}
                style={{ cursor: "pointer" }}
              />
              <div
                ref={lmModalRef}
                className={
                  lmModalHidden
                    ? styles.hidden_lm_half_modal
                    : styles.half_lm_modal
                }
              >
                <div
                  className={styles.modalContent}
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className={styles.modalHeader}>
                    <h2>Learning Module Status</h2>
                  </div>
                  <div className={styles.modalBody}>
                    <ul className={styles.moduleList}>
                      {tData?.data.map((module, index) => (
                        <li
                          key={index}
                          className={`${styles.moduleItem} ${
                            index % 2 === 0 ? styles.even : styles.odd
                          }`}
                        >
                          <span className={styles.moduleName}>
                            {module.moduleName}
                          </span>
                          <span
                            className={`${styles.status} ${
                              styles[module.status.toLowerCase()]
                            }`}
                          >
                            {IconStatusHelper(
                              module.status,
                              module.expirationDate
                            )}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </>
          );

        case DashboardPassportItemFields.oig:
        case DashboardPassportItemFields.sam:
          return IconStatusHelper(tData);

        case DashboardPassportItemFields.userContactType:
          return tData;

        default:
          return null;
      }
    }
  };

  return (
    <tr
      key={`${report.id}`}
      className={classNames(
        selected ? styles.selectedBackground : "",
        styles.custom_height
      )}
    >
      <td className={styles.checkbox}>
        <input
          type="checkbox"
          defaultChecked={selected}
          onClick={handleOnSelect}
        />
      </td>
      {columns.map(({ accessor }) => {
        return (
          <td className={styles.tableCell} key={accessor}>
            {handleRenderTd(accessor)}
          </td>
        );
      })}
    </tr>
  );
};

DashboardPassportItem.propTypes = {
  //Boolean to determine if modal is open
  isOpen: PropTypes.bool,
  //Passed in to make changes to the modals style
  className: PropTypes.string,
};

export default DashboardPassportItem;
