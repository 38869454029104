import { React, useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import DashboardRadio from "components/DashboardRadio";
import styles from "./ReportUserItem.module.css";

const ReportUserItem = ({
  selected,
  onSelect,
  user,
  itemType = "active",
  onClickOpenReasonModel,
  reason,
  additionalReason,
}) => {
  const [actionState, setActionState] = useState(selected);

  const handleActionChange = useCallback((value) => {
    setActionState(value);
    onSelect(user, value);
  });

  useEffect(() => {
    setActionState(selected);
  }, [selected]);

  const renderRadioButtons = () => {
    switch (itemType) {
      case "active":
        return (
          <>
            <td>
              <DashboardRadio
                isSelected={actionState === "deactivate"}
                onChange={handleActionChange}
                value="deactivate"
              />
            </td>
            <td>
              <DashboardRadio
                isSelected={actionState === "archive"}
                onChange={handleActionChange}
                value="archive"
              />
            </td>
            <td>
              <DashboardRadio
                isSelected={actionState === "clear"}
                onChange={handleActionChange}
                value="clear"
              />
            </td>
          </>
        );
      case "inactive":
        return (
          <>
            <td>
              <DashboardRadio
                isSelected={actionState === "activate"}
                onChange={handleActionChange}
                value="activate"
              />
            </td>
            <td>
              <DashboardRadio
                isSelected={actionState === "archive"}
                onChange={handleActionChange}
                value="archive"
              />
            </td>
            <td>
              <DashboardRadio
                isSelected={actionState === "clear"}
                onChange={handleActionChange}
                value="clear"
              />
            </td>
          </>
        );
      case "newRequest":
      case "returned":
        return (
          <>
            <td>
              <DashboardRadio
                isSelected={actionState === "activate"}
                onChange={handleActionChange}
                value="activate"
              />
            </td>
            <td>
              <DashboardRadio
                isSelected={actionState === "return"}
                onChange={handleActionChange}
                value="return"
              />
            </td>
            <td>
              <DashboardRadio
                isSelected={actionState === "deny"}
                onChange={handleActionChange}
                value="deny"
              />
            </td>
            <td>
              <DashboardRadio
                isSelected={actionState === "clear"}
                onChange={handleActionChange}
                value="clear"
              />
            </td>
          </>
        );
      default:
        return null;
    }
  };

  const handleSetReason = () => {
    if (onClickOpenReasonModel) onClickOpenReasonModel(user);
  };

  const isReasonSet = () => {
    return reason || additionalReason?.trim();
  };

  return (
    <>
      {renderRadioButtons()}
      {(itemType === "returned" || itemType === "newRequest") && (
        <td>
          <input
            type="button"
            value={isReasonSet() ? "Edit Reason" : "Set Reason"}
            className={styles.reason_button}
            onClick={handleSetReason}
          />
        </td>
      )}
      <td>{user.NameLast ?? ""}</td>
      <td>{user.NameFirst ?? ""}</td>
      <td>{user.IndividualID ?? ""}</td>
      <td>{user.email ?? ""}</td>
      <td>{user.BusinessUnitAbbrev ?? ""}</td>
      {(itemType === "active" || itemType === "inactive") && (
        <td>
          {user.ApprovalDate
            ? new Date(user.ApprovalDate).toISOString().split("T")[0]
            : ""}
        </td>
      )}
      {(itemType === "newRequest" || itemType === "returned") && (
        <td>
          {user.Created
            ? new Date(user.Created).toISOString().split("T")[0]
            : ""}
        </td>
      )}
      <td>{user.TypeContact ?? ""}</td>
      <td>
        {user.BackgroundCheckStatus !== null
          ? user.BackgroundCheckStatus
          : "Pending"}
      </td>
      <td>{user.ReportStatus !== null ? user.ReportStatus : "N/A"}</td>
      <td>{user.ReportETA !== null ? user.ReportETA : "N/A"}</td>
      <td>
        {user.BackgroundCheckURL !== null ? (
          <a
            href={user.BackgroundCheckURL}
            target="_blank"
            rel="noopener noreferrer"
          >
            {user.BackgroundCheckURL.length > 50
              ? `${user.BackgroundCheckURL.slice(0, 50)}...`
              : user.BackgroundCheckURL}
          </a>
        ) : (
          "N/A"
        )}
      </td>
    </>
  );
};
ReportUserItem.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    sid: PropTypes.string,
    email: PropTypes.string,
    program: PropTypes.string,
    approvalDate: PropTypes.string,
    userType: PropTypes.string,
    backgroundCheck: PropTypes.string,
    reportStatus: PropTypes.string,
    reportETA: PropTypes.string,
    backgroundCheckURL: PropTypes.string,
  }),
  selected: PropTypes.oneOf([
    "activate",
    "deactivate",
    "archive",
    "clear",
    "decline",
    "return",
    "",
  ]),
  onSelect: PropTypes.func.isRequired,
};

export default ReportUserItem;
