import React, { useEffect, useState } from "react";
import { HeaderSection } from "@cpnw/ui";
import { AccountContainer } from "hooks/useAccount";
import DashboardPageTabs from "components/DashboardPageTabs";
import EdSummary from "./EdSummary";
import EdOIGSAMCheck from "./EdOIGSAMCheck";
import HcPassport from "./HcPassport";
import HcOIGSAMCheck from "./HcOIGSAMCheck";
import HcClinicalRoster from "./HcClinicalRoster";
import styles from "./Reports.module.css";
import { CanPermissions } from "pages/enums";

const Reports = () => {
  const { meData, hasCanPermissions } = AccountContainer.useContainer();

  const [tabs, setTabs] = useState([]);
  const [activeTab, setActiveTab] = useState("");

  useEffect(() => {
    if (meData && (!tabs || tabs.length == 0)) {
      let roles = meData.roles;
      let tabs = [];

      if (roles.includes("HealthCare")){
        tabs = [Tabs.HcPassport];
        if(hasCanPermissions(CanPermissions.CanHIPAA)){
          tabs.push(Tabs.HcOIGSAMCheck)
        }
        tabs.push(Tabs.HcClinicalRoster)
      }
      else if (roles.includes("Education")){
        tabs = [Tabs.EdSummary];
        if(hasCanPermissions(CanPermissions.CanHIPAA)){
          tabs.push(Tabs.EdOIGSAMCheck)
        }
      }

      if (tabs.length > 0) {
        setTabs(tabs);
        setActiveTab(tabs[0]);
      }
    }
  }, [meData, tabs]);

  return (
    <section>
      <HeaderSection header={"Reports"} />
      <div className={styles.pageTabContaner}>
        <DashboardPageTabs
          titleList={tabs}
          activeTitle={activeTab}
          onChange={(tab) => setActiveTab(tab)}
        />
      </div>

      <div className={styles.tableContainer}>
        {activeTab == Tabs.EdSummary && <EdSummary />}
        {activeTab == Tabs.EdOIGSAMCheck && <EdOIGSAMCheck />}
        {activeTab == Tabs.HcPassport && <HcPassport />}
        {activeTab == Tabs.HcOIGSAMCheck && <HcOIGSAMCheck />}
        {activeTab == Tabs.HcClinicalRoster && <HcClinicalRoster />}
      </div>
    </section>
  );
};

const Tabs = {
  EdSummary: "Summary",
  EdOIGSAMCheck: "OIGSAMCheck",
  HcPassport: "Passport",
  HcOIGSAMCheck: "OIG/SAM Check",
  HcClinicalRoster: "Clinical Roster",
};

export default Reports;
