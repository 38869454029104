import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import styles from "./DashboardPhoneNumberInput.module.css";
import { InputBox } from "@cpnw/ui";

const DashboardPhoneNumberInput = ({ value, setValue, setError, ...props }) => {
    const phoneRegex = /^\d{3}-\d{3}-\d{4}$/;
    const phoneNumberRef = useRef();

    const phoneFormatter = (value) => {
        // Strip all characters from the input except digits
        let formattedInput = value.replace(/\D/g, '');
        // Trim the remaining input to ten characters, to preserve phone number format
        formattedInput = formattedInput.substring(0, 10);
        // Based upon the length of the string, add formatting as necessary
        const size = formattedInput.length;
        if (size === 0) {
            formattedInput = formattedInput;
        } else if (size < 4) {
            formattedInput = `${formattedInput}`;
        } else if (size < 7) {
            formattedInput = `${formattedInput.substring(0, 3)}-${formattedInput.substring(3, 6)}`;
        } else {
            formattedInput = `${formattedInput.substring(0, 3)}-${formattedInput.substring(3, 6)}-${formattedInput.substring(6, 10)}`;
        }
        return formattedInput;
    };

    const validatePhoneNumber = () => {
        let error;
        if (!value?.trim()) {

        } else if (phoneRegex.test(value)) {
            error = null;
            setValue(value);
        } else {
            error = 'Please enter a valid phone number.';
        }
        return error;
    }

    return (
        <>
            <InputBox
                id={props.id || 'phoneNumber'}
                placeholder={props.placeholder || "xxx-xxx-xxxx"}
                label={props.label || "Phone Number"}
                tip={props.tip || "Enter your phone number."}
                value={value}
                autoComplete={props.autoComplete || "off"}
                setValue={(e) => {
                    setValue(phoneFormatter(e.target.value));
                }}
                onBlur={(e) => {
                    setError(validatePhoneNumber());
                }}
                ref={phoneNumberRef}
                {...props}
            />
        </>
    );
};

DashboardPhoneNumberInput.propTypes = {
    value: PropTypes.string.isRequired,
    setValue: PropTypes.func.isRequired,
    setError: PropTypes.func,
};
export default DashboardPhoneNumberInput;
