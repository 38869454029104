import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import * as XLSX from "xlsx";
import DashboardClinicalRosterItem from "components/DashboardClinicalRosterItem/DashboardClinicalRosterItem";
import DashboardSelect from "components/DashboardSelect";
import DashboardSearch from "components/DashboardSearch";
import DashboardSortTableHeader from "components/DashboardSortTableHeader";
import DashboardTablePagination from "components/DashboardTablePagination";
import { DashboardItemsPerPage } from "components/DashboardItemsPerPage";
import { DashboardContactInformationModal } from "components/DashboardContactInformationModal";
import { Button } from "@cpnw/ui";
import styles from "./DashboardClinicalRosterTable.module.css";

const DashboardClinicalRosterTable = ({ items }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentItems, setCurrentItems] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedSchool, setSelectedSchool] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [itemsSelected, setItemsSelected] = useState(false);
  const [totalItems, setTotalItems] = useState(items.length);
  const [modalContactInfo, setModalContactInfo] = useState(null);
  const [sortCriteria, setSortCriteria] = useState({
    column: "startDate",
    direction: "asc",
  });

  const sortedItems = useMemo(() => {
    // Filter items based on search term
    let filteredItems = items.filter((item) =>
      Object.values(item).some((value) =>
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );

    // Apply additional filters based on selected criteria
    if (selectedLocation) {
      filteredItems = filteredItems.filter(
        (item) => item.location === selectedLocation
      );
    }
    if (selectedSchool) {
      filteredItems = filteredItems.filter(
        (item) => item.school === selectedSchool
      );
    }
    if (selectedStartDate) {
      filteredItems = filteredItems.filter(
        (item) => formatDate(item.startDate) === selectedStartDate
      );
    }

    // Sort the filtered items
    const sorted = filteredItems.sort((a, b) => {
      const { column, direction } = sortCriteria;
      const aValue = a[column];
      const bValue = b[column];
      return direction === "asc"
        ? aValue > bValue
          ? 1
          : -1
        : aValue < bValue
          ? 1
          : -1;
    });

    return sorted;
  }, [
    items,
    searchTerm,
    selectedLocation,
    selectedSchool,
    selectedStartDate,
    sortCriteria,
  ]);

  const filteredItems = useMemo(() => {
    let filtered = sortedItems;

    if (searchTerm) {
      filtered = sortedItems.filter((item) =>
        Object.values(item).some((value) =>
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }

    return filtered;
  }, [sortedItems, searchTerm]);

  useEffect(() => {
    setCurrentPage(1);
    const newCurrentItems = filteredItems.slice(0, itemsPerPage);
    setCurrentItems(newCurrentItems);

    if (selectedItems.length > 0) {
      setItemsSelected(true);
    } else {
      setItemsSelected(false);
    }
  }, [currentPage, itemsPerPage, sortedItems, selectedItems, itemsSelected]);

  // Effect for logging selected items
  useEffect(() => {
    console.log("Selected Items:", selectedItems);
  }, [selectedItems]);

  // Button Functions
  const handleShowAll = () => {
    setCurrentItems(sortedItems.slice(0, itemsPerPage)); // Display first page of sorted items
    setCurrentPage(1); // Reset to first page when showing all items
    setTotalItems(sortedItems.length);
  };

  const handleShowSelected = () => {
    if (selectedItems.length > 0) {
      const selectedItemsData = selectedItems.map((id) =>
        items.find((item) => item.id === id)
      );

      // Paginate the selected items based on the current page and items per page
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = Math.min(
        startIndex + itemsPerPage,
        selectedItemsData.length
      );
      const paginatedItems = selectedItemsData.slice(startIndex, endIndex);

      setCurrentItems(paginatedItems);
      setTotalItems(selectedItemsData.length);
    } else {
      setItemsSelected(false);
      // Handle the case when no items are selected
      console.log("No selected items to show.");
    }
  };

  const handleExport = () => {
    const headers = [
      "",
      "Name",
      "Location",
      "Start Date",
      "End Date",
      "School",
      "Program",
      "Type",
      "Email",
      "Phone",
      "Address",
      "Emergency Contact",
      "EmergencyPhone",
      "SID/EID",
    ];

    const wb = XLSX.utils.book_new();
    const searchData = currentItems.filter((data) =>
      data.contact.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const wsData = [
      headers,
      ...(selectedItems?.length > 0
        ? searchData.filter((data) => selectedItems.includes(data.id))
        : searchData
      )
        .slice(0)
        .map((item) => [
          "",
          item.name,
          item.location,
          formatDate(item.startDate),
          formatDate(item.endDate),
          item.school,
          item.program,
          item.type,
          item.contact.email,
          item.contact.phone,
          item.contact.address,
          item.contact.emergencyContact,
          item.contact.emergencyContactPhone,
          item.contact.sIDEID,
        ]),
    ];
    const ws = XLSX.utils.aoa_to_sheet(wsData);

    // Adjust column widths
    const columnWidths = [
      { wpx: 20 }, // Selected column width,
      { wpx: 200 }, // Name column width,
      { wpx: 150 }, // Location column width
      { wpx: 100 }, // Start Date column width
      { wpx: 100 }, // End Date column width
      { wpx: 150 }, // School column width
      { wpx: 150 }, // Program column width
      { wpx: 100 }, // Type column width,
      { wpx: 150 }, // Email column width
      { wpx: 100 }, // Phone column width
      { wpx: 200 }, // Address column width
      { wpx: 150 }, // Emergency Contact column width
      { wpx: 150 }, // Emergency Contact Phone column width
      { wpx: 100 }, // SID-EID column width
    ];
    ws["!cols"] = columnWidths;

    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "Clinical Rosters - CPNW.xlsx");
  };

  // Select Filtering
  const locationsOptions = Array.from(
    new Set(items.map((item) => item.location))
  );
  const schoolsOptions = Array.from(new Set(items.map((item) => item.school)));
  const startDatesOptions = Array.from(
    new Set(
      items
        .map((item) => {
          return formatDate(item.startDate);
        })
        .filter((date) => date !== null)// Remove null values from the array
        .sort((a, b) => new Date(b) - new Date(a))
    )
  );

  function formatDate(dateString) {
    // Check if item.startDate is not null or undefined
    if (dateString) {
      const utcDateString = dateString;

      // Convert the UTC date to a Date object
      const date = new Date(utcDateString);

      // Extract components
      const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      const day = String(date.getUTCDate()).padStart(2, '0');
      const year = date.getUTCFullYear();

      // Format to "MM/dd/yyyy"
      const formattedDate = `${month}/${day}/${year}`;
      return formattedDate;
    }
  }

  const handleFilter = (value, selectType) => {
    switch (selectType) {
      case "Locations":
        setSelectedLocation(value);
        break;
      case "Schools":
        setSelectedSchool(value);
        break;
      case "StartDates":
        setSelectedStartDate(value);
        break;
      default:
        break;
    }

    setCurrentPage(1); // Reset to first page when changing items per page
  };

  // Handle pagination change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle items per page change
  const handleItemsPerPageChange = (perPage) => {
    setItemsPerPage(perPage);
    setCurrentPage(1); // Reset to first page when changing items per page
  };

  // Handle search input
  const handleSearch = (input) => {
    setSearchTerm(input);
    setCurrentItems(filteredItems);
    setTotalItems(filteredItems.length);
    setCurrentPage(1); // Reset to first page when performing search    
  };

  // Handle sorting
  const handleSort = (column) => {
    setSortCriteria((prevCriteria) => {
      if (prevCriteria.column === column) {
        // If clicking the same column, toggle the direction
        const newDirection = prevCriteria.direction === "asc" ? "desc" : "asc";
        return { column, direction: newDirection };
      } else {
        // If clicking a different column, default to ascending order
        return { column, direction: "asc" };
      }
    });
  };

  // Handle Selection

  const handleSelect = (id) => {
    setSelectedItems((selectedItems) => {
      if (selectedItems.includes(id)) {
        return selectedItems.filter((selectedId) => selectedId !== id);
      } else {
        return [...selectedItems, id];
      }
    });
  };

  //  define onSelectSchools and onSelectStartDates functions
  const onSelectLocation = (value) => {
    setSelectedLocation(value); // Update the selected state
    handleFilter(); // Call the handleFilter function with the updated filter values
  };
  const onSelectSchool = (value) => {
    setSelectedSchool(value); // Update the selected  state
    handleFilter(); // Call the handleFilter function with the updated filter values
  };
  const onSelectStartDate = (value) => {
    setSelectedStartDate(value); // Update the selected location state
    handleFilter(); // Call the handleFilter function with the updated filter values
  };

  console.log(startDatesOptions)

  return (
    <div className={styles.clinicalRosterTableContainer}>
      {modalContactInfo && (
        <DashboardContactInformationModal
          onClose={() => {
            setModalContactInfo(null);
          }}
          contact={modalContactInfo}
          isOpen={true}
        />
      )}
      <div>
        <div className={styles.selectItems}>
          <div className={styles.selectItem}>
            <DashboardSelect
              placeholder="Locations - Select All"
              options={locationsOptions}
              tip="Click here to filter results by location"
              onSelect={(value) => onSelectLocation(value)}
            />
          </div>
          <div className={styles.selectItem}>
            <DashboardSelect
              placeholder="Schools - Select All"
              options={schoolsOptions}
              tip="Click here to filter results by school"
              onSelect={(value) => onSelectSchool(value)}
            />
          </div>
          <div className={styles.selectItem}>
            <DashboardSelect
              placeholder="Clinical Start Date - Select All"
              options={startDatesOptions}
              tip="Click here to filter results by clinical start date"
              onSelect={(value) => onSelectStartDate(value)}
            />
          </div>
        </div>
        <div>
          <div className={styles.itemsPerPage}>
            <DashboardItemsPerPage
              disabled={false}
              limit={itemsPerPage}
              limitOptions={[5, 10, 20, 50]}
              onLimitChange={handleItemsPerPageChange}
            />
          </div>
          <div className={styles.searchBox}>
            <DashboardSearch
              onInput={handleSearch}
              searchDelay={300}
              minChar={3}
            />
          </div>
          <div className={styles.filteringButtonContainer}>
            <Button
              text="Show All"
              className={styles.showAllButton}
              onClick={handleShowAll}
            />

            <Button
              text="Show Selected"
              className={styles.showSelectedButton}
              onClick={handleShowSelected}
              style={{ opacity: itemsSelected ? 1 : 0.5 }} // Conditionally set opacity based on itemsSelected state
            />

            <Button
              text="Export"
              className={styles.exportButton}
              onClick={handleExport}
            />
          </div>
        </div>
      </div>
      <div className={styles.clinicalRoster}>
        <div className={styles.tableContainer}>
          <table className={styles.rosterTable}>
            <thead>
              <tr>
                <th></th>

                <th>
                  <DashboardSortTableHeader
                    title={"Name"}
                    // width={1}
                    onChange={(direction) => handleSort("name", direction)}
                    value={
                      sortCriteria.column === "name" ? sortCriteria.direction : ""
                    }
                  />
                </th>

                <th>
                  <DashboardSortTableHeader
                    title={"Location"}
                    // width={1}
                    onChange={(direction) => handleSort("location", direction)}
                    value={
                      sortCriteria.column === "location"
                        ? sortCriteria.direction
                        : ""
                    }
                  />
                </th>
                <th>
                  <DashboardSortTableHeader
                    title="Start Date"
                    // width={1}
                    onChange={(direction) => handleSort("startDate", direction)}
                    value={
                      sortCriteria.column === "startDate"
                        ? sortCriteria.direction
                        : ""
                    }
                  />
                </th>
                <th>
                  <DashboardSortTableHeader
                    title="End Date"
                    // width={1}
                    onChange={(direction) => handleSort("endDate", direction)}
                    value={
                      sortCriteria.column === "endDate"
                        ? sortCriteria.direction
                        : ""
                    }
                  />
                </th>
                <th>
                  <DashboardSortTableHeader
                    title="School"
                    // width={1}
                    onChange={(direction) => handleSort("school", direction)}
                    value={
                      sortCriteria.column === "school"
                        ? sortCriteria.direction
                        : ""
                    }
                  />
                </th>
                <th>
                  <DashboardSortTableHeader
                    title="Program"
                    // width={1}
                    onChange={(direction) => handleSort("program", direction)}
                    value={
                      sortCriteria.column === "program"
                        ? sortCriteria.direction
                        : ""
                    }
                  />
                </th>
                <th>
                  <DashboardSortTableHeader
                    title="Type"
                    // width={1}
                    onChange={(direction) => handleSort("type", direction)}
                    value={
                      sortCriteria.column === "type" ? sortCriteria.direction : ""
                    }
                  />
                </th>
              </tr>
            </thead>

            <tbody>
              {currentItems.map((item) => (
                <DashboardClinicalRosterItem
                  key={item.id}
                  item={item}
                  selected={selectedItems.includes(item.id)}
                  onSelect={() => handleSelect(item.id)}
                  onClickName={(contact) => setModalContactInfo(contact)}
                />
              ))}
            </tbody>
          </table>
        </div>
        <div className={styles.space} />
        <DashboardTablePagination
          onPageChange={handlePageChange}
          total={totalItems}
          perPage={itemsPerPage}
        />
      </div>
    </div>
  );
};

DashboardClinicalRosterTable.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      location: PropTypes.string.isRequired,
      startDate: PropTypes.string.isRequired,
      endDate: PropTypes.string.isRequired,
      school: PropTypes.string.isRequired,
      program: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default DashboardClinicalRosterTable;
