import { useEffect } from "react";
import { AdminContainer } from "hooks/useAdmin";
import { useLocation } from "react-router-dom";

const ImpersonateUser = () => {
  const { impersonateUser } = AdminContainer.useContainer();
  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);
  const userEmail = searchParams.get("email").replace(" ", "+");
  
  useEffect(() => {
    async function impersonateUserFetch() {
      try {
        await impersonateUser(userEmail);
        window.location.href = "/"
      } catch (e) {}
    }
    if (userEmail) {
      impersonateUserFetch();
    }
  }, [userEmail]);

  return <></>;
};

export default ImpersonateUser;
