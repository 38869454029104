import React, { useEffect, useState } from 'react';
import { Button, HeaderSection } from '@cpnw/ui';
import DashboardDemographicsImage from 'components/DashboardDemographicsImage';
import styles from './DashboardMyDemographics.module.css';
import { CpnwContainer } from '../../../../hooks/useCpnw';
import { AccountContainer } from 'hooks/useAccount';
import classNames from 'classnames';

import {Loading} from "@cpnw/ui";

const DashboardMyDemographics = () => {
	const [imageFile, setImageFile] = useState(null);

	const { myDemoGraphicsData, myDemoGraphicsDataLoading, refetchMyDemographics, submitDemoGraphics } =
		AccountContainer.useContainer();

	useEffect(()=>{
		refetchMyDemographics()
	}, [])

    //Add uploading state to prevent multiple uploads
	const [isUploading, setIsUploading] = useState(false);

	const saveDemographics = async () => {
		if (imageFile && !isUploading) {
            setIsUploading(true);
			const formData = new FormData();
			formData.append('files', imageFile);
			formData.append(
				'demographics',
				JSON.stringify({
					ContactIDPK: myDemoGraphicsData.contactIDPK,
					iRace: myDemoGraphicsData.iRace
				})
			);

			try {
				const result = await submitDemoGraphics(formData);
			} catch (error) {
				console.error('Submit DemoGraphics Photo error:', error);
			} finally {
				//reset image file to avoid reuploading the same image
				setImageFile(null);
				setIsUploading(false);
			}
		}
	};

	const handleImageChange = file => {
		setImageFile(file);
	};

	return (
		<div className={styles.dashboardMyDemographics}>
			<HeaderSection header='My Demographics'>
				My Demographics
			</HeaderSection>
			<p>Providing this information is optional.</p>
			<h3>School ID Photo</h3>
			<div className={styles.containerImage}>
				{myDemoGraphicsDataLoading && (
					<div className={styles.photo_loader}>
						<Loading />
					</div>
				)}
				<DashboardDemographicsImage
					image={myDemoGraphicsData?.photoUrl ?? null}
					changeImageItem={handleImageChange}
					btnDisabled={isUploading}
				/>
			</div>
			<div>

			{isUploading ? (
				<div className={styles.loader}>
					<Loading />
				</div>
			) : (
				<hr />
			)}

			</div>
			<div className={styles.buttonContainer}>
				<Button
					className={classNames(styles.saveButton, !isUploading && styles.hover)}
					text={'SAVE MY DEMOGRAPHICS'}
					onClick={saveDemographics}
					disabled={isUploading}
				/>
			</div>
		</div>
	);
};

export default DashboardMyDemographics;
