import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import styles from "./CheckboxSelect.module.css";
import { TipBox } from "@cpnw/ui";

export default function CheckboxSelect({ placeholder, options, tip, selected, onSelect }){
    //state for hovered tip
    const [tipHovered, setTipHovered] = useState(false);
    //state for dropdown
    const [dropdownActive, setDropdownActive] = useState(false);
    //local state for the options selected (checked)
    const [selectedOptions, setSelectedOptions] = useState(selected || []);
    //ref for dropdown to allow clicking outside to close
    const dropdownRef = useRef(null);

    // Function to handle option change
    const handleOptionChange = (option) => {
        let newSelectedOptions = [];
    
        if (option === "All") {
            if (selectedOptions.length === options.length) {
                // if "All" is clicked and options are already selected, deselect all
                newSelectedOptions = [];
            } else {
                // select all options
                newSelectedOptions = options.slice();
            }
        } else {
            if (selectedOptions.includes(option)) {
                // remove the option
                newSelectedOptions = selectedOptions.filter(item => item !== option);
            } else {
                // append the option to the array
                newSelectedOptions = [...selectedOptions, option];
            }
        }
    
        // set local state
        setSelectedOptions(newSelectedOptions);
        // pass array of selected options back to parent
        onSelect(newSelectedOptions);
    };

    //useEffect for handling clicking outside the dropdown to make it close automatically
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownActive(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    //in the case where the parent containers need to verify if the selected values are within the options array (i.e. matched values)
        //this useEffect updates the state after the component is mounted ensuring the selected values are passed
    useEffect(() => {
        setSelectedOptions(selected || []);
    }, [selected]);

    // To display just the number of options selected
        //if the number of options selected is 0, then display the placeholder
    const selectedCount = selectedOptions.length === 0 
        ? placeholder
        //if the selected options is one, then display that option
        : selectedOptions.length === 1 
            ? selectedOptions[0]
            //otherwise, just display a count of options selected
            : `Options selected: ${selectedOptions.length}`;

    // Determine options to render, including "All" if applicable
    const renderOptions = options.length > 1 ? ['All', ...options] : options;

    return (
        <>
            <div className={styles.select_container} tabIndex={0}>
                <div 
                    className={classNames(styles.select, dropdownActive && styles.select_active)} 
                    onClick={() => setDropdownActive(!dropdownActive)} 
                    onMouseEnter={() => setTipHovered(true)}
                    onMouseLeave={() => setTipHovered(false)}
                >
                    {selectedCount}
                </div>

                {dropdownActive && (
                <div className={styles.dropdown} ref={dropdownRef}>
                    {renderOptions.map((item, index) => (
                        <label key={index} className={styles.option}>
                            <input 
                            type="checkbox" 
                            value={item} 
                            checked={item === "All" ? selectedOptions.length === options.length : selectedOptions.includes(item)}
                            onChange={() => handleOptionChange(item)}
                            className={styles.option_input}
                            />
                            {item}
                        </label>
                    ))}
                </div>
                )}
            </div>
            {tipHovered ? <div className={styles.tip_container}>
                <TipBox tip={tip} className={styles.custom_tip}/> 
            </div>: ""}
        </>
    );
};

CheckboxSelect.propTypes = {
    placeholder: PropTypes.string,
    options: PropTypes.array,
    tip: PropTypes.string,
    selected: PropTypes.array,
    onSelect: PropTypes.func,
}