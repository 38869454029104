import React from "react";
import PropTypes from "prop-types";
import DashboardStepItem from "components/DashboardStepItem";

const DashboardStepsList = ({steps}) => {
    return (
        <div>
            {steps.map((step, index) => {
                return (
                    <DashboardStepItem step={step.step} info={step.info} list={step.list && step.list} link={step.link} key={index}/>
                )
            })}
        </div>
    )
}

export default DashboardStepsList;

DashboardStepsList.propTypes = {
    step: PropTypes.arrayOf(
        PropTypes.shape(
            {
                step: PropTypes.number.isRequired,
                info: PropTypes.string.isRequired,
                list: PropTypes.arrayOf(PropTypes.string)
            }
        )
    )
}