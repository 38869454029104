import { useEffect, useState, useMemo } from 'react';
import UsersTable from 'components/DashboardUsersTable/UsersTable';
import { AdminContainer } from 'hooks/useAdmin';
import {Loading} from "@cpnw/ui";

const ActiveUsers = () => {
  const { adminUsersData, adminUsersDataLoading, refetchAdminUsers, updateUsersActive } = AdminContainer.useContainer();
  const [loading, setLoading] = useState(false)

	const activeUsers = useMemo(() => {
	  return adminUsersData?.Active ?? [];
	}, [adminUsersData]);

	const [users, setUsers] = useState([]);

	const handleUpdateActiveUsers = async (updateUserSelections) => {
		setLoading(true);
	  if(updateUserSelections === null || updateUserSelections === undefined)
	    return;

	  const convertedData = Object.keys(updateUserSelections)
	    .filter((key) => updateUserSelections[key].selectedValue && updateUserSelections[key].selectedValue !== 'clear')
	    .map((key) => ({
	      active: updateUserSelections[key].selectedValue,
	      id: parseInt(key)
	    }));


	  if(convertedData.length === 0){
			setLoading(false);
		  return;
	  }

	  try {
		const usersUpdateData = await updateUsersActive(convertedData);
		setUsers(usersUpdateData?.Active ?? []);
	  } catch (error) {
		console.error('Error updating users:', error);
	  } finally {
		//may not be needed
		// refetchAdminUsers();
		setLoading(false);
	  }
	}

	useEffect(() => {
	  if(activeUsers && activeUsers.length > 0) {
	    setUsers(activeUsers);
	  } else {
	    setUsers([]);
	  }

    // Cleanup function
    return () => {
      setUsers([]);
    };
	}, [activeUsers]);

  return (
	<>
	{!adminUsersDataLoading ? (
		<UsersTable items={users} onUpdate={handleUpdateActiveUsers} isLoading={loading} />
	) : (
		<Loading/>
	)}
	</>
  );
}

export default ActiveUsers;