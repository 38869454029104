import React from 'react';
import PropTypes from "prop-types";

import styles from "./DashboardLearningList.module.css";
import DashboardLearningItem from '../DashboardLearningItem';

export default function DashboardLearningList({items}){

return (
    <div className={styles.learning_list_wrapper}>
        {items.map((item, index) => (
            <div key={index} className={styles.learning_list_item}>
                <DashboardLearningItem header={item.header} description={item.description} src={item.src} onClick={item.onClick}/>
            </div>
        ))}
    </div>
);
};

DashboardLearningList.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
          header: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
          src: PropTypes.string,
          onClick: PropTypes.func
        })
    ).isRequired
}