import { React, useState, useCallback } from "react";
import PropTypes from "prop-types";
import styles from "./DashboardSelect.module.css";

import { TipBox } from "@cpnw/ui";

export default function DashboardSelect({ placeholder, options, tip, selected, onSelect, disabled = false }) {
  const [tipHovered, setTipHovered] = useState(false);
  const [selectActive, setSelectActive] = useState(false);

  const handleSelectChange = useCallback((value) => {
    //passing null as the value of placeholder if placeholder is selected
    onSelect(value === placeholder ? null : value) 
    //
  }, [onSelect, placeholder]);

  return (
    <>
      <div className={styles.select_container}>
        <select
          onChange={(e) => handleSelectChange(e.target.value)}
          //active tipbox
          onMouseEnter={() => setTipHovered(true)}
          onMouseLeave={() => setTipHovered(false)}
          //active select menu
          onFocus={() => setSelectActive(true)}
          onBlur={() => setSelectActive(false)}
          className={selectActive || tipHovered ? styles.select_active : ""}

          //default value of the select will be the item passed as a prop
          defaultValue={selected}
          disabled={disabled}
        >
          <option className={styles.option} value={null}>{placeholder}</option>
          {options.map((item, index) => (

            <option key={index} value={item} className={styles.option}>
              {item}
            </option>
          ))}
        </select>
      </div>
      <div className={styles.tip_container}>
        {tipHovered ? <TipBox tip={tip} className={styles.custom_tip}/> : ""}
      </div>
    </>
  );
}

DashboardSelect.propTypes = {
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  tip: PropTypes.string.isRequired,
  //Adjusted to allow for the value of selected to be null
  selected: PropTypes.string,
  onSelect: PropTypes.func.isRequired
};
