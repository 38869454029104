export const UserRegState = {
    /// <summary> Registration Process email confirm. </summary>
    AwaitingEmailConfirmation: 0,
    /// <summary> Send email to student to that effect. </summary>
    AwaitingBioCompletion: 1,
    /// <summary> User hasn't consented yet. </summary>
    AwaitingConsent: 2,
    /// <summary> Waiting for facility coordinator to approve. </summary>
    PendingApproval: 3,
    /// <summary> Returned for some reason. </summary>
    FixBio: 4,
    /// <summary> Complete rejected - user needs to be deleted. </summary>
    Rejected: 5,
    /// <summary> Registration complete and student is approved. </summary>
    Approved: 6,
    /// <summary> Student has been inactivated. </summary>
    Inactive: 7,
    /// <summary> Waiting for student to pay through PayPal. </summary>
    AwaitingSelfPay: 8,
    Archived: 9
  };

  export const CanPermissions = {
    "CanAssign": "CanAssign",
    "CanReport": "CanReport",
    "CanUpload": "CanUpload",
    "CanFERPA": "CanFERPA",
    "CanHIPAA": "CanHIPAA",
    "CanView": "CanView",
    "CanCoordinate": "CanCoordinate",
    "CanPlace": "CanPlace",
    "CanDownload": "CanDownload",
    "CanDelete": "CanDelete",
    "CanOrgDocs": "CanOrgDocs",
  }

  export const Categories = {
    CERTS: 1,
    LICENSES: 2,
    ELEARNING: 3,
    FORMS: 4,
    IMMUNIZATION: 5,
    INSURANCE: 6,
    SCREENS_CHECKS: 7,
    SITE_ORIENTATIONS: 8,
  };