import moment from "moment";
// import iconPlus from "assets/faIcons/iconPlus";
import iconPlus from "../../assets/faIcons/iconPlus";
import iconHourglass from "../../assets/faIcons/iconHourglass";
import iconMinus from "../../assets/faIcons/iconMinus";

const IconStatusHelper = (status, expirationDate = null) => {
  let date = expirationDate ? moment(expirationDate).format("M/DD/yyyy") : null;

  if (status == "Complete")
    return <span title={date ? `Expire on ${date}` : null}>{iconPlus}</span>;
  if (status == "Expiring")
    return (
      <span title={date ? `Expired on ${date}` : null}>{iconHourglass}</span>
    );
  if (status == "Incomplete")
    return <span title="Incomplete">{iconMinus}</span>;
  return "";
};

export default IconStatusHelper;
