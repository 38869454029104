import { format } from "date-fns";
import { formatInTimeZone, utcToZonedTime } from 'date-fns-tz';

const toCamelCase = (str) => {
  return str.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
};

// Convert a CSS style string to a camelCase style object
export const convertStyleStringToObject = (styleString) => {
  return styleString.split(";").reduce((acc, style) => {
    if (style.trim()) {
      const [key, value] = style.split(":");
      acc[toCamelCase(key.trim())] = value.trim();
    }
    return acc;
  }, {});
};

export const formatUTCMidnightToSameDay = (timestamp, formatString = "MM/dd/yyyy") =>{
  //return format(utcToZonedTime(timestamp, 'UTC'), formatString, { timeZone: 'UTC' })
  return formatInTimeZone(timestamp, "UTC", formatString)
}