import { HeaderSection } from '@cpnw/ui';
import styles from './Users.module.css';
import DashboardPageTabs from 'components/DashboardPageTabs';
import { useEffect, useState } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import ActiveUsers from './ActiveUsers';
import InactiveUsers from './InactiveUsers';
import AccountRequests from './AccountRequests';
import { AdminContainer } from 'hooks/useAdmin';

const UserTabs = {
	ActiveUsers: 'Active Users',
	AccountRequests: 'Account Requests',
	InactiveUsers: 'Inactive Users'
}

const Users = () => {
	const {refetchAdminUsers} = AdminContainer.useContainer()
	const titleList = [UserTabs.ActiveUsers, UserTabs.AccountRequests, UserTabs.InactiveUsers];
  	const [activeTab, setActiveTab] = useState(UserTabs.ActiveUsers);

	const handleTabChange = tab => {
		setActiveTab(tab);
	};

	useEffect(()=>{
		refetchAdminUsers()
	}, [])

	useEffect(() => {
		refetchAdminUsers();
	}, [activeTab])

	return (
			<section>
				<HeaderSection header={'Users'} />
				<div className={styles.pageTabContaner}>
					<DashboardPageTabs
						titleList={titleList}
						activeTitle={activeTab}
						onChange={handleTabChange}
					/>
				</div>

				<div className={styles.tableContainer}>
					{activeTab == UserTabs.ActiveUsers && (
						<ActiveUsers/>
					)}
					{activeTab == UserTabs.AccountRequests && (
						<AccountRequests/>
					)}
					{activeTab == UserTabs.InactiveUsers && (
						<InactiveUsers/>
					)}
				</div>
			</section>
	);
};

export default Users;
