import React, { useState, useEffect } from 'react';
import placeholderImage from '../../assets/profile-image.png';
import PropTypes from 'prop-types';
import styles from "./PassportStudentPhoto.module.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from "@fortawesome/free-solid-svg-icons";

const PassportStudentPhoto = ({ image, downloadFileName }) => {
    const [imagePreview, setImagePreview] = useState(image || placeholderImage);

    useEffect(() => {
        if (image) {
            setImagePreview(image);
        }
    }, [image]);


    return (
        <div className={styles.container}>
            <h3 className={styles.heading}>School ID Photo</h3>
            <div className={styles.image_container}>

                {imagePreview && <img src={imagePreview} alt="Preview" style={{ marginTop: '10px', marginBottom: '15px', width: '100%', maxWidth: '100%' }} />}
                <a href={imagePreview} download={(downloadFileName != undefined && downloadFileName.length > 0) ? downloadFileName : "School ID Photo"}>
                    <FontAwesomeIcon icon={faDownload} style={{ backgroundColor: "transparent", color: "#007e8d", padding: "1px", marginRight: "5px", fontSize: "12px" }} />
                    Download Image
                </a>
            </div>
        </div>
    );
};

PassportStudentPhoto.propTypes = {
    image: PropTypes.string,
    downloadFileName: PropTypes.string
};

export default PassportStudentPhoto;



