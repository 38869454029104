import React from "react";
import PropTypes from "prop-types";
import styles from "./PassportReviewItem.module.css";

export default function PassportReviewItem({ label, description }) {
  return (
    <>
      <div>
        <p className={styles.label}>{label}</p>
        <div className={styles.unset} dangerouslySetInnerHTML={{__html: description}}></div>
      </div>
    </>
  );
}

PassportReviewItem.propTypes = {
  label: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};
