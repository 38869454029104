import React, { useCallback, useRef, useState } from "react";
import PropTypes from "prop-types";
import styles from "./InsuranceView.module.css";

import { AddButton, InputBox, CheckboxItem } from "@cpnw/ui";
import DashboardRadio from "components/DashboardRadio";
import FormDatePicker from "components/FormDatePicker";

const SubmissionTypes = {
  A: "A",
  B: "B",
  C: "C",
  D: "D",
  Military: "Military",
};

const SubmissionTypeDescriptions = {
  A: "Insurance verified by program",
  B: "Individual Insurance",
  Military: "Military immunization",
};

export default function InsuranceView({
  data = {},
  onChange,
  isReviewing = false,
  disabled = false
}) {
  const setValue = (key, value) => {
    if (key) {
      data[key] = value;
    }

    if (onChange) {
      onChange({ ...data });
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.testRow}>
          <DashboardRadio
            isSelected={data.type === SubmissionTypes.A}
            onChange={(value) => {
              setValue("type", value);
            }}
            value={SubmissionTypes.A}
            label={SubmissionTypeDescriptions.A}
            containerClassName={styles.radioContainer}
            disabled={disabled}
          />
        </div>
        <div className={styles.testRow}>
          <DashboardRadio
            isSelected={data.type === SubmissionTypes.B}
            onChange={(value) => {
              setValue("type", value);
            }}
            value={SubmissionTypes.B}
            label={SubmissionTypeDescriptions.B}
            containerClassName={styles.radioContainer}
            disabled={disabled}
          />
        </div>
        <div className={styles.testRow}>
          <label>Expiration Date:</label>
          <div className={styles.testItem2}>
            <FormDatePicker
              dateFormat="MM/dd/yyyy"
              dateString={data.expirationDate}
              setDate={(value) => {
                data.expirationDate = value;
                setValue();
              }}
            disabled={disabled}
            />
          </div>
        </div>
      </div>
    </>
  );
}
