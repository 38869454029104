import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { format, parseISO } from "date-fns";
import styles from "./DashboardOIGSAMAdminItem.module.css";
import { CheckboxItem } from "@cpnw/ui";

export const DashboardOIGSAMAdminItem = ({ selected, onSelect, report, backgroundClassName, isEvenRow }) => {
  const [data, setData] = useState(report);
  const [isChecked, setChecked] = useState(selected);

  function formatDate(dateString) {
    // Check if item.startDate is not null or undefined
    if (dateString) {
      const utcDateString = dateString;

      // Convert the UTC date to a Date object
      const date = new Date(utcDateString);

      // Extract components
      const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      const day = String(date.getUTCDate()).padStart(2, '0');
      const year = date.getUTCFullYear();

      // Format to "MM-dd-yyyy"
      const formattedDate = `${month}-${day}-${year}`;
      return formattedDate;
    }
  }

  const handleSelect = (data, isChecked) => {
    setChecked(isChecked);
    onSelect(data, isChecked);
  }

  useEffect(() => {
    setData(report);
  }, [report])

  return (
    <div className={classNames(styles.rowData, styles.row, isEvenRow && styles.evenRow, isChecked && styles.selectedRow, !isChecked && isEvenRow && styles.evenRow, !isChecked && backgroundClassName)}>
      <div className={classNames(styles.columnCheckBox)}>
        <CheckboxItem onClick={(isChecked) => handleSelect(data, isChecked)} checkedIcon={styles.customCheckedIcon} uncheckedIcon={styles.customUncheckedIcon}
          isChecked={isChecked}
          className={isChecked ? styles.selectedIcon : styles.unSelectIcon} /></div>
      <div className={classNames(styles.columnIndex0, styles.content)}>{formatDate(data.scanDate)}</div>
      <div className={classNames(styles.columnIndex1, styles.content)}>{data.nameInput}</div>
      <div className={classNames(styles.columnIndex2, styles.content)}>{data.program}</div>
      <div className={classNames(styles.columnIndex3, styles.content)}>{data.sidEid}</div>
      <div className={classNames(styles.columnIndex4, styles.content)}>{data.agency}</div>
      <div className={classNames(styles.columnIndex5, styles.content)}>{data.scanResult}</div>
      <div className={classNames(styles.columnIndex6, styles.content)}>{data.message}</div>
      <div className={classNames(styles.columnIndex7, styles.content)}>{data.userType}</div>
    </div>
  );
};

DashboardOIGSAMAdminItem.propTypes = {
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
  report: PropTypes.object,
  backgroundClassName: PropTypes.string,
}

export default DashboardOIGSAMAdminItem;


