import React from "react";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./DashboardSearch.module.css";

export default function DashboardSearch({ onInput, searchDelay, minChar }) {
    //This component is a lot like the shared-ui search component, except with different styles and no placeholder.

  const [search, setSearch] = useState("");

  //called when search state is changed
    //allowing this to be a on keystroke function
  useEffect(() => {
    //Onlyfire the search if the number of characters is greater than minChar
    if (search.length < minChar) {
        onInput("");
        return;
    }
    //If search delay is provided
    if (searchDelay) {
        const timeOutId = setTimeout(() => onInput(search), searchDelay);
        return () => clearTimeout(timeOutId);
    } else {
        //If seach delay is not provided
        onInput(search);
    }
  }, [search]);

  return (
    <div className={styles.dash_search_row}>
      <label htmlFor="dash-search" className={styles.dash_search_label}>
        Search:
      </label>
      <input
        type="text"
        id="dash-search"
        className={styles.dash_search_input}
        onChange={(e) => setSearch(e.target.value)}
      />
    </div>
  );
}

DashboardSearch.propTypes = {
  onInput: PropTypes.func,
  searchDelay: PropTypes.number,
  minChar: PropTypes.number,
};
