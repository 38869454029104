import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faPenToSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import styles from "./DashboardPassportFileEditorItem.module.css";

const DashboardPassportFileEditorItem = ({
  name,
  onView,
  onDelete,
  canView = true,
  canDelete = true
}) => {
  return (
    <div className="container">
      {canView && (<button className={styles.button} onClick={onView}>
        <FontAwesomeIcon icon={faEye} />
      </button>)}
      {canDelete && (<button className={styles.button} onClick={onDelete}>
        <FontAwesomeIcon icon={faTrash} />
      </button>)}
      {name}
    </div>
  );
};

DashboardPassportFileEditorItem.propTypes = {
  name: PropTypes.string,
  onView: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default DashboardPassportFileEditorItem;
