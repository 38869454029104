import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import * as XLSX from 'xlsx';
import styles from "./DashboardAssignmentTable.module.css";
import { Button, HeaderSection, Modal, SelectBox } from "@cpnw/ui";
import { DashboardItemsPerPage } from "components/DashboardItemsPerPage";
import DashboardSearch from "components/DashboardSearch";
import DashboardTablePagination from "components/DashboardTablePagination";
import DashboardSortTableHeader from "components/DashboardSortTableHeader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideo } from '@fortawesome/free-solid-svg-icons';

import { DashboardGroupAssigningModal } from "components/DashboardGroupAssigningModal";
import { DashboardAssignmentItem } from "components/DashboardAssignmentItem";
import Preloader from "../../images/preloader.gif";

export const DashboardAssignmentTable = ({ 
    assignments, 
    setAssignments, 
    onAddAssignment,
    onEditAssignment,
    onDeleteAssignment,
    selectOptions,
    refetchAssignments
}) => {
    const TITLE_COLUMN_NAME = 'Name';
    const TITLE_COLUMN_SIDEID = 'SID-EID';
    const TITLE_COLUMN_USER_TYPE = 'UserType';
    const TITLE_COLUMN_PROGRAM = 'Program';
    const TITLE_COLUMN_LOCATION = 'Location';
    const TITLE_COLUMN_START_DATE = 'Start Date';
    const TITLE_COLUMN_END_DATE = 'End Date';
    const TITLE_COLUMN_ADDEDIT = 'Add/Edit';

    const TITLE_COLUMN_EMAIL = 'Email';
    const TITLE_COLUMN_PHONE = 'Phone';
    const TITLE_COLUMN_ADDRESS = 'Address';
    const TITLE_COLUMN_EMERGENCY_CONTACT = 'Emergency Contact';
    const TITLE_COLUMN_EMERGENCY_CONTACT_PHONE = 'Emergency Contact Phone';
    const TITLE_COLUMN_SCHOOL = 'School';

    // Add _isEditing, _isShowAll, _isChecked, _isUnCheckedInSelecteMode properties to each assignment
    const [rows, setRows] = useState(assignments.map(assignment => ({
        ...assignment,
        _isEditing: false,
        _relatedTo: null,
        _isChecked: false,
        _isUnCheckedInSelecteMode: false
    })));

    const [filterName, setFilterName] = useState('');
    const [filterUserType, setFilterUserType] = useState('');
    const [filterProgram, setFilterProgram] = useState('');
    const [filterLocation, setFilterLocation] = useState('');
    const [filterStartDate, setFilterStartDate] = useState('');

    const [disableShowSelectedButton, setDisableShowSelectedButton] = useState(true);
    const [disableAssignGroupButton, setDisableAssignGroupButton] = useState(true);
    const [disableEditGroupButton, setDisableEditGroupButton] = useState(false);

    const [showSelectedOnly, setShowSelectedOnly] = useState(false);

    const [isOpenVideoTutorialModal, setOpenVideoTutorialModal] = useState(false);
    const [videoUrl, setVideoUrl] = useState(undefined);
    const [isOpenGroupAssigningModal, setOpenGroupAssigningModal] = useState(false);
    const [isOpenEditGroupModal, setOpenEditGroupModal] = useState(false);

    const [sortingDirection, setSortingDirection] = useState('asc');
    const [sortingColumn, setSortingColumn] = useState(TITLE_COLUMN_NAME);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(assignments.length);
    const [limit, setLimit] = useState(10); // Default limit
    const [showPreloaderList, setShowPreloaderList] = useState([]);

    const limitOptions = [10, 25, 50, 100]; // Options for items per page

    const scrollContainers = useRef([]);

    const [loadingAssignGroup, setLoadingAssignGroup] = useState(false); // Loading Assign Group state to avoid spamming call api

    useEffect(() => {
        let updateAssignments = onFilter(filterName, filterUserType, filterProgram, filterLocation, filterStartDate);

        // Apply sorting to the filtered data
        updateAssignments = updateAssignments.sort((a, b) => {
            const replaceNullValue = (value) => (value ? value : "");

            switch (sortingDirection) {
                case 'asc':
                    switch (sortingColumn) {
                        case TITLE_COLUMN_NAME:
                            return replaceNullValue(a.name).localeCompare(replaceNullValue(b.name));
                        case TITLE_COLUMN_SIDEID:
                            return replaceNullValue(a.sIDEID).localeCompare(replaceNullValue(b.sIDEID));
                        case TITLE_COLUMN_USER_TYPE:
                            return replaceNullValue(a.userType).localeCompare(replaceNullValue(b.userType));
                        case TITLE_COLUMN_PROGRAM:
                            return replaceNullValue(a.program).localeCompare(replaceNullValue(b.program));
                        case TITLE_COLUMN_LOCATION:
                            return replaceNullValue(a.location).localeCompare(replaceNullValue(b.location));
                        case TITLE_COLUMN_START_DATE:
                            return replaceNullValue(a.startDate).localeCompare(replaceNullValue(b.startDate));
                        case TITLE_COLUMN_END_DATE:
                            return replaceNullValue(a.endDate).localeCompare(replaceNullValue(b.endDate));
                        case TITLE_COLUMN_ADDEDIT:
                            return (replaceNullValue(a.location) + replaceNullValue(a.startDate) + replaceNullValue(a.endDate)).localeCompare(replaceNullValue(b.location) + replaceNullValue(b.startDate) + replaceNullValue(b.endDate));
                        default:
                            return replaceNullValue(a.name).localeCompare(replaceNullValue(b.name));
                    }
                case 'desc':
                    switch (sortingColumn) {
                        case TITLE_COLUMN_NAME:
                            return replaceNullValue(b.name).localeCompare(replaceNullValue(a.name));
                        case TITLE_COLUMN_SIDEID:
                            return replaceNullValue(b.sIDEID).localeCompare(replaceNullValue(a.sIDEID));
                        case TITLE_COLUMN_USER_TYPE:
                            return replaceNullValue(b.userType).localeCompare(replaceNullValue(a.userType));
                        case TITLE_COLUMN_PROGRAM:
                            return replaceNullValue(b.program).localeCompare(replaceNullValue(a.program));
                        case TITLE_COLUMN_LOCATION:
                            return replaceNullValue(b.location).localeCompare(replaceNullValue(a.location));
                        case TITLE_COLUMN_START_DATE:
                            return replaceNullValue(b.startDate).localeCompare(replaceNullValue(a.startDate));
                        case TITLE_COLUMN_END_DATE:
                            return replaceNullValue(b.endDate).localeCompare(replaceNullValue(a.endDate));
                        case TITLE_COLUMN_ADDEDIT:
                            return (replaceNullValue(b.location) + replaceNullValue(b.startDate) + replaceNullValue(b.endDate)).localeCompare(replaceNullValue(a.location) + replaceNullValue(a.startDate) + replaceNullValue(a.endDate));
                        default:
                            return replaceNullValue(b.name).localeCompare(replaceNullValue(a.name));
                    }
                default:
                    return 0;
            }
        });

        if (showSelectedOnly) {
            updateAssignments = updateAssignments.filter(assignment => assignment._isChecked === true || assignment._isUnCheckedInSelecteMode === true);
        }

        const seletedAssignments = updateAssignments.filter(assignment => assignment._isChecked === true);
        // Check ShowSelectedButton disabled state
        const hasSelectedRows = seletedAssignments.length > 0;
        setDisableShowSelectedButton(!hasSelectedRows);

        // Check AssignGroupButton disabled state
        const hasMultipleSelectedRows = seletedAssignments.length > 1;
        setDisableAssignGroupButton(!hasMultipleSelectedRows);


        // Check EditGroupButton disabled state
        // Check if all assignments in selectedAssignments have the same location, startDate, and endDate
        const hasSameLocation = seletedAssignments.every(assignment => assignment.location === seletedAssignments[0].location);
        const hasSameStartDate = seletedAssignments.every(assignment => assignment.startDate === seletedAssignments[0].startDate);
        const hasSameEndDate = seletedAssignments.every(assignment => assignment.endDate === seletedAssignments[0].endDate);
        // Check if all three conditions are true
        const isEditGroupEnabled = ((seletedAssignments.length > 1) && hasSameLocation && hasSameStartDate && hasSameEndDate);
        setDisableEditGroupButton(!isEditGroupEnabled);

        setTotalItems(updateAssignments.length)

        const startIndex = (currentPage - 1) * limit;
        const endIndex = Math.min(startIndex + limit - 1, updateAssignments.length - 1);
        setRows(updateAssignments.slice(startIndex, endIndex + 1));

    }, [assignments, showSelectedOnly, filterName, filterUserType, filterProgram, filterLocation, filterStartDate, sortingColumn, sortingDirection, currentPage, limit]);

    const VideoPlayerButton = ({ label, videoUrl }) => {
        return <>
            <div>{label && <strong>{label}: </strong>}<a className={styles.videoPopup} onClick={() => onOpenVideoTutorialModal(true, videoUrl)}>
                <FontAwesomeIcon icon={faVideo} size="lg" /></a>
            </div>
        </>
    };

    const VideoTutorialModal = ({ isOpen, videoUrl }) => {
        const closeModal = () => {
            onCloseVideoTutorialModal()
        };

        return (
            <div>
                <Modal isOpen={isOpen} onRequestClose={closeModal} className={styles.customModal} children={<iframe
                    width="100%"
                    height="100%"
                    src={videoUrl}
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                ></iframe>} />
            </div>
        );
    };
    const onCloseVideoTutorialModal = () => {
        setOpenVideoTutorialModal(false);
        setVideoUrl(undefined);
    }
    const onOpenVideoTutorialModal = (isOpen, videoUrl) => {
        setOpenVideoTutorialModal(isOpen);
        setVideoUrl(videoUrl);
    };

    const onCloseGroupAssigningModal = () => {
        setOpenGroupAssigningModal(false);
    }

    const onOpenGroupAssigningModal = (isOpen) => {
        setOpenGroupAssigningModal(isOpen);
    };

    const handleGroupAssigning = async (group, editAssignmentCallback, addAssignmentCallBack) => {
        
        if (loadingAssignGroup) {
            console.log('Assignment in progress...');
            return;
        }
    
        setLoadingAssignGroup(true);
        const selectedAssignments = rows.filter(assignment => assignment._isChecked === true);

        setShowPreloaderList(selectedAssignments.map(a => a.id))


        // Filter rows which is already an assignment (aid > 0)
        const updateAssignments = selectedAssignments.filter(assignment => assignment.aid > 0);

        
        // Filter rows which is just contact for add new assignment
        const newAssignmets = selectedAssignments.filter(assignment => assignment.aid == null || assignment.aid === undefined);

        try {
            // Map assignments to promises
            const updatePromises = updateAssignments.map(async (assignment) => {
                assignment.hid = parseInt(group.location);
                assignment.startDate = group.startDate;
                assignment.endDate = group.endDate;
                return editAssignmentCallback(assignment, true);
            });
            // let lastIndex = 0
            // const newAssignmentRow = updateAssignments.map(assignment => {
            //     const currentRowIndex = assignments.findIndex(a => a.id === assignment.id)
            //     if (currentRowIndex !== -1) {
            //         //get list tmp_aid from split id from assignments and filter NaN
            //         const tmp_aids = assignments.map(row => row.id.split('__')[1])?.map(aid => parseInt(aid))?.filter(a => !isNaN(a));
            //         const minAid = Math.min(...tmp_aids);
            //         const newAid = minAid < 0 ? minAid - 1 : -1;
            //         const newRow = {
            //             id: `${assignment.cid}__${newAid}`,
            //             cid: assignment.cid,
            //             aid: newAid,
            //             name: assignment.name,
            //             sIDEID: assignment.sIDEID,
            //             userType: assignment.userType,
            //             program: assignment.program,
            //             //edbuid: assignment.edbuid,
            //             location: assignment.location,
            //             school: assignment.school,
            //             hid: assignment.hid,
            //             startDate: '',
            //             endDate: '',
            //             _isEditing: true,
            //             _relatedTo: assignment.id, //Add _relatedTo to check the row created base on another row
            //             //_isChecked: currentRow._isChecked,
            //             //_isUnCheckedInSelecteMode: currentRow._isUnCheckedInSelecteMode
            //         }
            //         lastIndex = currentRowIndex
            //         return newRow
            //     }
            //     return null
            // })

            // const updatedRows = assignments.splice(lastIndex + 1, newAssignmentRow.filter(a => a !== null))
            // setAssignments(updatedRows);
            // setRows(updatedRows);

            const newAssignmentPromises = newAssignmets.map(async (assignment) => { 
                assignment.location = selectOptions.find(a => a.key === group.location)?.value;
                assignment.hid = parseInt(group.location);
                assignment.startDate = group.startDate;
                assignment.endDate = group.endDate;
                return addAssignmentCallBack(assignment);
            });
            

            // Wait for all promises to resolve
            await Promise.all(updatePromises, newAssignmentPromises).then(async () => {
                
                await refetchAssignments()
                setShowPreloaderList([])
            });


        } catch (error) {
            console.error('Error assigning group:', error);
        } finally {
            setLoadingAssignGroup(false);
            setOpenGroupAssigningModal(false);
            setOpenEditGroupModal(false);
        }
    }

    const onGroupAssigning = async (group) => {
        await handleGroupAssigning(group, onEditAssignment, onAddAssignment);  
    }

    const onCloseEditGroupModal = () => {
        setOpenEditGroupModal(false);
    }

    const onOpenEditGroupModal = (isOpen) => {
        setOpenEditGroupModal(isOpen);
    };

    const onEditGroup = async (group) => {
        await handleGroupAssigning(group, onEditAssignment, onAddAssignment);     
    }

    const getEditGroupData = () => {
        const locationSelected = rows.filter(assignment => assignment._isChecked === true).map(row => row.hid?.toString());
        const startDateSelected = rows.filter(assignment => assignment._isChecked === true).map(row => row.startDate);
        const endDateSelected = rows.filter(assignment => assignment._isChecked === true).map(row => row.endDate);
        const groupData = {
            location: (locationSelected.every(location => location === locationSelected[0]) ? locationSelected[0] : ''),
            startDate: (startDateSelected.every(location => location === startDateSelected[0]) ? startDateSelected[0] : ''),
            endDate: (endDateSelected.every(location => location === endDateSelected[0]) ? endDateSelected[0] : ''),
        }
        return groupData;
    }

    const onDeleteGroup = async () => {
        if (loadingAssignGroup) {
            console.log('Assignment in progress...');
            return;
        }

        setLoadingAssignGroup(true);
        //Get seleted assignments and filter row which is just contact (aid = null), not assigments
        const selectedAssignments = rows.filter(assignment => assignment._isChecked === true && assignment.aid > 0);

        try {
            const promises = selectedAssignments.map(async (assignment) => {
                return onDeleteAssignment(assignment);
            });

            await Promise.all(promises);
        } catch (error) {
            console.error('Error assigning group:', error);
        } finally {
            setLoadingAssignGroup(false);
            setOpenGroupAssigningModal(false);
            setOpenEditGroupModal(false);
        }
    }

    /** Function to get location options from assignments */
    const locationOptions = () => {
        return {
            options: selectOptions,
            defaultValue: selectOptions.length > 0 ? selectOptions[0] : null // Return default location if no locations found
        };

    };


    const onLimitChange = (newLimit) => {
        setLimit(newLimit);
        // Reset current page to 1 when the limit changes
        setCurrentPage(1)
    };

    const onDelete = async (removeAssignment) => {
        const aid = removeAssignment.aid;
        if(aid > 0)
        {
            await onDeleteAssignment(removeAssignment);
        }
        else
        {
            const updatedAssignments = assignments.filter(assignment => assignment.id !== removeAssignment.id);
            setAssignments(updatedAssignments);
        }
    }

    const onDuplicate = (assignment) => {
        const aid  = assignment.aid;
        const cid = assignment.cid;
        const currentRow = assignments.find(row => row.id === assignment.id);
        if (currentRow && (currentRow.location && currentRow.startDate && currentRow.endDate)) {
            const currentRowIndex = assignments.findIndex(row => row.id === assignment.id);
            if (currentRowIndex !== -1) {
                //get list tmp_aid from split id from assignments and filter NaN
                const tmp_aids = assignments.map(row => row.id.split('__')[1])?.map(aid => parseInt(aid))?.filter(a => !isNaN(a));
                const minAid = Math.min(...tmp_aids);
                const newAid = minAid < 0 ? minAid - 1 : -1;

                const newRow = {
                    id: `${cid}__${newAid}`,
                    cid: cid,
                    aid: newAid,
                    name: currentRow.name,
                    sIDEID: currentRow.sIDEID,
                    userType: currentRow.userType,
                    program: currentRow.program,
                    //edbuid: currentRow.edbuid,
                    location: currentRow.location,
                    school: currentRow.school,
                    hid: currentRow.hid,
                    startDate: '',
                    endDate: '',
                    _isEditing: true,
                    _relatedTo: currentRow.id, //Add _relatedTo to check the row created base on another row
                    //_isChecked: currentRow._isChecked,
                    //_isUnCheckedInSelecteMode: currentRow._isUnCheckedInSelecteMode
                }
                const updatedRows = [
                    ...assignments.slice(0, currentRowIndex + 1), // Insert before current row
                    newRow,
                    ...assignments.slice(currentRowIndex + 1)
                ];

                setAssignments(updatedRows);
                setRows(updatedRows);
            }
        } else {
            currentRow._isEditing = true;
        }
    }

    /** Function to handle saving an assignment */
    const onSave = async (saveAssignment) => {
        //const [cid, aid] = saveAssignment.id.split('__').map(part => parseInt(part));
        const aid = saveAssignment.aid;
        if(aid > 0)
        {
            await onEditAssignment(saveAssignment);
        } else {
            await onAddAssignment(saveAssignment);
        }
    };


    /** Function to filter assignments based on name, userType, program, location, startDate */
    const onFilter = (name, userType, program, location, startDate) => {
        return assignments.filter(assignment => {
            // Convert filter values and assignment properties to lowercase for case-insensitive comparison
            const assignmentName = assignment.name?.toLowerCase();
            const assignmentUserType = assignment.userType?.toLowerCase();
            const assignmentProgram = assignment.program?.toLowerCase();
            const assignmentLocation = assignment.location?.toLowerCase();
            const assignmentStartDate = assignment.startDate?.toLowerCase();

            // Check if name matches
            if (name && !assignmentName.includes(name.toLowerCase())) {
                return false;
            }
            // Check if userType matches
            if (userType && !assignmentUserType.includes(userType.toLowerCase())) {
                return false;
            }
            // Check if program matches
            if (program && !assignmentProgram.includes(program.toLowerCase())) {
                return false;
            }
            // Check if startDate matches
            if (startDate && !assignmentStartDate.includes(startDate.toLowerCase())) {
                return false;
            }
            // Check if location matches
            if (location && assignmentLocation !== location.toLowerCase()) {
                return false;
            }
            // If all conditions pass, return true to include the assignment
            return true;
        });
    };

    const onSortChange = (columnName, direction) => {
        // Update sorting direction state for the respective column
        setSortingColumn(columnName);
        setSortingDirection(direction);
    };

    function onSelect(data, isChecked) {
        const updatedAssignments = assignments.map(assignment => 
            assignment.id === data.id ? { ...assignment, _isChecked: isChecked } : assignment
        );
        setAssignments(updatedAssignments);
    }

    // show only the selected items when the "Show Selected" button is clicked
    const onShowSelected = () => {
        setShowSelectedOnly(true);
        setCurrentPage(1);
    };

    // show all items when the "Show All" button is clicked
    const onShowAll = () => {
        setShowSelectedOnly(false);
        setCurrentPage(1);
    };

    const onExportToExcel = () => {
        const headers = [
            TITLE_COLUMN_EMAIL,
            TITLE_COLUMN_PHONE,
            TITLE_COLUMN_ADDRESS,
            TITLE_COLUMN_EMERGENCY_CONTACT,
            TITLE_COLUMN_EMERGENCY_CONTACT_PHONE,
            TITLE_COLUMN_SCHOOL,
            TITLE_COLUMN_NAME,
            TITLE_COLUMN_SIDEID,
            TITLE_COLUMN_USER_TYPE,
            TITLE_COLUMN_PROGRAM,
            TITLE_COLUMN_LOCATION,
            TITLE_COLUMN_START_DATE,
            TITLE_COLUMN_END_DATE
        ];

        const wb = XLSX.utils.book_new();
        const wsData = [headers, ...(showSelectedOnly ? assignments.filter(assignment => assignment._isChecked === true) : assignments)
            .slice(0).map(item => [
                item.email,
                item.phone,
                item.address,
                item.emergencyContact,
                item.emergencyContactPhone,
                item.school,
                item.name,
                item.sIDEID,
                item.userType,
                item.program,
                item.location,
                item.startDate,
                item.endDate])];
        const ws = XLSX.utils.aoa_to_sheet(wsData);

        // Adjust column widths
        const columnWidths = [
            { wpx: 150 }, // Email column width
            { wpx: 100 }, // Phone column width
            { wpx: 200 }, // Address column width
            { wpx: 150 }, // Emergency Contact column width
            { wpx: 150 }, // Emergency Contact Phone column width
            { wpx: 150 }, // School column width
            { wpx: 150 }, // Name column width
            { wpx: 100 }, // SID-EID column width
            { wpx: 100 }, // UserType column width
            { wpx: 150 }, // Program column width
            { wpx: 150 }, // Location column width
            { wpx: 100 }, // Start Date column width
            { wpx: 100 }  // End Date column width
        ];
        ws['!cols'] = columnWidths;

        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, 'Assignments - CPNW.xlsx');
    }

    const onPageChange = (e) => {
        setCurrentPage(e);
    }

    const handleScroll = (index) => {
        const scrollLeft = scrollContainers.current[index].scrollLeft;
        scrollContainers.current.forEach((container, i) => {
            if (i !== index) {
                container.scrollLeft = scrollLeft;
            }
        });
    };
    return (
        <div className={styles.dashboardAssignmentTable}>
            <div className={classNames(styles.row)}>
                <div className={classNames(styles.column)}>
                    <h5 className={styles.assignmentTutorialsTitle}>Assignment Tutorials:</h5>
                </div>
            </div>
            <div className={classNames(styles.assignmentTutorials, styles.row)}>
                <div className={classNames(styles.column)}>
                    <VideoPlayerButton label={'Adding'} videoUrl={"https://cpnw.blob.core.windows.net/documents/CoordinatorTutorials/Instructions/addingAssignment.mp4"} />
                </div>
                <span>|</span>
                <div className={classNames(styles.column)}>
                    <VideoPlayerButton label={'Editing'} videoUrl={"https://cpnw.blob.core.windows.net/documents/CoordinatorTutorials/Instructions/editingAssignment.mp4"} />
                </div>
                <span>|</span>
                <div className={classNames(styles.column)}>
                    <VideoPlayerButton label={'Deleting'} videoUrl={"https://cpnw.blob.core.windows.net/documents/CoordinatorTutorials/Instructions/deletingAssignment.mp4"} />
                </div>
                <span>|</span>
                <div className={classNames(styles.column)}>
                    <a href="https://cpnw.blob.core.windows.net/documents/CoordinatorTutorials/Instructions/CoordinatorChecklist.pdf" target="_blank">Coordinator Checklist (PDF)</a>
                </div>
                <span>|</span>
                <div className={classNames(styles.column)}>
                    <a href="https://cpnw.blob.core.windows.net/documents/CoordinatorTutorials/Instructions/BatchAssignmentInstructions.pdf" target="_blank">Batch Assigning (PDF) </a>
                </div>
            </div>
            <div className={classNames(styles.assignmentActionsRow, styles.row)} >
                <div className={classNames(styles.column, styles.itemsPerPage)}>
                    <DashboardItemsPerPage disabled={false} limit={limit} limitOptions={[10, 25, 50, 100]} onLimitChange={onLimitChange} />
                </div>
                <div className={classNames(styles.column)}>
                    <Button onClick={() => onShowAll()} text="Show All" disabled={false} />
                </div>
                <div className={classNames(styles.column)}>
                    <Button onClick={() => onShowSelected()} text="Show Selected" disabled={disableShowSelectedButton} />
                </div>
                <div className={classNames(styles.column)}>
                    <Button onClick={() => onExportToExcel()} text="Export" disabled={false} />
                </div>
                <div className={classNames(styles.column)}>
                    <Button onClick={() => onOpenGroupAssigningModal(true)} text="Assign Group" disabled={disableAssignGroupButton} />
                </div>
                <div className={classNames(styles.column)}>
                    <Button onClick={() => onOpenEditGroupModal(true)} text="Edit Group" disabled={disableEditGroupButton} />
                </div>
                <div className={classNames(styles.dashboardSearch, styles.column)}>
                    <DashboardSearch onInput={(input) => {
                        setFilterName(input);
                        setCurrentPage(1);
                    }} searchDelay={0} minChar={0} />
                </div>
            </div>
            <div className={styles.container}>
                <div className={classNames(styles.scrollContainer, styles.assignmentHeader)} style={{ overflow: 'hidden' }} ref={(el) => (scrollContainers.current[0] = el)}
                    onScroll={() => handleScroll(0)}>
                    <div className={classNames(styles.columnCheckBox, styles.content)}>&nbsp;&nbsp;</div>
                    <div className={classNames(styles.columnIndex0, styles.content)}><DashboardSortTableHeader title={TITLE_COLUMN_NAME} value={(sortingColumn == TITLE_COLUMN_NAME) ? sortingDirection : ""} onChange={(direction) => onSortChange(TITLE_COLUMN_NAME, direction)} className={classNames((sortingDirection === "asc" ? styles.active : styles.inactive))} /></div>
                    <div className={classNames(styles.columnIndex1, styles.content)}><DashboardSortTableHeader title={TITLE_COLUMN_SIDEID} value={(sortingColumn == TITLE_COLUMN_SIDEID) ? sortingDirection : ""} onChange={(direction) => onSortChange(TITLE_COLUMN_SIDEID, direction)} className={classNames((sortingDirection === "asc" ? styles.active : styles.inactive))} /></div>
                    <div className={classNames(styles.columnIndex2, styles.content)}><DashboardSortTableHeader title={TITLE_COLUMN_USER_TYPE} value={(sortingColumn == TITLE_COLUMN_USER_TYPE) ? sortingDirection : ""} onChange={(direction) => onSortChange(TITLE_COLUMN_USER_TYPE, direction)} className={classNames((sortingDirection === "asc" ? styles.active : styles.inactive))} /></div>
                    <div className={classNames(styles.columnIndex3, styles.content)}><DashboardSortTableHeader title={TITLE_COLUMN_PROGRAM} value={(sortingColumn == TITLE_COLUMN_PROGRAM) ? sortingDirection : ""} onChange={(direction) => onSortChange(TITLE_COLUMN_PROGRAM, direction)} className={classNames((sortingDirection === "asc" ? styles.active : styles.inactive))} /></div>
                    <div className={classNames(styles.columnIndex4, styles.content)}><DashboardSortTableHeader title={TITLE_COLUMN_LOCATION} value={(sortingColumn == TITLE_COLUMN_LOCATION) ? sortingDirection : ""} onChange={(direction) => onSortChange(TITLE_COLUMN_LOCATION, direction)} className={classNames((sortingDirection === "asc" ? styles.active : styles.inactive))} /></div>
                    <div className={classNames(styles.columnIndex5, styles.content)}><DashboardSortTableHeader title={TITLE_COLUMN_START_DATE} value={(sortingColumn == TITLE_COLUMN_START_DATE) ? sortingDirection : ""} onChange={(direction) => onSortChange(TITLE_COLUMN_START_DATE, direction)} className={classNames((sortingDirection === "asc" ? styles.active : styles.inactive))} /></div>
                    <div className={classNames(styles.columnIndex6, styles.content)}><DashboardSortTableHeader title={TITLE_COLUMN_END_DATE} value={(sortingColumn == TITLE_COLUMN_END_DATE) ? sortingDirection : ""} onChange={(direction) => onSortChange(TITLE_COLUMN_END_DATE, direction)} className={classNames((sortingDirection === "asc" ? styles.active : styles.inactive))} /></div>
                    <div className={classNames(styles.columnIndex7, styles.content)}><DashboardSortTableHeader title={TITLE_COLUMN_ADDEDIT} value={(sortingColumn == TITLE_COLUMN_ADDEDIT) ? sortingDirection : ""} onChange={(direction) => onSortChange(TITLE_COLUMN_ADDEDIT, direction)} className={classNames((sortingDirection === "asc" ? styles.active : styles.inactive))} /></div>
                </div>
                <div className={styles.scrollContainer} ref={(el) => (scrollContainers.current[1] = el)}
                    onScroll={() => handleScroll(1)}>
                    {
                        rows
                            .map((assignment, index) => (
                                <DashboardAssignmentItem
                                    key={assignment.id}
                                    selected={assignment._isChecked}
                                    onSelect={onSelect}
                                    assignment={assignment}
                                    onSave={onSave}
                                    onDelete={onDelete}
                                    onDuplicate={onDuplicate}
                                    isEditing={assignment._isEditing}
                                    //backgroundClassName={(index % 2 !== 0) ? styles.evenRow : undefined}
                                    isEvenRow={index % 2 !== 0}
                                    locationOptions={locationOptions()}
                                    isAssigningGroup={showPreloaderList.includes(assignment.id)}
                                />
                            ))
                    }
                </div>
                <div className={classNames(styles.scrollContainer, styles.assignmentFooter)} style={{ overflow: 'hidden' }} ref={(el) => (scrollContainers.current[2] = el)}
                    onScroll={() => handleScroll(2)}>
                    <div className={classNames(styles.assignmentFilterRow, styles.rowFilter, styles.content)} style={{ display: 'flex' }}>
                        <div className={classNames(styles.columnFilter, styles.column)}>
                            <SelectBox onChange={(value) => {
                                setFilterUserType(value);
                                setCurrentPage(1);
                            }} placeholder={""} options={[...new Set(assignments.map(item => item.userType))].filter(Boolean).sort()} />
                        </div>
                        <div className={classNames(styles.columnFilter, styles.column)}>
                            <SelectBox onChange={(value) => {                                
                                setFilterProgram(value);
                                setCurrentPage(1);
                            }} placeholder={""} options={[...new Set(assignments.map(item => item.program))].filter(Boolean).sort()} />
                        </div>
                        <div className={classNames(styles.columnFilter, styles.column)}>
                            <SelectBox onChange={(value) => {
                                setFilterLocation(value);
                                setCurrentPage(1);
                            }} placeholder={""} options={locationOptions().options.map(a => a.value)} />
                        </div>
                        <div className={classNames(styles.columnFilter, styles.column)}>
                            <SelectBox onChange={(value) => {
                                setFilterStartDate(value);
                                setCurrentPage(1);
                            }} placeholder={""} options={[...new Set(assignments.map(item => item.startDate))].filter(Boolean).sort()} />
                        </div>
                    </div>
                </div>
            </div>

            <div className={classNames(styles.assignmentPaginationRow, styles.row)}>
                <div className={classNames(styles.columnPagination, styles.column)}>
                    <DashboardTablePagination onPageChange={(e) => onPageChange(e)} total={totalItems}
                        currentPage={currentPage}
                        isAutoSetPage={false}
                        perPage={limit} />
                </div>
            </div>
            <VideoTutorialModal isOpen={isOpenVideoTutorialModal} videoUrl={videoUrl} />
            <DashboardGroupAssigningModal isOpen={isOpenGroupAssigningModal} onClose={() => onCloseGroupAssigningModal()} locationOptions={locationOptions()} onAssign={(group) => { onGroupAssigning(group) }} />
            <DashboardGroupAssigningModal isOpen={isOpenEditGroupModal} onClose={() => onCloseEditGroupModal()} locationOptions={locationOptions()} onAssign={(group) => { onEditGroup(group) }} onDelete={onDeleteGroup} isEditGroup={true} groupData={getEditGroupData()} />
        </div>
    );
};

DashboardAssignmentTable.propTypes = {
    assignments: PropTypes.array.isRequired,
};


export default DashboardAssignmentTable;

