import React, { useEffect, useState } from "react";
import { CpnwContainer } from "hooks/useCpnw";
import DashboardReportEDTable from "components/DashboardReportEDTable";
import styles from "./EdSummary.module.css";
import { AccountContainer } from "hooks/useAccount";
import { CanPermissions } from "pages/enums";

const EdSummary = () => {
  const {
    learningModulesData,
	refetchLearningModules,
    reportData,
    reportRefetch,
    getPassports,
    downloadPassports,
    uploadPassports,
    deletePassports,
  } = CpnwContainer.useContainer();

  const {hasCanPermissions} = AccountContainer.useContainer()

  const showUpload = hasCanPermissions(CanPermissions.CanUpload)
  const showDownload = hasCanPermissions(CanPermissions.CanDownload)
  const showDelete = hasCanPermissions(CanPermissions.CanDelete)

  const [modules, setModules] = useState([]);
  const [reports, setReports] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);

  // endpoints are disabled by default, refetch both first
  useEffect(() => {
    reportRefetch();
    refetchLearningModules();
  }, [])

  useEffect(() => {
    if (learningModulesData && modules.length == 0) {
      setModules([...learningModulesData]);
    }
  }, [learningModulesData]);

  useEffect(() => {
    if (reportData && reportData.total > 0) {
      setReports([...reportData.data]);
    }
  }, [reportData]);

  useEffect(() => {
    if(reports.length>0 && modules.length>0){
      setDataLoading(false);
    }
  }, [reports, modules])

  const passportUpload = async (studentContactId, files) => {
    try {
      const rsp = await uploadPassports(studentContactId, files);
      return rsp?.data || [];
    } catch (error) {
      console.log(error);
    }

    return [];
  };

  const passportDelete = async (studentContactId, fileName) => {
    try {
      const rsp = await deletePassports(studentContactId, fileName);
      return rsp?.data == true;
    } catch (error) {
      console.log(error);
    }

    return false;
  };

  const onDownloadContactPassports = async (contactId, passports) => {
    try {
      let fileNames = passports?.map((m) => m.name);
      if (fileNames?.length > 0) await downloadPassports(contactId, fileNames);
    } catch (error) {
      console.log(error);
    }
  };

  console.log("EdSummary")

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {reports?.length > 0 && modules?.length > 0 ? (
          <DashboardReportEDTable
            items={reports || []}
            modules={modules}
            passportUpload={passportUpload}
            passportDelete={passportDelete}
            onGetPassportsData={getPassports}
            onDownloadContactPassports={(contactId, passports) =>
              onDownloadContactPassports(contactId, passports)
            }
            showDelete={showDelete}
            showDownload={showDownload}
            showUpload={showUpload}
          />
        ) : null}
      </div>
    </div>
  );
};

export default EdSummary;
