import React from 'react';
import PropTypes from "prop-types";

import styles from "./DashboardResultsTable.module.css";
import DashboardResultHeader from 'components/DashboardResultHeader';
import DashboardResultsItem from 'components/DashboardResultsItem';
import { convertStyleStringToObject } from 'utils/helpers.js';

export default function DashboardResultsTable({name, id, src, results, onDownloadResults}){

return (
    <div>
        <div className={styles.header_wrapper}>
            <DashboardResultHeader name={name} id={id} src={src} onDownloadResults={onDownloadResults}/>
        </div>

        <div className={styles.results_container_table}>
            <div className={styles.results_table_header}>
                <div className={styles.results_table_subject_col}>
                    <strong>Subject</strong>    
                </div>
                <div className={styles.results_table_status_col}>
                    <strong>Status</strong>
                </div>
                <div className={styles.results_table_date_col}>
                    <strong>Date</strong>
                </div>
            </div>
            {results && results.map((item, index) => {
                const styleObject = convertStyleStringToObject(item.style || "");
                
                return (
                <div key={index}>
                    {index % 2 == 0 ? (
                        <div style={styleObject}>
                            <DashboardResultsItem subject={item.subject} status={item.status} date={item.date}/>
                        </div> ) : (
                        <div className={styles.row_even} style={styleObject}>
                            <DashboardResultsItem subject={item.subject} status={item.status} date={item.date}/>
                        </div>
                    )}
                </div>
            )})}
        </div>
    </div>
);
};

DashboardResultsTable.propTypes = {
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
    results: PropTypes.arrayOf(
        PropTypes.shape({
            subject: PropTypes.string.isRequired,
            status: PropTypes.string.isRequired,
            date: PropTypes.string.isRequired,
            style: PropTypes.string
        })
    ),
}