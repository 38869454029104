import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function LazyEditor(props) {
  const setButtonTitle = () => {
    const toolbarElements = document.querySelectorAll(
      '.ql-toolbar button[class*="ql-"], .ql-toolbar span.ql-picker.ql-color-picker',
    );

    toolbarElements.forEach((element) => {
      if (element.tagName.toLowerCase() === 'button') {
        // Handles buttons
        const { className } = element;
        let title = className.split('ql-')[1].split(' ')[0]; // Split by 'ql-' and take the first part
        title = title.charAt(0).toUpperCase() + title.slice(1);
        let value = element.getAttribute('value');
        if (title === "List") {
            if (value === "ordered") {
                value = "Numbering"
            } else if (value === "bullet") {
                value = "Bullets"
            }
            title = ""
        }

        element.setAttribute('title', title ? `${title}${value ? `: ${value}` : ''}` : `${value ? value : ''}`);
      } else if (
        element.tagName.toLowerCase() === 'span' &&
        element.className.includes('ql-picker')
      ) {
        // Handles spans (color pickers)
        // get the first class in the list of classes of the span
        let title = element.className.split(' ')[0];
        // remove its ql- prefix
        title = title.split('ql-')[1];
        title = title.charAt(0).toUpperCase() + title.slice(1);
        element.setAttribute('title', `${title} picker`);
      }
    });
  };

  React.useEffect(() => {
    setButtonTitle();
  }, []);

  return <ReactQuill {...props} />;
}

export default LazyEditor;