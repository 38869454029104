import React, { useEffect, useState, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import DashboardTablePagination from "components/DashboardTablePagination";
import DashboardSortTableHeader from "components/DashboardSortTableHeader";
import { DashboardItemsPerPage } from "components/DashboardItemsPerPage";
import { DashboardContactInformationModal } from "components/DashboardContactInformationModal";
import IconStatusHelper from "components/_Helpers/IconStatusHelper";
import DashboardSearch from "components/DashboardSearch";
import DashboardSelect from 'components/DashboardSelect';
import DashboardSummaryKeyModal from 'components/DashboardSummaryKeyModal';
import DashboardPassportFileModal from "components/DashboardPassportFileModal";
import iconPlus from "assets/faIcons/iconPlus";
import iconHourglass from "assets/faIcons/iconHourglass";
import iconMinus from "assets/faIcons/iconMinus";
import iconFolderOpen from "assets/faIcons/iconFolderOpen";
import { Modal } from "@cpnw/ui";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideo } from "@fortawesome/free-solid-svg-icons";
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faMinus } from '@fortawesome/free-solid-svg-icons';
import { faHourglassHalf } from '@fortawesome/free-solid-svg-icons';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { Button } from "@cpnw/ui";
import * as XLSX from "xlsx";

import styles from "./DashboardReportEDTable.module.css";

const DashboardReportEDTable = ({ 
    items, 
    modules = [],
    passportUpload,
    passportDelete,
    onGetPassportsData,
    onDownloadContactPassports,
    showDelete,
    showUpload,
    showDownload }) => {
  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredItems, setFilteredItems] = useState(items);
  const [summaryKeyModalState, setSummaryKeyModalState] = useState(false);
  const [contactInfomationModalState, setContactInfomationModalState] = useState(false);
  const [currentContactInModal, setCurrentContactInModal] = useState({});
  const [isFileManagementModalOpen, setIsFileManagementModalOpen] = useState(false);

  const [videoUrl, setVideoUrl] = useState(undefined);
  const [uploadingVideoOpen, setUploadingVideoOpen] = useState(false);
  const [exportingVideoOpen, setExportingVideoOpen] = useState(false);

  const [selectedItems, setSelectedItems] = useState([]);
  const [showSelected, setShowSelected] = useState(false);
  const [contactDataWithPassports, setContactDataWithPassports] = useState(null);

  const [sortConfig, setSortConfig] = useState({
    key: "",
    direction: "",
  });

  const lastItemIndex = currentPage * pageLimit;
  const firstItemIndex = lastItemIndex - pageLimit;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const sortedItems = useMemo(() => {
    let sortableItems = [...filteredItems];
    if (sortConfig.key?.startsWith("contactModules.")) {
      sortableItems.sort((a, b) => {
		let moduleAbbrev = sortConfig.key.split('.')[1];
        const aValue = a.contactModules.find(m => m.moduleAbbrev == moduleAbbrev)?.status?.toString().toLowerCase() ?? "";
        const bValue = b.contactModules.find(m => m.moduleAbbrev == moduleAbbrev)?.status?.toString().toLowerCase() ?? "";

        if (aValue < bValue) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    } else if (sortConfig.key) {
      sortableItems.sort((a, b) => {
        const aValue = a[sortConfig.key]?.toString().toLowerCase() ?? "";
        const bValue = b[sortConfig.key]?.toString().toLowerCase() ?? "";

        if (aValue < bValue) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [filteredItems, sortConfig]);

  const currentItems = useMemo(() => {
    return sortedItems.slice(firstItemIndex, lastItemIndex);
  }, [sortedItems, firstItemIndex, lastItemIndex]);


  const sort = (key, direction) => {
    setSortConfig({ key, direction });
  };

  const handleSetPageLimit = (limit) => {
    setPageLimit(limit);
    setCurrentPage(1);
  };

  const [selectOptions, setSelectOptions] = useState([]);
  const [optionSelected, setOptionSelected] = useState(null)

  useEffect(() => {
      if (items && Array.isArray(items)) {
          const programs = [];
          items.forEach(item => {
              if (item.businessUnit && !programs.includes(item.businessUnit)) {
                  programs.push(item.businessUnit);
              }
          });
          setSelectOptions(programs);
      }
  }, [items]);

  useEffect(() => {
    let filtered = items.filter((item) => {
      const matchesSearchQuery = searchQuery === "" || item.name?.toLowerCase().includes(searchQuery.toLowerCase());
      const matchesOptionSelected = !optionSelected || item.businessUnit === optionSelected;
      const matchesSelected = !showSelected || selectedItems.includes(item);
      return matchesSearchQuery && matchesOptionSelected && matchesSelected;
    });
    setFilteredItems(filtered);
  }, [items, searchQuery, optionSelected, selectedItems, showSelected]);

  useEffect(() => {
    setCurrentPage(1);
}, [items, searchQuery, optionSelected, showSelected]);

  const handleSelectChange = (value) => {
    setOptionSelected(value);
  }

  const onCloseVideoTutorialModal = () => {
    setUploadingVideoOpen(false);
    setExportingVideoOpen(false);
    setVideoUrl(undefined);
  };

  const handleUploadingClick = () => {
    setVideoUrl("https://cpnw.blob.core.windows.net/documents/CoordinatorTutorials/Instructions/uploadDocuments.mp4");
    setUploadingVideoOpen(true);
}

  const handleExportingClick = () => {
      setVideoUrl("https://cpnw.blob.core.windows.net/documents/CoordinatorTutorials/Instructions/exporting.mp4");
      setExportingVideoOpen(true);
  }

  const VideoTutorialModal = ({ isOpen, videoUrl }) => {
    const closeModal = (event) => {
        event.stopPropagation(); //only way I could get the modal to close and stay closed.
        onCloseVideoTutorialModal();
    };

    return (
      <div>
        <Modal
          isOpen={isOpen}
          onRequestClose={onCloseVideoTutorialModal}
          modalClassName={styles.customWidthAndHeight}
          className={styles.custom_modal}
          children={
            <>
                <div className={styles.modal_icon}>
                    <FontAwesomeIcon icon={faXmark} style={{fontSize: "28px", transform: "translateY(-15px) translateX(15px)", color: "#808080", cursor: "pointer"}} onClick={closeModal}/>
                </div>
                <iframe
                width="100%"
                height="100%"
                src={videoUrl}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                ></iframe>
            </>
          }
        />
      </div>
    );
  };

  const handleShowAllClick = () => {
    setShowSelected(false);
    setCurrentPage(1);
  }

  const handleShowSelectedClick = () => {
    setShowSelected(true);
    setCurrentPage(1);
  }

  const handleExportButtonClick = () => {
    const dataToExport = filteredItems.map(item => {
      const contactModules = item.contactModules.reduce((acc, module) => {
        let status = "";
        switch (module.status?.toLowerCase()) { 
          case "complete": 
            status = "+";
            break;
          case "incomplete":
            status = "-";
            break;
          case "expiring":
            status = "E";
            break;
          default:
            status = "";
            break;
        }

        acc[module.moduleAbbrev] = status;
        return acc;
      }, {});
  
      return {
        Name: item.name,
        PP: "",
        BP: contactModules.BP,
        CH: contactModules.CH,
        CP: contactModules.CP,
        EM: contactModules.EM,
        FP: contactModules.FP,
        IW: contactModules.IW,
        MRI: contactModules.MRI,
        PR: contactModules.PR,
        PS: contactModules.PS,
        SP: contactModules.SP,
        TLM: contactModules.TLM,
        TG: contactModules.TG,
        OIG: item.oig === "Complete" ? "+" : "",
        SAM: item.sam === "Complete" ? "+" : "",
        Type: item.typeContactName,
        Email: item.email,
        Phone: item.primaryPhone,
        Address: item.address,
        "Emergency Contact": item.emergencyContact,
        EmergencyPhone: item.emergencyPhone,
        "SID/EID": item.individualID,
        School: item.facilityName,
        Program: item.businessUnit
      };
    });
  
    exportToExcel(dataToExport);
  }

  const exportToExcel = (data) => {
    const worksheet = XLSX.utils.json_to_sheet(data, { header: exportHeaders });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Summary");
  
    // Generate Excel file and trigger download
    XLSX.writeFile(workbook, "Reports - CPNW.xlsx");
  };

  const handleCheckboxChange = (event, item) => {
    const isChecked = event.target.checked;
    setSelectedItems((prevSelectedItems) => {
      if (isChecked) {
        return [...prevSelectedItems, item];
      } else {
        return prevSelectedItems.filter((selectedItem) => selectedItem !== item);
      }
    });
  };

  const handleOnClickContactName = (item) => {
    let contact = item;
    contact.sIDEID = item.individualID;
    contact.phone = item.primaryPhone;
    contact.emergencyContactPhone = item.emergencyPhone;
    contact.program = item.businessUnit;
    contact.school = item.facilityName;
    setCurrentContactInModal(contact);
    setContactInfomationModalState(true);
  }

  const onCloseContactInfoModal = () => {
    setContactInfomationModalState(false);
    setCurrentContactInModal({});
  }

  const [loadingPassports, setLoadingPassports] = useState(false)

  const handleGetPassports = async (contactId) => {
    let passports = [];

    setLoadingPassports(true)
    setIsFileManagementModalOpen(true);

    try {
      const rsp = await onGetPassportsData(contactId);
      passports = rsp?.data || [];
    } catch (error) {
      console.log(error);
    }

    setContactDataWithPassports({
      contactId: contactId,
      passports: passports,
    });

    setLoadingPassports(false)
  };
  
  return (
    <div className={styles.containerFlex}>
      {summaryKeyModalState && <DashboardSummaryKeyModal onClose={() => {setSummaryKeyModalState(false)}} isOpen={summaryKeyModalState} items={headersTable}/>}
      {isFileManagementModalOpen && (
        <DashboardPassportFileModal
          isOpen={true}
          onClose={() => setIsFileManagementModalOpen(false)}
          contactDataWithPassports={contactDataWithPassports}
          passportUpload={passportUpload}
          passportDelete={passportDelete}
          onDownloadContactPassports={onDownloadContactPassports}
          isLoading={loadingPassports}
          showDelete={showDelete}
          showDownload={showDownload}
          showUpload={showUpload}
        />
      )}
      <DashboardContactInformationModal isOpen={contactInfomationModalState} headerTitle={"Contact Information"} onClose={() => { onCloseContactInfoModal() }} contact={currentContactInModal} />
      <div>
        <DashboardSelect
          placeholder="Programs - Select All"
          options={selectOptions}
          tip="Select the data to render in the table"
          selected={optionSelected}
          onSelect={handleSelectChange}
        />
      </div>
      <div className={styles.actionAndColumnNameModalContainer}>
        <div className={styles.actionContainer}>
          <span>Key:</span>
          <div>
            {iconPlus}
            <span style={{ marginLeft: "10px" }}>Complete</span>
          </div>
          <div>
            {iconMinus}
            <span style={{ marginLeft: "10px" }}>Incomplete</span>
          </div>
          <div>
            {iconHourglass}
            <span style={{ marginLeft: "10px" }}>Expiring</span>
          </div>
        </div>
        <div className={styles.headerNamesActionContainer}>
          <span className={styles.clickable_key} onClick={() => {setSummaryKeyModalState(true)}}>Click here to view</span>{" "}column names.
        </div>
      </div>

      <hr className={styles.hr} />

      <div className={styles.lower_container}>
        <h3 className={styles.lower_header}>Summary Tutorials:</h3>
        <div className={styles.lower_subheader_row}>
          <div className={classNames(styles.lower_subheader_item, styles.pointer)} onClick={() => handleUploadingClick()}>
              {uploadingVideoOpen && <VideoTutorialModal isOpen={uploadingVideoOpen} videoUrl={videoUrl} />}
              <h4>Uploading:</h4>
              <span className={styles.lower_subheader_icon}><FontAwesomeIcon icon={faVideo} style={{color: "#007E8D", marginBottom: "3px"}} /></span>
             
          </div>
          <div className={styles.bar}>
              |
          </div>
          <div className={classNames(styles.lower_subheader_item, styles.pointer)} onClick={() => handleExportingClick()}>
            {exportingVideoOpen && <VideoTutorialModal isOpen={exportingVideoOpen} videoUrl={videoUrl} />}
            <h4>Exporting:</h4>
            <span className={styles.lower_subheader_icon}><FontAwesomeIcon icon={faVideo} style={{color: "#007E8D", marginBottom: "3px"}} /></span>
          </div>
          <div className={styles.bar}>
              |
          </div>
          <div className={styles.lower_subheader_item}>
            <a href="https://cpnw.blob.core.windows.net/documents/CoordinatorTutorials/Instructions/CoordinatorChecklist.pdf" target="_blank" rel="noopener noreferrer" className={styles.lower_subheader_coord}>
                Coordinator Checklist (PDF)
            </a>
          </div>
      </div>
      </div>
      
      <div className={classNames(styles.table_upper_row)}>
        <DashboardItemsPerPage
          disabled={false}
          limitOptions={[10, 25, 50, 100]}
          onLimitChange={handleSetPageLimit}
          limit={pageLimit}
        />

        <div className={styles.buttons_col}>
          <Button onClick={handleShowAllClick} text={'Show All'} className={styles.button_height}/>
          <Button onClick={handleShowSelectedClick} text={'Show Selected'} className={styles.button_height} disabled={selectedItems?.length === 0}/>
          <Button onClick={handleExportButtonClick} text={'Export'} className={styles.button_height}/>
        </div>

        <DashboardSearch onInput={setSearchQuery} />
      </div>
      <div className={styles.tableWrapper}>
        <table className={styles.customTable}>
          <thead className={styles.customTableHeader}>
            <tr className={styles.customTableHeaderRow}>
              <th className={styles.smallColumn}></th>
              <th className={styles.smallColumn} style={{ width: 200 }}>
                Name
              </th>
              <th className={styles.smallColumn}>
                <DashboardSortTableHeader
                  value={sortConfig.key === "pp" ? sortConfig.direction : ""}
                  onChange={(direction) => sort("pp", direction)}
                  title="PP"
                  width={50}
                />
              </th>
              {modules.map((m) => (
                <th key={m.moduleAbbrev} className={styles.smallColumn}>
                  <DashboardSortTableHeader
                    value={
                      sortConfig.key === `contactModules.${m.moduleAbbrev?.toUpperCase()}` ? sortConfig.direction : ""
                    }
                    onChange={(direction) =>
                      sort(
                        `contactModules.${m.moduleAbbrev?.toUpperCase()}`,
                        direction
                      )
                    }
                    title={m.moduleAbbrev?.toUpperCase()}
                    width={50}
                  />
                </th>
              ))}
              <th className={styles.smallColumn}>
                <DashboardSortTableHeader
                  value={sortConfig.key === "oig" ? sortConfig.direction : ""}
                  onChange={(direction) => sort("oig", direction)}
                  title="OIG"
                  width={50}
                />
              </th>
              <th className={styles.smallColumn}>
                <DashboardSortTableHeader
                  value={sortConfig.key === "sam" ? sortConfig.direction : ""}
                  onChange={(direction) => sort("sam", direction)}
                  title="SAM"
                  width={50}
                />
              </th>
              <th className={styles.smallColumn}>
                <DashboardSortTableHeader
                  value={
                    sortConfig.key === "typeContactName"
                      ? sortConfig.direction
                      : ""
                  }
                  onChange={(direction) => sort("typeContactName", direction)}
                  title="Type"
                  width={50}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr></tr>
            {currentItems.length === 0 ? (
              <tr>
                <td colSpan="13" className={styles.noData}>
                  No data available in table
                </td>
              </tr>
            ) : (
              currentItems.map((m, index) => (
                <tr key={m.id} className={classNames(styles.customTableRow, {
                    [styles.selected]: selectedItems.includes(m),
                  })}>
                  <td className={styles.checkboxSelect}>
                  <input
                    type="checkbox"
                    checked={selectedItems.includes(m)}
                    onChange={(e) => handleCheckboxChange(e, m)}
                  />
                  </td>
                  <td> <span onClick={() => handleOnClickContactName(m)} className={styles.item_name_link}>{m.name}</span></td>
                  <td><span onClick={() => handleGetPassports(m.id)}>{iconFolderOpen}</span></td>
                  {modules.map((module) => {
                    let contactModule = m.contactModules?.find(
                      (o) => o.moduleId == module.id
                    );

                    return (
                      <td key={module.id}>
                        {IconStatusHelper(
                          contactModule?.status,
                          contactModule?.expirationDate
                        )}
                      </td>
                    );
                  })}
                  <td>{IconStatusHelper(m.oig)}</td>
                  <td>{IconStatusHelper(m.sam)}</td>
                  <td>{m.typeContactName}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <div className={styles.pagingContainer}>
        <DashboardTablePagination
          onPageChange={handlePageChange}
          total={filteredItems.length}
          perPage={pageLimit}
          currentPage={currentPage}
        />
      </div>
    </div>
  );
};

const headersTable = [
  {short: 'PP', full: 'Passport Documents'},
  {short: 'ER', full: 'Emergency Response Module'},
  {short: 'PS', full: 'Patient Safety Module'},
  {short: 'BP', full: 'Blood-borne Pathogens Module'},
  {short: 'IW', full: 'Infections Waste Module'},
  {short: 'SP', full: 'Standard Precautions Module'},
  {short: 'CH', full: 'Chemical Hazards Module'},
  {short: 'FP', full: 'Fall Prevention Module'},
  {short: 'OIG', full: 'OIG Excluded Provider Check'},
  {short: 'CP', full: 'Compliance Module'},
  {short: 'MRI', full: 'MRI Saftey Module'},
  {short: 'SAM', full: 'SAM Excluded Provider Check'},
  {short: 'PR', full: 'Patient Rights Module'},
];

const exportHeaders = [
  "Name", "PP", "BP", "CH", "CP", "EM", "FP", "IW", "MRI", "PR", "PS", "SP", 
  "TLM", "TG", "OIG", "SAM", "Type", "Email", "Phone", "Address", "Emergency Contact", 
  "EmergencyPhone", "SID/EID", "School", "Program"
];

DashboardReportEDTable.propTypes = {};

export default DashboardReportEDTable;
