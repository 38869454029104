import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import DashboardOIGSAMTable from "components/DashboardOIGSAMTable";
import styles from "./DashboardOIGSAM.module.css";

export function DashboardOIGSAM({
  header,
  infoLeft,
  infoRight,
  items, // Receive items as props
  itemWidth, // Receive itemWidth as props
}) {
  return (
    <div className={classNames([styles.dashboardOIGSAM])}>
      <div className={styles.top}>
        <div>
          <h2 className={styles.lineAfter}>{header || "Header"}</h2>
        </div>
      </div>
      <div className={styles.infoContainer}>
        <div className={styles.info}>{infoLeft || "Info Left"}</div>
        <div className={styles.info}>{infoRight || "Info Right"}</div>
      </div>
      <div className={styles.tableContainer}>
        {/* Pass items and itemWidth as props */}
        <DashboardOIGSAMTable items={items} itemWidth={itemWidth} />
      </div>
    </div>
  );
}

DashboardOIGSAM.propTypes = {
  header: PropTypes.string,
  infoLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  infoRight: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  itemWidth: PropTypes.shape({
    scanDateWidth: PropTypes.number,
    nameInputWidth: PropTypes.number,
    agencyWidth: PropTypes.number,
    scanResultWidth: PropTypes.number,
    messageWidth: PropTypes.number,
    nameFoundWidth: PropTypes.number,
  }).isRequired,
};

export default DashboardOIGSAM;
