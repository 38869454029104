import React, { useEffect, useState } from "react";
import moment from "moment";
import { CpnwContainer } from "hooks/useCpnw";
import DashboardOIGSAMAdminTable from "components/DashboardOIGSAMAdminTable";
import DashboardSelectBoxWithValue from "components/DashboardSelectBoxWithValue";
import { Button } from "@cpnw/ui";
import _ from "lodash";
import styles from "./HcOIGSAMCheck.module.css";
import { format, parseISO } from "date-fns";

import classNames from 'classnames';
const HcOIGSAMCheck = () => {
  const {
    reportData,
    reportRefetch,
    getExclusionReports,
    getExclusionFilters,
  } = CpnwContainer.useContainer();
  const [showPreloader, setShowPreloader] = useState(false);
  const [showReports, setShowReports] = useState(false);
  const [reports, setReports] = useState([]);
  const [dateRuns, setDateRuns] = useState([]);
  const [schools, setSchools] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [selectedDateRun, setDateRun] = useState('');
  const [selectedSchool, setSelectedSchool] = useState('');
  const [selectedProgram, setSelectedProgram] = useState('');
  const [filters, setFilters] = useState({
    schoolName: '',
    programName: '',
  });

  useEffect(() => {
    if (dateRuns && dateRuns.length > 0 && dateRuns[0].key && !selectedDateRun) {
      setDateRun(dateRuns[0].key);
    }

    if (schools && schools.length > 0 && schools[0].id && !selectedSchool) {
      setSelectedSchool(schools[0].id);
    }

    if (programs && programs.length > 0 && programs[0].id && !selectedProgram) {
      setSelectedProgram(programs[0].id);
    }
  }, [programs, schools, dateRuns]);

  useEffect(() => {
    try {
      if (!reportData) reportRefetch();

      getExclusionFilters().then((res) => {
        setDateRuns(
          (res?.data?.dateRuns || []).map((m) => ({
            key: m,
            value: formatDate(m),
          }))
        );
      });
    } catch (error) { }
  }, []);

  useEffect(() => {
    if (reportData && reportData.data) {
      console.log(reportData.data)
      const newSchools = _(reportData.data)
        .groupBy((m) => m.facilityId)
        .map((value, key) => ({
          id: key,
          name: value[0].facilityName,
          programs: _(value)
            .groupBy((m) => m.businessUnitId)
            .map((mValue, mKey) => ({
              id: mKey,
              name: `${mValue[0].businessUnitId} - ${mValue[0].businessUnit} (${mValue[0].businessUnitAbbrev})`,
            }))
            .value(),
        }))
        .value();

      // Add a new item to the beginning of newSchools
      const additionalSchool = {
        id: '0',
        name: 'All',
        programs: [],
      };
      newSchools.unshift(additionalSchool);

      setSchools(newSchools || []);
      setPrograms(newSchools?.flatMap((m) => m.programs) || []);
    }
  }, [reportData]);

  const onChangeSchool = (value) => {
    setSelectedSchool(value);
    const newSchools = schools
      ?.filter((m) => value == null || m.id == value)
      .flatMap((m) => m.programs);
    setPrograms((newSchools && newSchools.length > 0) ? newSchools : programs);
    setSelectedProgram((newSchools && newSchools.length > 0) ? newSchools[0]?.id : programs[0]?.id);
  };

  const getReports = async () => {
    if (!selectedProgram || !selectedDateRun) return;
    setShowPreloader(true);
    setShowReports(true);
    let reports = null;
    try {
      reports = await getExclusionReports(selectedProgram, selectedDateRun);
    } catch (error) { }

    setFilters({
      schoolName: schools.find((m) => m.id == selectedSchool)?.name,
      programName: programs.find((m) => m.id == selectedProgram)?.name,
    });

    setReports(reports?.data || []);
    setShowPreloader(false);
  };

  function formatDate(dateString) {
    // Check if item.startDate is not null or undefined
    if (dateString) {
      const utcDateString = dateString;

      // Convert the UTC date to a Date object
      const date = new Date(utcDateString);

      // Extract components
      const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      const day = String(date.getUTCDate()).padStart(2, '0');
      const year = date.getUTCFullYear();

      // Format to "MM-dd-yyyy"
      const formattedDate = `${month}-${day}-${year}`;
      return formattedDate;
    }
  }

  return (
    <div className={styles.hcOIGSAMCheckContainer}>
      <h1>OIG/SAM Excluded Provider Check</h1>
      <div>
        <div className={styles.description}>
          <div>
            <p>
              Excluded provider checks are performed bi-monthly on the 1st and
              15th of each month for all users in the clinical setting. Your
              schools' results will be posted on this page by the 5th and 20th
              of the month.
            </p>
            <br />
            <p>
              If there is a match of any kind, the school is responsible for
              further examining the finding.
            </p>
          </div>
          <div>
            <p>
              CPNW runs the screening against two federal excluded provider
              lists.The data sources for this check are:
            </p>
            <br />
            <ul className={styles.excludedProviderList}>
              <li>
                Office of Inspector General(OIG) list of Excluded Individuals
                and Entities(LEIE)
              </li>
              <li>
                General Services Administration(GSA) System for Award
                Management(SAM)
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.report}>
          <div className={styles.reportQuery}>
            <div className={styles.reportQueryDate}>
              <p>Report Date</p>
              <DashboardSelectBoxWithValue
                selected={selectedDateRun}
                onChange={(value) => {
                  setDateRun(value);
                }}
                placeholder={""}
                options={dateRuns}
              />
            </div>
            <div className={styles.reportQuerySchool}>
              <p>School</p>
              <DashboardSelectBoxWithValue
                selected={selectedSchool}
                onChange={(value) => onChangeSchool(value)}
                placeholder={""}
                options={schools.map((m) => ({
                  key: m.id,
                  value: m.name,
                }))}
              />
            </div>
            <div className={styles.reportQueryProgram}>
              <p>Program</p>
              <DashboardSelectBoxWithValue
                selected={selectedProgram}
                onChange={(value) => {
                  setSelectedProgram(value);
                }}
                placeholder={""}
                options={programs.map((m) => ({
                  key: m.id,
                  value: m.name,
                }))}
              />
              <div>
                <Button
                  onClick={() => getReports()}
                  text="Run Report"
                  disabled={!selectedDateRun || !selectedProgram}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {showPreloader ? <div className={classNames(styles.sp, styles.spCircle)}></div> :
        showReports && (
          <div className={styles.content}>
            <DashboardOIGSAMAdminTable items={mapReports(reports)} filters={filters} />
          </div>
        )}
    </div>
  );
};

const mapReports = (reports) => {
  let result = (reports || []).map((m) => {
    return {
      id: m.id || `${Math.random().toString(36).substr(2, 9)}`,
      scanDate: m.dateRun,
      nameInput: m.nameInput,
      program: m.contact?.businessUnitAbbrev || "",
      sidEid: m.contact?.individualId || "",
      agency: m.tableHIT,
      scanResult: m.likelyMatch,
      message: m.message,
      userType: m.contact?.typeContactName || "",
      contact: m.contact,
      _isSelected: false,
    };
  });

  return result;
};


export default HcOIGSAMCheck;
