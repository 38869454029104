import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { DashboardItemsPerPage } from "components/DashboardItemsPerPage";
import DashboardSearch from "components/DashboardSearch";
import DashboardSortTableHeader from "components/DashboardSortTableHeader";
import DashboardOIGSAMItem from "components/DashboardOIGSAMItem";
import DashboardTablePagination from "components/DashboardTablePagination";
import styles from "./DashboardOIGSAMTable.module.css";

const DashboardOIGSAMTable = ({ items, itemWidth }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortCriteria, setSortCriteria] = useState({
    column: "scanDate",
    direction: "asc",
  });

  // Filter items based on search term
  const filteredItems = items.filter((item) =>
    Object.values(item).some((value) =>
      value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  // Perform sorting on items
  const sortedItems = filteredItems.sort((a, b) => {
    const { column, direction } = sortCriteria;
    const aValue = a[column];
    const bValue = b[column];
    if (direction === "asc") {
      return aValue > bValue ? 1 : -1;
    } else {
      return aValue < bValue ? 1 : -1;
    }
  });

  // Pagination
  const [currentItems, setCurrentItems] = useState([]);

  useEffect(() => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const newCurrentItems = sortedItems.slice(
      indexOfFirstItem,
      indexOfLastItem
    );

    // Only update state if the currentItems actually change
    if (JSON.stringify(newCurrentItems) !== JSON.stringify(currentItems)) {
      setCurrentItems(newCurrentItems);
    }
  }, [currentPage, itemsPerPage, sortedItems, currentItems]);

  // Handle pagination change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle items per page change
  const handleItemsPerPageChange = (perPage) => {
    setItemsPerPage(perPage);
    setCurrentPage(1); // Reset to first page when changing items per page
  };

  // Handle search input
  const handleSearch = (input) => {
    setSearchTerm(input);
    setCurrentPage(1); // Reset to first page when performing search
  };

  // Handle sorting
  const handleSort = (column) => {
    setSortCriteria((prevCriteria) => {
      if (prevCriteria.column === column) {
        // If clicking the same column, toggle the direction
        const newDirection = prevCriteria.direction === "asc" ? "desc" : "asc";
        return { column, direction: newDirection };
      } else {
        // If clicking a different column, default to ascending order
        return { column, direction: "asc" };
      }
    });
  };

  return (
    <div className={styles.container}>
      <div className={classNames(styles.top)}>
        <DashboardItemsPerPage
          disabled={false}
          limit={itemsPerPage}
          limitOptions={[5, 10, 20, 50, 100]}
          onLimitChange={handleItemsPerPageChange}
        />
        <DashboardSearch onInput={handleSearch} searchDelay={300} minChar={3} />
      </div>
      <div className={classNames(styles.tableWrapper)}>
        <div className={styles.table}>
          <div className={classNames(styles.tableHeader)}>
            {/* Render all table headers */}
            <DashboardSortTableHeader
              title="Scan Date"
              width={itemWidth.scanDateWidth}
              onChange={(direction) => handleSort("scanDate", direction)}
              value={
                sortCriteria.column === "scanDate" ? sortCriteria.direction : ""
              }
            />
            <DashboardSortTableHeader
              title="Name Input"
              width={itemWidth.nameInputWidth}
              onChange={(direction) => handleSort("nameInput", direction)}
              value={
                sortCriteria.column === "nameInput"
                  ? sortCriteria.direction
                  : ""
              }
            />
            <DashboardSortTableHeader
              title="Agency"
              width={itemWidth.agencyWidth}
              onChange={(direction) => handleSort("agency", direction)}
              value={
                sortCriteria.column === "agency" ? sortCriteria.direction : ""
              }
            />
            <DashboardSortTableHeader
              title="Scan Result"
              width={itemWidth.scanResultWidth}
              onChange={(direction) => handleSort("scanResult", direction)}
              value={
                sortCriteria.column === "scanResult"
                  ? sortCriteria.direction
                  : ""
              }
            />
            <DashboardSortTableHeader
              title="Message"
              width={itemWidth.messageWidth}
              onChange={(direction) => handleSort("message", direction)}
              value={
                sortCriteria.column === "message" ? sortCriteria.direction : ""
              }
            />
            <DashboardSortTableHeader
              title="Name Found"
              width={itemWidth.nameFoundWidth}
              onChange={(direction) => handleSort("nameFound", direction)}
              value={
                sortCriteria.column === "nameFound"
                  ? sortCriteria.direction
                  : ""
              }
            />
          </div>
          {currentItems.length === 0 ? (
            <div className={styles.no_data_row}>
              No data available in table
            </div>
          ) : (
            currentItems.map((item, index) => (
              <div key={index} className={classNames(styles.row, index %2 === 1 && styles.oddRow)}>
                <DashboardOIGSAMItem item={item} itemWidth={itemWidth} />
              </div>
            ))
          )}
        </div>
      </div>
      <div className={styles.bottom}>
        <DashboardTablePagination
          onPageChange={handlePageChange}
          total={sortedItems.length}
          perPage={itemsPerPage}
        />
      </div>
    </div>
  );
};

DashboardOIGSAMTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  itemWidth: PropTypes.shape({
    scanDateWidth: PropTypes.number,
    nameInputWidth: PropTypes.number,
    agencyWidth: PropTypes.number,
    scanResultWidth: PropTypes.number,
    messageWidth: PropTypes.number,
    nameFoundWidth: PropTypes.number,
  }).isRequired,
};

export default DashboardOIGSAMTable;
