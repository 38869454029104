import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./DashboardItemsPerPage.module.css";

export const DashboardItemsPerPage = ({
  disabled,
  limit,
  limitOptions,
  onLimitChange,
}) => {
  const handleChange = (e) => {
    const newLimit = parseInt(e.target.value);
    onLimitChange(newLimit);
  };

  return (
    <div className={classNames(styles.container)}>
      Show
      <div className={styles.selectContainer}>
        <select
          className={styles.itemsPerPage}
          disabled={disabled}
          value={limit}
          onChange={handleChange}
        >
          {limitOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
      entries
    </div>
  );
};

DashboardItemsPerPage.propTypes = {
  disabled: PropTypes.bool.isRequired,
  limit: PropTypes.number.isRequired,
  limitOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  onLimitChange: PropTypes.func.isRequired,
};
