import React, { useCallback, useEffect, useState } from "react";
import styles from "./MyDashboardPage.module.css";
import { HeaderSection, Loading } from "@cpnw/ui";
import DashboardStepsList from "components/DashboardStepsList";
// import DashboardNoticeItem from "components/DashboardNoticeItem";
import DashboardNoticeList from "components/DashboardNoticeList";
import { StaticRoutes } from "pages/routes";
import DashboardClinicalAssignmentTable from "components/DashboardClinicalAssignmentTable";
// import { CpnwContainer } from "hooks/useCpnw";
import { AccountContainer } from "hooks/useAccount";
import { RequirementContainer } from "hooks/useRequirement";
import { Modal } from "@cpnw/ui";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CpnwContainer } from "hooks/useCpnw";
import PassportUserInputView from "components/PassportUserInputView";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const MyDashboardPage = () => {
  const history = useHistory()
  const {contactNoticesData, refetchContactNotices, myRequirementsData, refetchMyRequirements} = RequirementContainer.useContainer();
  const [selectedContactRequirementId, setSelectedContactRequirementId] = useState(null)
  
  const { takeQuiz } = CpnwContainer.useContainer();

  const [requirements, setRequirements] = useState({ items: [], type: 1 });
  const [expiration, setExpiration] = useState({ items: [], type: 2, expiration: "" });
  const [system, setSystem] = useState({ items: [], type: 3 });
  const [clinAssignments, setClinAssignments] = useState([])
  const [noticeCount, setNoticeCount] = useState(0);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  //gets user data (role: education/faculty/healthcare/etc.)
  const { meData, refetchMeData } = AccountContainer.useContainer(); 

  const onNoticeClick = useCallback(async (item) => {
    console.log("onNoticeClick", item)
    const path = item.Path
    if(path.startsWith("/MyRequirements/")){
      //load data and open modal
      const contactRequirementId = path.split("/MyRequirements/")[1]

      setSelectedContactRequirementId(contactRequirementId)
      setIsModalOpen(true);
    }
    else if(path.startsWith("/TakeQuiz/")){
      //load data and open modal
      const moduleId = path.split("/TakeQuiz/")[1]
      const data = await takeQuiz(moduleId)
      const url = data.url
      console.log("url", url)
      window.open(url, "_blank")
    }
    else{
      //for anything that don't have custom solution, redirect it
      if(path.startsWith("https://") || path.startsWith("http://")){
        //absolute
        window.open(path, "_blank")
      }
      else{
        //relative
        history.push(path)
      }
    }
  }, [myRequirementsData])

  useEffect(()=>{
    if(myRequirementsData && selectedContactRequirementId){
      const requirement = myRequirementsData.data.find(a=>a.ContactRequirementId == selectedContactRequirementId)
      if(requirement){
        (requirement.Submissions || []).forEach(sub => {
          sub.files = (sub.Files || []).map(f=>({
            id: f.Id,
            name: f.Name,
            url: f.Url
          }))
        });

        const modalData = {
          contactRequirementId: requirement.ContactRequirementId,
          requirementName: requirement.Name,
          type: requirement.Category,
          abbreviation: requirement.Abbr,
          requiredBy: requirement.RequiredBy.map((a) => a.FacilityName),
          frequency: requirement.Frequency,
          instructions: requirement.Instructions || "",
          status: requirement.Status || "",
          requirementFiles: (requirement.RequirementFiles || []).map((a) => ({
            id: a.Id,
            name: a.Name,
            url: a.Url,
          })),
          messages: requirement.Messages || [],
          allowWaiver: requirement.AllowWaiver,
          isWaived: requirement.IsWaived,
          score: requirement.Score ? requirement.Score.Score : null,
          submissions: requirement.Submissions,
          submission: requirement.Submissions.length > 0 ? requirement.Submissions[0] : {},
          hasLicenses: requirement.HasLicenses
        };

        setModalContent(modalData);
      }
      else{
        alert("Requirement not found or expired")
      }
    }
  }, [myRequirementsData, selectedContactRequirementId])

  useEffect(()=>{
    if(!myRequirementsData){
      refetchMyRequirements()
    }
  }, [myRequirementsData])

  useEffect(()=>{
    refetchContactNotices();
  }, [])

  useEffect(()=>{
    if(contactNoticesData && contactNoticesData.data){
      const data = contactNoticesData.data
      setRequirements(data.filter(a=>a.Type == 1))
      setExpiration(data.filter(a=>a.Type == 2))
      setSystem(data.filter(a=>a.Type == 3))
      setNoticeCount(data.length)
    }
  }, [contactNoticesData])

  const onSaveComplete = () =>{
    setIsModalOpen(false)
    refetchContactNotices()
    refetchMyRequirements()
  }

  const steps = [
    {
      step: 1,
      info: "Check this page daily. The most current notifications and clinical assignments will be posted here.",
      link: {
        text: "Check this page daily.",
      },
    },
    {
      step: 2,
      info: "Review and complete every item listed under the Requirements link on the left.",
      link: {
        text: "Requirements",
        src: StaticRoutes.MyRecordsRequirements
      }
    },
    {
      step: 3,
      info: "If your address or cell contact information changes, you can update it under the My Account link on the left.",
      link: {
        text: "My Account",
        src: StaticRoutes.MyProfile,
      },
    },
  ];
  
  return (
    <div className={styles.page}>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        className={styles.modal_content}
        modalClassName={styles.modal_wrapper}
      >
        <div className={styles.admin_review_modal}>
          <div className={styles.close_modal_btn}>
            <FontAwesomeIcon
              icon={faXmark}
              style={{
                fontSize: "28px",
                transform: "translateY(-15px) translateX(15px)",
                color: "#808080",
                cursor: "pointer",
              }}
              onClick={() => setIsModalOpen(false)}
            />
          </div>
          {modalContent && <PassportUserInputView requirement={modalContent} onSaveComplete={onSaveComplete} />}
          {!modalContent && <Loading/>}
        </div>
      </Modal>
      <div className={styles.header}>
        <HeaderSection header={"My Dashboard"} />
      </div>
      <div className={styles.pageContent}>
        <div className={styles.steps}>
          <h3>First Steps</h3>
          <DashboardStepsList steps={steps} />
        </div>

        <div className={styles.updated_notices}>
            <div className={styles.header_badge_description_container}>
              <div className={styles.header_badge_row}>
                <h3>Notices</h3>
              </div>
              <p>Click on links to be taken to full text of notice.</p>
            </div>
            {requirements.length>0 && (
              <div className={styles.requirement_notices}>
                <DashboardNoticeList items={requirements} type={1} onClick={onNoticeClick}/>
              </div>
            )}
            {expiration.length>0 && (
              <div className={styles.expiration_notices}>
                <DashboardNoticeList items={expiration} type={2} onClick={onNoticeClick} expirationTip={"(Notices will post 120 days from expiration.)"}/>
              </div>
            )}
            {system.length>0 && (
              <div className={styles.system_notices}>
                <DashboardNoticeList items={system} type={3} onClick={onNoticeClick}/>
              </div>
            )}
        </div>

        {/* Old Notices */}
        {/* <div className={styles.notices}>
          <h3>Notices</h3>
          <DashboardNoticeItem text={notices} /> */}
          {/* <DashboardNoticeList /> */}

          {/* {notices.map((notice, index) => (
            <div key={index}>
              <DashboardNoticeItem text={notice} />
              <br />
            </div>
          ))} */}

        {/* </div> */}
      </div>

      {meData && (meData.roles.includes("Student") || meData.roles.includes("Faculty")) && (
        <div className={styles.assignments_table}>
            <DashboardClinicalAssignmentTable assignments={clinAssignments} />
        </div>
      )}

    </div>
  );
};

export default MyDashboardPage;
