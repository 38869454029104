import { useState, useEffect } from "react";

function getDefaultSorting(defaultTableData, columns) {
  const sorted = [...defaultTableData].sort((a, b) => {
    const filterColumn = columns.filter((column) => column.sortbyOrder);

    // Merge all array objects into single object and extract accessor and sortbyOrder keys
    let { accessor = "id", sortbyOrder = "asc" } = Object.assign(
      {},
      ...filterColumn
    );

    if (a[accessor] === null) return 1;
    if (b[accessor] === null) return -1;
    if (a[accessor] === null && b[accessor] === null) return 0;

    const ascending = a[accessor]
      .toString()
      .localeCompare(b[accessor].toString(), "en", {
        numeric: true,
      });

    return sortbyOrder === "asc" ? ascending : -ascending;
  });
  return sorted;
}

export const useSortableTable = (data, columns) => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setTableData(getDefaultSorting(data, columns));
  }, [data])

  const handleSorting = (sortField, sortOrder) => {
    console.log('inside usesortable');
    console.log(sortField)
    console.log(sortOrder)
    console.log(tableData)
    if (sortField) {
      const sorted = [...tableData].sort((a, b) => {
        if(sortField === 'contact'){
          // console.log(a[sortField].name)
          if (a[sortField].name === null) return 1;
          if (b[sortField].name === null) return -1;
          if (a[sortField].name === null && b[sortField].name === null) return 0;
          return (
            a[sortField].name.toString().localeCompare(b[sortField].name.toString(), "en", {
              numeric: true,
            }) * (sortOrder === "asc" ? 1 : -1)
          )
        } else {
          if (a[sortField] === null) return 1;
          if (b[sortField] === null) return -1;
          if (a[sortField] === null && b[sortField] === null) return 0;
          return (
            a[sortField].toString().localeCompare(b[sortField].toString(), "en", {
              numeric: true,
            }) * (sortOrder === "asc" ? 1 : -1)
          );
        }
      });
      setTableData(sorted);
    }
  };

  return [tableData, handleSorting];
};
