import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from "prop-types";

import styles from './DashboardSummaryKeyModal.module.css';

import {Modal} from "@cpnw/ui";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

export default function DashboardSummaryKeyModal({onClose, isOpen, items}){

const onCloseModal = () => {
    console.log("model closed");
    onClose();
}

//information shown on model
const SummaryKeyInfo = () => {
    return (
        <>
            <div className={styles.modal_container}>
                <div className={classNames(styles.modal_container_header_row, styles.just_between)}>
                    <h4>Passport Report Items</h4>
                    <FontAwesomeIcon icon={faXmark} style={{fontSize: "28px", transform: "translateY(-15px) translateX(15px)", color: "#808080", cursor: "pointer"}} onClick={() => onCloseModal()}/>
                </div>
                <div className={styles.modal_container_content}>
                    <ul className={styles.modal_container_row}>
                        {items.map((item, index) => (
                            <li key={index}>
                                <span className={styles.key_abbrev}>{item.short}</span>{" "}-{" "}{item.full}
                            </li>
                        ))}
                    </ul>
                </div>

            </div>
        </>
    )
}

//actual return of model here
return (
    <>
        {isOpen ? 
                <Modal isOpen={isOpen} onRequestClose={onCloseModal}
                modalClassName={styles.customWidthAndHeight}
                className={classNames(styles.summary_key_modal)}
                children={<SummaryKeyInfo />} />
            :
                <div>

                </div>
        }

    </>
);
};

DashboardSummaryKeyModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            short: PropTypes.string.isRequired,
            full: PropTypes.string.isRequired
        }).isRequired
    ).isRequired
}