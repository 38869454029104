import React from "react";
import PropTypes from "prop-types";
import styles from "./DashboardClinicalAssignmentItem.module.css";

const DashboardClinicalAssignmentItem = ({ assignment, columnWidths }) => {
  const { location, startDate, endDate } = assignment;

  return (
    <div className={styles.assignmentItem}>
      <div className={styles.cell} style={{ width: columnWidths[0] }}>
        {location}
      </div>
      <div className={styles.cell} style={{ width: columnWidths[1] }}>
        {startDate}
      </div>
      <div className={styles.cell} style={{ width: columnWidths[2] }}>
        {endDate}
      </div>
    </div>
  );
};

DashboardClinicalAssignmentItem.propTypes = {
  assignment: PropTypes.shape({
    location: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
  }).isRequired,
  columnWidths: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default DashboardClinicalAssignmentItem;
