import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./DashboardPassportReportItemModal.module.css";
import { Modal } from "@cpnw/ui";

const DashboardPassportReportItemModal = ({ children, onClose, isOpen }) => {
  const onCloseModal = () => {
    onClose();
  };

  const SummaryReportItemsModalContent = () => {
    return (
      <>
        <div className={styles.flexContainer}>
          <h2 id="reportCol">Summary Report Items</h2>
          <div
            className={classNames(styles.close, styles.flexItem)}
            onClick={() => onCloseModal()}
          >
            <div className={styles.timeIcon}>x</div>
          </div>
        </div>
        <div class={styles.row}>
          {children}
          <a class="close-reveal-modal" aria-label="Close">
            ×
          </a>
        </div>
      </>
    );
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseModal}
      modalClassName={styles.customWidthAndHeight}
      className={classNames(styles.dashboardContactInformationModal)}
      children={<SummaryReportItemsModalContent />}
    />
  );
};

DashboardPassportReportItemModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default DashboardPassportReportItemModal;
