import React, { useState, useEffect } from "react";

import { Modal } from "@cpnw/ui";
import { Button } from "@cpnw/ui";
import { SelectBox } from "@cpnw/ui";
import { InputBox } from "@cpnw/ui";

import styles from "./DashboardProfileCorrectionModal.module.css";

import PropTypes from "prop-types";

const DashboardProfileCorrectionModal = ({onSet, selectOptions, onSelectChange, onTextChange, isOpen, onClose, reason, additionalReason }) => {
    const [selectedReason, setSelectedReason] = useState('');
    const [additionalReasonState, setAdditionalReasonState] = useState('');

    useEffect(() => {
        if (isOpen) {
            setSelectedReason(reason);
            setAdditionalReasonState(additionalReason);
        }
    }, [isOpen, reason, additionalReason]);

    const handleSelectChange = (value) => {
        setSelectedReason(value);
        onSelectChange(value);
    };
    
    const handleTextChange = (e) => {
        setAdditionalReasonState(e.target.value);
        onTextChange(e);
    };

    const handleSet = () => {
        onSet(selectedReason, additionalReasonState);
    };

    const children = (
        <div className={styles.modalContent}>
            <button className={styles.modalCloseBtn} onClick={onClose}>x</button>
            <div className={styles.modalHeaderContainer}>
                <div className={styles.modalHeader}>
                    Profile Correction Request
                </div>
            </div>
            <div className={styles.modalInfo}>
                Some of the profile information in your account request does not match our records. Please correct and resubmit your profile.
            </div>
            <div className={styles.modalForm}>
                <div className={styles.select}>
                    <SelectBox 
                        placeholder={"-No Reason Selected-"} 
                        options={selectOptions} 
                        onChange={handleSelectChange}
                        selected={selectedReason}
                    />
                </div>
                <div className={styles.input}>
                    <InputBox 
                        placeholder={"Additional Information"} 
                        onChange={handleTextChange} 
                        value={additionalReasonState}
                    />
                </div>
                <div className={styles.button}>
                    <Button text={"Set"} onClick={handleSet}/>
                </div>
            </div>
        </div>
    );

    return (
        <div>
            <Modal isOpen={isOpen} children={children} modalClassName={styles.modal}/>
        </div>
    )
}

export default DashboardProfileCorrectionModal;

DashboardProfileCorrectionModal.propTypes = {
    onSet: PropTypes.func,
    onSelectChange: PropTypes.func,
    selectOptions: PropTypes.arrayOf(PropTypes.string),
    onTextChange: PropTypes.func
}