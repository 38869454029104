import React from "react";
import { useState, useEffect } from "react";
import styles from "./SiteRequirements.module.css";
import env from "../../../../environment";
import { HeaderSection, LinkItem } from "@cpnw/ui";
import DashboardSelect from "../../../../components/DashboardSelect";
import { CpnwContainer } from "../../../../hooks/useCpnw";

export default function SiteRequirements() {
  const { siteRquirementsData, refetchSiteRequirements } = CpnwContainer.useContainer()
  const [data, setData] = useState([])
  //dashboard select state
  const [selectValue, setSelectValue] = useState(null);
  const [complianceInfo, setComplianceInfo] = useState(null);
  // API Call on mount
  useEffect(()=>{
    refetchSiteRequirements()
  }, [])
  useEffect(() => {
    if (siteRquirementsData && siteRquirementsData.length) {
      const updatedData = siteRquirementsData.map((item) => item.facilityName);
      setData(updatedData);
      return
    }
    setData([])
  }, [siteRquirementsData])

  const handleSelectChange = (value) => {
    //dashboard select callback function state management
    setSelectValue(value);
    if (value) {
      const complianceInfo = siteRquirementsData.filter((item) => item.facilityName === value).map((item) => item.complianceInfo);
      setComplianceInfo(complianceInfo);
    }

  };

  //this is here to visualize the change in state for the selectValue
  //Content to be rendered based on this value
  useEffect(() => {
    console.log(selectValue);
    //render specific select page content
  }, [selectValue]);

  return (
    <section>
      <HeaderSection header={"Site Requirements"} />
      <h3 className={styles.site_requirements_subheader}>
        Look up and complete additional requirements.
      </h3>

      <div className={styles.site_requirements_content}>
        <div className={styles.site_requirements_col}>
          <p>
            Many participating clinical sites have additional health and safety
            requirements unique to their facility. It is your responsibility to
            look up and complete any additional requirements at the location you
            are assigned.
          </p>
          {/* link item here - however there seems to be an error on the import and use of LinkItem, as well as there is no need for a unordered list, or a key because its just one link. Unless the goal is to add more at a later date*/}
          {/* <ul>
                <LinkItem label="test-label" src="#"/>
                <LinkItem key={1} label="test-label" src="#"/>
                <LinkItem key={"test"} label="test-label" src="#"/>
            </ul> */}
          <p className={styles.site_requirements_cpr_header}>
            For AHA CPR Renewal:
            <br />
            <a
              className={styles.site_requirements_cpr_link}
              href="https://cpr.heart.org/en/course-catalog-search"
            >
              Click Here
            </a>
          </p>
        </div>
        <div className={styles.site_requirements_col}>
          <p>
            Use the dropdown below to select where you will be completing your
            clinical education experience. If there are additional requirements
            they will be listed.
          </p>
          <DashboardSelect
            placeholder={"- Select Clinical Site -"}
            options={data ? data : [
              // "CPNW HEALTHCARE",
              // "CPNW MEDICAL CENTER",
              // "EASTERN STATE HOSPITAL",
              // "EVERGREENHEALTH",
              // "HARBORVIEW MEDICAL CENTER",
              // "HARBORVIEW MEDICAL CENTER - AACS",
              // "KOOTENAI HEALTH",
              // "KOOTENAI HEALTH CLINICS",
              // "MADIGAN ARMY MEDICAL CENTER",
              // "MULTICARE ADULT DAY HEALTH",
              // "MULTICARE ALLENMORE HOSPITAL",
              // "MULTICARE AUBURN MEDICAL CENTER",
              // "MULTICARE BEHAVIORAL HEALTH",
              // "MULTICARE CAPITAL MEDICAL CENTER",
              // "MULTICARE CHILDRENS THERAPY UNIT",
              // "MULTICARE CLINICS",
              // "MULTICARE COVINGTON MEDICAL CENTER",
              // "MULTICARE DEACONESS HOSPITAL",
              // "MULTICARE GOOD SAMARITAN HOSPITAL",
              // "MULTICARE HOME HEALTH AND HOSPICE",
              // "MULTICARE INDIGO HEALTH",
              // "MULTICARE MARY BRIDGE CHILDRENS HOSPITAL",
              // "MULTICARE NAVOS",
              // "MULTICARE ROCKWOOD CLINICS",
              // "MULTICARE TACOMA GENERAL HOSPITAL",
              // "ODESSA MEMORIAL HEALTHCARE CENTER",
              // "OVERLAKE HOSPITAL MEDICAL CENTER",
              // "PROVIDENCE CENTRALIA HOSPITAL",
              // "PROVIDENCE HOLY FAMILY HOSPITAL",
              // "PROVIDENCE MEDICAL GROUP EAST",
              // "PROVIDENCE MEDICAL GROUP SW",
              // "PROVIDENCE MT. CARMEL HOSPITAL",
              // "PROVIDENCE REGINAL MEDICAL CENTER EVERETT",
              // "PROVIDENCE SACRED HEART MEDICAL CENTER",
              // "PROVIDENCE ST PETER HOSPITAL",
              // "PROVIDENCE ST. JOSEPH'S HOSPITAL",
              // "ST LUKE'S REHABILITATION INSTITUTE",
              // "SWEDISH CANCER INSTITUTE",
              // "SWEDISH MEDICAL CENTER - BALLARD",
              // "SWEDISH MEDICAL CENTER - FIRST HILL",
              // "SWEDISH MEDICAL CENTER - REDMOND",
              // "SWEDISH MEDICAL CENTER - CHERRY HILL",
              // "SWEDISH MEDICAL CENTER - EDMONDS",
              // "SWEDISH MEDICAL GROUP",
              // "SWEDISH MEDICAL CENTER - ISSAQUAH",
              // "SWEDISH MEDICAL CENTER - MILL CREEK",
              // "UNIVERSITY WASHINGTON MEDICAL CENTER-NORTHWEST",
              // "VA PUGET SOUND AMERICAN LAKE",
              // "VA PUGET SOUND SEATTLE",
              // "VALLEY MEDICAL CENTER",
              // "VETERANS HOSPITAL-SPOKANE",
              // "VIRGINIA MASON MEDICAL CENTER",
              // "VMFH HOSPICE HOUSE",
              // "VMFH MEDICAL GROUP CLINICS",
              // "VMFH ST ANNE HOSPITAL",
              // "VMFH ST ANTHONY HOSPITAL",
              // "VMFH ST CLARE HOSPITAL",
              // "VMFH ST ELIZABETH HOSPITAL",
              // "VMFH ST FRANCIS HOSPITAL",
              // "VMFH ST JOSEPH MEDICAL CENTER",
              // "VMFH ST MICHAEL MEDICAL CENTER",
              // "WESTERN STATE HOSPITAL",
            ]}
            tip={
              "Select the location where you have been assigned for clinical."
            }
            selected={null}
            onSelect={(e) => handleSelectChange(e)}
          />

          {/* For now this is just a placeholder for section that will hold the data based on the location selected in dashboard-select */}
          {/* This needs to change to match the logic based on what I am assuming will be data within the API */}
          {(selectValue != null && complianceInfo != null) ? (
            <div dangerouslySetInnerHTML={{ __html: complianceInfo }} />
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </section>
  );
}

