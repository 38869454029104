import React, { useCallback, useRef, useState } from "react";
import PropTypes from "prop-types";
import styles from "./ImmunizationHepBView.module.css";

import { AddButton, InputBox } from "@cpnw/ui";
import DashboardRadio from "components/DashboardRadio";
import FormDatePicker from "components/FormDatePicker";

const HepBSubmissionTypes = {
  A: "A",
  B: "B",
  C: "C",
  D: "D",
  Military: "Military",
};

const HepBSubmissionTypeDescriptions = {
  A: "A. Proof of immunity (after 2 or 3 step series) by Titer (anti-HBs or HepB Sab are ONLY accepted titers)",
  B: "B. Signed Series in Process",
  C: "C. History of disease. For those with history of disease, upload associated provider documentation",
  D: "D. Non-converter. For those who are Non-converters, outline the completion of 2 series types, with proper titers drawn, indicating ongoing Negative results.",
  Military: "Military immunization",
};

const HepBTestResults = {
  Neg: "Neg",
  Pos: "Pos",
};

const Series = {
  TwoStep: "2-Step",
  ThreeStep: "3-Step",
};

export default function ImmunizationHepBView({
  data = {},
  onChange,
  isReviewing = false,
  disabled = false
}) {
  const setValue = (key, value) => {
    if (key) {
      data[key] = value;
    }

    if (onChange) {
      onChange({ ...data });
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div>
          {(!isReviewing || (isReviewing && data.type === HepBSubmissionTypes.Military)) && (
            <DashboardRadio
              isSelected={data.type === HepBSubmissionTypes.Military}
              onChange={(value) => {
                setValue("type", value);
              }}
              value={HepBSubmissionTypes.Military}
              label={HepBSubmissionTypeDescriptions.Military}
              containerClassName={styles.radioContainer}
              disabled={disabled}
            />
          )}
        </div>
        <div>
          {(!isReviewing || (isReviewing && data.type === HepBSubmissionTypes.A)) && (
            <DashboardRadio
              isSelected={data.type === HepBSubmissionTypes.A}
              onChange={(value) => {
                setValue("type", value);
              }}
              value={HepBSubmissionTypes.A}
              label={HepBSubmissionTypeDescriptions.A}
              containerClassName={styles.radioContainer}
              disabled={disabled}
            />
          )}
          {data.type === HepBSubmissionTypes.A && (
            <div className={styles.testRow}>
              <div className={styles.testItem}>
                <label>Date:</label>
                <div className={styles.testItem2}>
                  <FormDatePicker
                    dateFormat="MM/dd/yyyy"
                    dateString={data.date}
                    setDate={(value) => {
                      data.date = value;
                      setValue();
                    }}
                    disabled={disabled}
                  />
                </div>
              </div>
              <div className={styles.testItem}>
                <label>Result:</label>
                <div className={styles.testItem2}>
                  <DashboardRadio
                    isSelected={data.result === HepBTestResults.Neg}
                    onChange={(value) => {
                      data.result = value;
                      setValue();
                    }}
                    value={HepBTestResults.Neg}
                    label={HepBTestResults.Neg}
                    containerClassName={styles.radioContainer}
                    disabled={disabled}
                  />
                  <DashboardRadio
                    isSelected={data.result === HepBTestResults.Pos}
                    onChange={(value) => {
                      data.result = value;
                      setValue();
                    }}
                    value={HepBTestResults.Pos}
                    label={HepBTestResults.Pos}
                    containerClassName={styles.radioContainer}
                    disabled={disabled}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <div>
          {(!isReviewing || (isReviewing && data.type === HepBSubmissionTypes.B)) && (
            <DashboardRadio
              isSelected={data.type === HepBSubmissionTypes.B}
              onChange={(value) => {
                setValue("type", value);
              }}
              value={HepBSubmissionTypes.B}
              label={HepBSubmissionTypeDescriptions.B}
              containerClassName={styles.radioContainer}
              disabled={disabled}
            />
          )}
          {data.type === HepBSubmissionTypes.B && (
            <div className={styles.testRow}>
              <div className={styles.testItem}>
                <label>Date:</label>
                <div className={styles.testItem2}>
                  <FormDatePicker
                    dateFormat="MM/dd/yyyy"
                    dateString={data.date}
                    setDate={(value) => {
                      data.date = value;
                      setValue();
                    }}
                    disabled={disabled}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <div>
          {(!isReviewing || (isReviewing && data.type === HepBSubmissionTypes.C)) && (
            <DashboardRadio
              isSelected={data.type === HepBSubmissionTypes.C}
              onChange={(value) => {
                setValue("type", value);
              }}
              value={HepBSubmissionTypes.C}
              label={HepBSubmissionTypeDescriptions.C}
              containerClassName={styles.radioContainer}
              disabled={disabled}
            />
          )}
        </div>
        <div>
          {(!isReviewing || (isReviewing && data.type === HepBSubmissionTypes.D)) && (
            <DashboardRadio
              isSelected={data.type === HepBSubmissionTypes.D}
              onChange={(value) => {
                setValue("type", value);
              }}
              value={HepBSubmissionTypes.D}
              label={HepBSubmissionTypeDescriptions.D}
              containerClassName={styles.radioContainer}
              disabled={disabled}
            />
          )}
          {data.type === HepBSubmissionTypes.D && (
            <div className={styles.testColumn}>
              <div className={styles.testColumn}>
                <label>
                  <strong>Series #1</strong>
                </label>
                <div>
                  <DashboardRadio
                    isSelected={data.serie1.type === Series.TwoStep}
                    onChange={(value) => {
                      data.serie1.type = value;
                      setValue();
                    }}
                    value={Series.TwoStep}
                    label={Series.TwoStep}
                    containerClassName={styles.radioContainer}
                    disabled={disabled}
                  />
                  {data.serie1.type === Series.TwoStep && (
                    <div className={styles.testColumn}>
                      <label>Vacination dates</label>
                      <div className={styles.testRow}>
                        1.{" "}
                        <FormDatePicker
                          dateFormat="MM/dd/yyyy"
                          dateString={data.serie1.date1}
                          setDate={(value) => {
                            data.serie1.date1 = value;
                            setValue();
                          }}
                          disabled={disabled}
                        />
                      </div>
                      <div className={styles.testRow}>
                        2.{" "}
                        <FormDatePicker
                          dateFormat="MM/dd/yyyy"
                          dateString={data.serie1.date2}
                          setDate={(value) => {
                            data.serie1.date2 = value;
                            setValue();
                          }}
                          disabled={disabled}
                        />
                      </div>
                      <label>Titer</label>
                      <div className={styles.testRow}>
                        <div className={styles.testItem}>
                          <label>Date Drawn:</label>
                          <div className={styles.testItem2}>
                            <FormDatePicker
                              dateFormat="MM/dd/yyyy"
                              dateString={data.serie1.dateDrawn}
                              setDate={(value) => {
                                data.serie1.dateDrawn = value;
                                setValue();
                              }}
                              disabled={disabled}
                            />
                          </div>
                        </div>
                        <div className={styles.testItem}>
                          <label>Result:</label>
                          <div className={styles.testItem2}>
                            <DashboardRadio
                              isSelected={
                                data.serie1.result === HepBTestResults.Neg
                              }
                              onChange={(value) => {
                                data.serie1.result = value;
                                setValue();
                              }}
                              value={HepBTestResults.Neg}
                              label={HepBTestResults.Neg}
                              containerClassName={styles.radioContainer}
                              disabled={disabled}
                            />
                            <DashboardRadio
                              isSelected={
                                data.serie1.result === HepBTestResults.Pos
                              }
                              onChange={(value) => {
                                data.serie1.result = value;
                                setValue();
                              }}
                              value={HepBTestResults.Pos}
                              label={HepBTestResults.Pos}
                              containerClassName={styles.radioContainer}
                              disabled={disabled}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div>
                  <DashboardRadio
                    isSelected={data.serie1.type === Series.ThreeStep}
                    onChange={(value) => {
                      data.serie1.type = value;
                      setValue();
                    }}
                    value={Series.ThreeStep}
                    label={Series.ThreeStep}
                    containerClassName={styles.radioContainer}
                    disabled={disabled}
                  />
                  {data.serie1.type === Series.ThreeStep && (
                    <div className={styles.testColumn}>
                      <label>Vacination dates</label>
                      <div className={styles.testRow}>
                        1.{" "}
                        <FormDatePicker
                          dateFormat="MM/dd/yyyy"
                          dateString={data.serie1.date1}
                          setDate={(value) => {
                            data.serie1.date1 = value;
                            setValue();
                          }}
                          disabled={disabled}
                        />
                      </div>
                      <div className={styles.testRow}>
                        2.{" "}
                        <FormDatePicker
                          dateFormat="MM/dd/yyyy"
                          dateString={data.serie1.date2}
                          setDate={(value) => {
                            data.serie1.date2 = value;
                            setValue();
                          }}
                          disabled={disabled}
                        />
                      </div>
                      <div className={styles.testRow}>
                        3.{" "}
                        <FormDatePicker
                          dateFormat="MM/dd/yyyy"
                          dateString={data.serie1.date3}
                          setDate={(value) => {
                            data.serie1.date3 = value;
                            setValue();
                          }}
                          disabled={disabled}
                        />
                      </div>
                      <label>Titer</label>
                      <div className={styles.testRow}>
                        <div className={styles.testItem}>
                          <label>Date Drawn:</label>
                          <div className={styles.testItem2}>
                            <FormDatePicker
                              dateFormat="MM/dd/yyyy"
                              dateString={data.serie1.dateDrawn}
                              setDate={(value) => {
                                data.serie1.dateDrawn = value;
                                setValue();
                              }}
                              disabled={disabled}
                            />
                          </div>
                        </div>
                        <div className={styles.testItem}>
                          <label>Result:</label>
                          <div className={styles.testItem2}>
                            <DashboardRadio
                              isSelected={
                                data.serie1.result === HepBTestResults.Neg
                              }
                              onChange={(value) => {
                                data.serie1.result = value;
                                setValue();
                              }}
                              value={HepBTestResults.Neg}
                              label={HepBTestResults.Neg}
                              containerClassName={styles.radioContainer}
                              disabled={disabled}
                            />
                            <DashboardRadio
                              isSelected={
                                data.serie1.result === HepBTestResults.Pos
                              }
                              onChange={(value) => {
                                data.serie1.result = value;
                                setValue();
                              }}
                              value={HepBTestResults.Pos}
                              label={HepBTestResults.Pos}
                              containerClassName={styles.radioContainer}
                              disabled={disabled}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div>If titer is negative after series #1, then include series #2 information with date the titer was drawn and results</div>
              <div className={styles.testColumn}>
                <label>
                  <strong>Series #2</strong>
                </label>
                <div>
                  <DashboardRadio
                    isSelected={data.serie2.type === Series.TwoStep}
                    onChange={(value) => {
                      data.serie2.type = value;
                      setValue();
                    }}
                    value={Series.TwoStep}
                    label={Series.TwoStep}
                    containerClassName={styles.radioContainer}
                    disabled={disabled}
                  />
                  {data.serie2.type === Series.TwoStep && (
                    <div className={styles.testColumn}>
                      <label>Vacination dates</label>
                      <div className={styles.testRow}>
                        1.{" "}
                        <FormDatePicker
                          dateFormat="MM/dd/yyyy"
                          dateString={data.serie2.date1}
                          setDate={(value) => {
                            data.serie2.date1 = value;
                            setValue();
                          }}
                          disabled={disabled}
                        />
                      </div>
                      <div className={styles.testRow}>
                        2.{" "}
                        <FormDatePicker
                          dateFormat="MM/dd/yyyy"
                          dateString={data.serie2.date2}
                          setDate={(value) => {
                            data.serie2.date2 = value;
                            setValue();
                          }}
                          disabled={disabled}
                        />
                      </div>
                      <label>Titer</label>
                      <div className={styles.testRow}>
                        <div className={styles.testItem}>
                          <label>Date Drawn:</label>
                          <div className={styles.testItem2}>
                            <FormDatePicker
                              dateFormat="MM/dd/yyyy"
                              dateString={data.serie2.dateDrawn}
                              setDate={(value) => {
                                data.serie2.dateDrawn = value;
                                setValue();
                              }}
                              disabled={disabled}
                            />
                          </div>
                        </div>
                        <div className={styles.testItem}>
                          <label>Result:</label>
                          <div className={styles.testItem2}>
                            <DashboardRadio
                              isSelected={
                                data.serie2.result === HepBTestResults.Neg
                              }
                              onChange={(value) => {
                                data.serie2.result = value;
                                setValue();
                              }}
                              value={HepBTestResults.Neg}
                              label={HepBTestResults.Neg}
                              containerClassName={styles.radioContainer}
                              disabled={disabled}
                            />
                            <DashboardRadio
                              isSelected={
                                data.serie2.result === HepBTestResults.Pos
                              }
                              onChange={(value) => {
                                data.serie2.result = value;
                                setValue();
                              }}
                              value={HepBTestResults.Pos}
                              label={HepBTestResults.Pos}
                              containerClassName={styles.radioContainer}
                              disabled={disabled}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div>
                  <DashboardRadio
                    isSelected={data.serie2.type === Series.ThreeStep}
                    onChange={(value) => {
                      data.serie2.type = value;
                      setValue();
                    }}
                    value={Series.ThreeStep}
                    label={Series.ThreeStep}
                    containerClassName={styles.radioContainer}
                    disabled={disabled}
                  />
                  {data.serie2.type === Series.ThreeStep && (
                    <div className={styles.testColumn}>
                      <label>Vacination dates</label>
                      <div className={styles.testRow}>
                        1.{" "}
                        <FormDatePicker
                          dateFormat="MM/dd/yyyy"
                          dateString={data.serie2.date1}
                          setDate={(value) => {
                            data.serie2.date1 = value;
                            setValue();
                          }}
                          disabled={disabled}
                        />
                      </div>
                      <div className={styles.testRow}>
                        2.{" "}
                        <FormDatePicker
                          dateFormat="MM/dd/yyyy"
                          dateString={data.serie2.date2}
                          setDate={(value) => {
                            data.serie2.date2 = value;
                            setValue();
                          }}
                          disabled={disabled}
                        />
                      </div>
                      <div className={styles.testRow}>
                        3.{" "}
                        <FormDatePicker
                          dateFormat="MM/dd/yyyy"
                          dateString={data.serie2.date3}
                          setDate={(value) => {
                            data.serie2.date3 = value;
                            setValue();
                          }}
                          disabled={disabled}
                        />
                      </div>
                      <label>Titer</label>
                      <div className={styles.testRow}>
                        <div className={styles.testItem}>
                          <label>Date Drawn:</label>
                          <div className={styles.testItem2}>
                            <FormDatePicker
                              dateFormat="MM/dd/yyyy"
                              dateString={data.serie2.dateDrawn}
                              setDate={(value) => {
                                data.serie2.dateDrawn = value;
                                setValue();
                              }}
                              disabled={disabled}
                            />
                          </div>
                        </div>
                        <div className={styles.testItem}>
                          <label>Result:</label>
                          <div className={styles.testItem2}>
                            <DashboardRadio
                              isSelected={
                                data.serie2.result === HepBTestResults.Neg
                              }
                              onChange={(value) => {
                                data.serie2.result = value;
                                setValue();
                              }}
                              value={HepBTestResults.Neg}
                              label={HepBTestResults.Neg}
                              containerClassName={styles.radioContainer}
                              disabled={disabled}
                            />
                            <DashboardRadio
                              isSelected={
                                data.serie2.result === HepBTestResults.Pos
                              }
                              onChange={(value) => {
                                data.serie2.result = value;
                                setValue();
                              }}
                              value={HepBTestResults.Pos}
                              label={HepBTestResults.Pos}
                              containerClassName={styles.radioContainer}
                              disabled={disabled}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
