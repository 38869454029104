import React from "react";
import PropTypes from "prop-types";
import styles from "./DashboardClinicalAssignmentTable.module.css";
import DashboardClinicalAssignmentItem from "components/DashboardClinicalAssignmentItem";

const DashboardClinicalAssignmentTable = ({ assignments }) => {
  const columnWidths = ["40%", "30%", "30%"]; // Adjust widths as needed

  return (
    <div className={styles.assignmentTable}>
      <h2 className={styles.title}>My Clinical Assignments</h2>
      <div className={styles.table}>
        <div className={styles.header}>
          <div className={styles.headerCell} style={{ width: columnWidths[0] }}>
            Location
          </div>
          <div className={styles.headerCell} style={{ width: columnWidths[1] }}>
            Start Date
          </div>
          <div className={styles.headerCell} style={{ width: columnWidths[2] }}>
            End Date
          </div>
        </div>
        {assignments.map((assignment, index) => (
          <DashboardClinicalAssignmentItem
            key={index}
            assignment={assignment}
            columnWidths={columnWidths}
          />
        ))}
      </div>
    </div>
  );
};

DashboardClinicalAssignmentTable.propTypes = {
  assignments: PropTypes.arrayOf(
    PropTypes.shape({
      location: PropTypes.string.isRequired,
      startDate: PropTypes.string.isRequired,
      endDate: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default DashboardClinicalAssignmentTable;
