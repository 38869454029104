import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./MySecuritySettings.module.css";
import { InputBox, HeaderSection, SelectBox, Button } from "@cpnw/ui";
import DashboardPhoneNumberInput from "components/DashboardPhoneNumberInput";
import { CpnwContainer } from "../../../../hooks/useCpnw";
import { AccountContainer } from "hooks/useAccount";

const MySecuritySettings = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const passwordReg = /^(?=.*[A-Z])(?=.*[!@#$&*_?])(?=.*[0-9])(?=.*[a-z]).{10,}$/;

  const setFormPhoneNumberData = (value) => { 
    setFormData({...formData, PhoneNumber: value});
  };
  const [phoneNumberError, setPhoneNumberError] = useState(null);
  const [errors, setErrors] = useState({});
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const [SMSProviders, setSMSProviders] = useState([]);

  const [formData, setFormData] = useState({
    ContactIDPK: '',
    Email: '',
    Password: '',
    ConfirmPassword: '',
    MFAPreference: '',
    SelectedProvider: '',
    PhoneNumber: '',
  });

  const { SMSProvidersData, refetchSMSProviders } = CpnwContainer.useContainer()
  const { mySecuritySettingsData, refetchMySecuritySettings, updateSecuritySettings } = AccountContainer.useContainer()

  useEffect(()=>{
    refetchSMSProviders()
    refetchMySecuritySettings()
  }, [])

  useEffect(() => {
    if (mySecuritySettingsData) {
      setFormData(formData =>  ({
        ...formData,
        ContactIDPK: mySecuritySettingsData.ContactIDPK,
        Email: mySecuritySettingsData.Email,
        MFAPreference: mySecuritySettingsData.MFAPreference,
        SelectedProvider: mySecuritySettingsData.SMSCarrier,
        PhoneNumber: mySecuritySettingsData.PhoneNumber,
      }));
    }
  }, [mySecuritySettingsData])

  useEffect(() => {
    if (SMSProvidersData) {
      setSMSProviders(SMSProvidersData);
    }
  }, [SMSProvidersData]);

  const validateForm = () => {
    const newErrors = {
      ...validatePassword(),
      ...validateConfirmPassword(),
      ...validatePhoneNumber()
    }

    return newErrors;
  };

  const validatePhoneNumber = () => {
    let newErrors = { ...errors };
    if (phoneNumberError) {
      newErrors.phoneNumber = phoneNumberError;
    } else {
      newErrors.phoneNumber = '';
    }
    return newErrors;
  }

  const validatePassword = (password = null) => {
    if(password == null) {
      password = formData.Password;
    }

    let newErrors = { ...errors };
    if (password?.trim() && !passwordReg.test(password)) {
      newErrors.password = `Password must contain at least 10 characters including at least: an uppercase character, a lowercase character, a number, and a special character.`;
    } else {
      newErrors.password = '';
    }
    return newErrors;
  };

  const validateConfirmPassword = (confirmPassword, password = null) => {
    // Copy the existing errors
    let newErrors = { ...errors };

    // If password is not set, get it from formData
    if(!password) {
      password = formData.Password;
    }

    // If password is still not set, return the errors
    if(!password) { return newErrors; }

    // Check if password and confirmPassword match
    if (password !== confirmPassword) {
      newErrors.confirmPassword = `The password and confirmation password do not match.`;
    } else {
      // If they match, clear any previous error
      newErrors.confirmPassword = '';
    }

    // Return the updated errors
    return newErrors;
  }

  const onSubmitUpdateSettings = async (data) => {
    const response = await updateSecuritySettings(data);

    if(response?.success) {
      setShowSuccessMessage(true)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowSuccessMessage(false)
    let newErrors = validateForm();

    if (newErrors.password || newErrors.confirmPassword || newErrors.phoneNumber) {
      setErrors(newErrors);
    } else {
      //Add layer between body api and form view to map the data to the correct format in future
      const submitData = {
        ...formData
      };
      
      await onSubmitUpdateSettings(submitData);
    }
  };

  const Message = ({ showSuccessMessage }) => {
    return <>{showSuccessMessage && <p className={classNames(styles.success, styles.round, styles.label)}>Your Security Settings have been updated.</p>}</>
  };

  return (
    <section className={styles.mySecuritySettings}>
      <HeaderSection header={"My Security Settings"} />
      <div className={classNames(styles.row)}>
        <div className={classNames(styles.column)}></div>
      </div>
      <div className={classNames(styles.row)}>
        <div className={classNames(styles.column)}>
          <form onSubmit={handleSubmit} className={styles.mySecuritySettingsPage}>
            <div>
              <Message showSuccessMessage={showSuccessMessage} />
            </div>
            <div>
              <div className={classNames(styles.row)}>
                <div className={classNames(styles.column6, styles.column)}>
                  <InputBox
                    id="password"
                    placeholder={"Change Password"}
                    label={"Change Password"}
                    tip={"At least 10 characters including: upper and lowercase letters, a number and a special character."}
                    value={formData.Password}
                    type={'password'}
                    setValue={(e) => {
                      setFormData(prevFormData => ({...prevFormData, Password: e.target.value}))

                      const newErrors = {
                        ...errors,
                        ...validatePassword(e.target.value),
                        ...validateConfirmPassword(formData.ConfirmPassword, e.target.value)
                      };

                      setErrors(newErrors);
                    }}
                    onBlur={(e) => {
                      const trimmedValue = e.target.value?.trim();
                      const trimmedConfirmPassword = formData.ConfirmPassword?.trim();
                      
                      let newErrors = { ...errors };
                      
                      if (trimmedValue) {
                        newErrors = {
                          ...newErrors,
                          password: validatePassword(trimmedValue).password,
                          confirmPassword: trimmedConfirmPassword ? validateConfirmPassword(trimmedConfirmPassword).confirmPassword : newErrors.confirmPassword
                        };
                      } else {
                        newErrors = {
                          confirmPassword: trimmedConfirmPassword ? validateConfirmPassword(trimmedConfirmPassword).confirmPassword : newErrors.confirmPassword
                        };
                      }
                      
                      setErrors(newErrors);
                  }}
                  />
                  <small className={styles.error}>
                    <span className={styles.fieldValidationError}>{errors.password && <span>{errors.password}</span>}</span>
                  </small>
                </div>

                <div className={classNames(styles.column4, styles.column)}>
                  <InputBox
                    id="confirmPassword"
                    placeholder={"Confirm Password"}
                    label={"Confirm New Password"}
                    value={formData.ConfirmPassword}
                    type={'password'}
                    setValue={(e) => {
                      setFormData({...formData, ConfirmPassword: e.target.value});
                    }}
                    onBlur={(e) => {
                      const newErrors = validateConfirmPassword(e.target.value);
                      setErrors({ ...errors, ...newErrors });
                    }}
                  />
                  <small className={styles.error}>
                    <span className={styles.fieldValidationError}>{errors.confirmPassword && <span>{errors.confirmPassword}</span>}</span>
                  </small>
                </div>
              </div>
              <div className={classNames(styles.row)}>
                <div className={classNames(styles.column6, styles.column)}>
                  <SelectBox
                    id="mFAPreference"
                    label={'I prefer to receive my authentication code by:'}
                    placeholder={"Please Select"}
                    selected={formData.MFAPreference ?? ''}
                    onChange={(e) => {
                      setFormData({...formData, MFAPreference: e});
                      setErrors({ ...errors });
                    }}
                    options={['Email', 'Text Message']}
                  />
                  <small className={styles.error}>
                    <span className={styles.fieldValidationError}>{errors.mFAPreference && <span>{errors.mFAPreference}</span>}</span>
                  </small>
                  {errors.mFAPreferenceAlert && <div className={classNames(styles.alertBox, styles.alert, styles.radius)} id="MFAalert" style={errors.mFAPreferenceAlert ? { display: 'block' } : { display: 'none' }}>{errors.mFAPreferenceAlert}</div>}

                </div>

                <div className={classNames(styles.column4, styles.column)}>
                  <DashboardPhoneNumberInput
                    id="phoneNumber"
                    placeholder={"xxx-xxx-xxxx"}
                    label={"Primary Text Phone Number"}
                    tip={"Enter your text-enabled phone number."}
                    value={formData.PhoneNumber}
                    autoComplete={"off"}
                    setValue={setFormPhoneNumberData}
                    setError={setPhoneNumberError}
                  />
                  <small className={styles.error}>
                    <span className={styles.fieldValidationError}>{phoneNumberError && <span>{phoneNumberError}</span>}</span>
                  </small>
                </div>
              </div>
              <div className={classNames(styles.row)}>
                <div className={classNames(styles.column6, styles.column)}>
                  <SelectBox
                    id="selectedProvider"
                    label={'Select Cell Carrier'}
                    placeholder={"-No Cell Phone-"}
                    tip={"Select your cellular provider."}
                    selected={formData.SelectedProvider ?? ''}
                    onChange={(e) => {
                      setFormData({...formData, SelectedProvider: e});
                      setErrors({ ...errors });
                    }}
                    options={SMSProviders?.map((provider) => provider.carrier) ?? []}
                  />
                  <small className={styles.error}>
                    <span className={styles.fieldValidationError}>{errors.selectedSMSCarrier && <span>{errors.selectedSMSCarrier}</span>}</span>
                  </small>
                </div>
                <div className={classNames(styles.column4, styles.column)}>
                </div>
              </div>
              <div className={classNames(styles.row)}>
                <div className={classNames(styles.column)}>
                  <hr />
                </div>
              </div>
              <div className={classNames(styles.row)}>
                <div className={classNames(styles.column6, styles.column)}>
                  <Button type="submit" id="submit" onClick={(e) => handleSubmit(e)} text={"UPDATE"} className={classNames(styles.button)} />
                </div>
                <div className={classNames(styles.column4, styles.column)}>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

MySecuritySettings.propTypes = {

};

export default MySecuritySettings;
