import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./DashboardPageTabs.module.css";

const DashboardPageTabs = ({ titleList, activeTitle, onChange }) => {
  return (
    <div className={styles.container}>
      <ul className={styles.tabList}>
        {titleList.map((tab) => (
          <li
            key={tab}
            className={classNames([styles.tabItem], {
              [styles.active]: activeTitle === tab,
            })}
          >
            <a onClick={() => onChange(tab)}>{tab}</a>
          </li>
        ))}
      </ul>
    </div>
  );
};

DashboardPageTabs.propTypes = {
  titleList: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeTitle: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default DashboardPageTabs;
