import React from 'react';
import PropTypes from "prop-types"
import styles from "./DashboardResultsItem.module.css";

export default function DashboardResultsItem({subject, status, date}){

return (
    <>
        <div className={styles.results_row}>
            <div className={styles.subject_col}>
                {subject}
            </div>
            <div className={styles.status_col}>
                {status}
            </div>
            <div className={styles.date_col}>
                {date}
            </div>
        </div>
    </>
);
};

DashboardResultsItem.propTypes = {
    subject: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
}
