import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from "./DashboardNoticeList.module.css";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

export default function DashboardNoticeList({items, type, expirationTip, onClick = (item) => {console.log("onClick", item)}}){

return (
    <div className={styles.notice_container}>
        <div className={classNames(styles.notice_bar, {[styles.requirement_color]: type === 1, [styles.expiration_color]: type === 2, [styles.system_color]: type === 3})}>
            {type === 1 && <p>Requirement Notices</p>}
            {type === 2 && <p>Expiration Notices</p>}
            {type === 3 && <p>System Notices</p>}
        </div>
        <div className={styles.notice_list}>
            {expirationTip && <p>{expirationTip}</p>}
            {items.map((item, index) => {
                return <div key={index} onClick={() => onClick(item)}>
                    <div className={styles.notice_link}>{item.Content}</div>
                </div>
            })}
        </div>
    </div>
);
};

DashboardNoticeList.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            description: PropTypes.string.isRequired,
            link: PropTypes.string.isRequired
        })
    ).isRequired,
    type: PropTypes.oneOf([1, 2, 3]).isRequired,
    //subheader for optional expirtaion notices tip
    expirationTip: PropTypes.string
}
// 1 - requirement
// 2 - expiration
// 3 - system