const DashboardPassportItemFields = {
  assignmentId: "assignmentId",
  assignmentStatus: "assignmentStatus",
  nameOfContact: "contact",
  passport: "pp",
  learningModule: "lm",
  oig: "oig",
  sam: "sam",
  userContactType: "userType",
};

export default DashboardPassportItemFields;
