import React, { useEffect, useState } from "react";
import environment from "environment";
import {Button, CheckboxItem} from "@cpnw/ui"
import Preloader from "../../images/preloader.gif";
import styles from "./ConsentPage.module.css";
import { AccountContainer } from "hooks/useAccount";
import { StaticRoutes } from "pages/routes";

export function ConsentPage() {
  const { accountConsentGet, accountConsentPost } =
    AccountContainer.useContainer();
  const [loading, setLoading] = useState(true);
  const [agreed, setAgreed] = useState(false);
  const [error, setError] = useState(false);

  useEffect(
    () =>
      accountConsentGet().then((m) => {
        if (m && m.consented) {
          goToHome();
          return;
        }

        setLoading(false);
      }),
    []
  );

  const onSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    if (agreed) {
      let result = await accountConsentPost(true);

      if (result && result.success) {
        goToProfile();
        return;
      }
    }
    setLoading(false);
    setError(true);
  };

  const goToHome = () => {
    window.location.href = StaticRoutes.MyDashboard
  }

  const goToProfile = () => {
    // window.location.href = environment.dashboardAppUrl
    window.location.href = StaticRoutes.AccountRegistrationProfile
  };

  return !agreed && loading ? (
    <img className={styles.loading} src={Preloader} height={35} width={35} />
  ) : (
    <div className={styles.container}>
      <h1>Release</h1>
      <h2>Consent Form and Student Self-Pay Refund Policy</h2>
      <p>
        Effective upon submission of this Consent for Disclosure of Personally
        Identifiable Information (“Consent”), I hereby authorize Clinical
        Placement Consortium #1, North Puget Sound Clinical Placement
        Consortium, and Inland Northwest Clinical Placements Consortia
        (collectively referred to as “CPNW”) to disclose and provide my
        personally identifiable information as provided in this Consent.
      </p>
      <p>
        The personally identifiable information subject to this Consent (“PII”)
        includes any and all personally identifiable information I have provided
        to CPNW. My personally identifiable information may include my contact,
        identification, and demographic information; my educational
        affiliations, including but not limited to the educational institutions’
        verification of such affiliations; copies of documentation relating to
        my immunizations; history of infectious diseases; titer levels;
        background checks; personal identity, including but not limited to
        social security number; nursing skills check-off lists; results of
        health training modules on such topics as compliance requirements,
        patient confidentiality, HIV/AIDS, and universal infectious disease
        precautions; and other records and documentation which may include other
        personally identifiable, sensitive information that I have provided to
        CPNW.
      </p>
      <p>
        I authorize CPNW to disclose this personally identifiable information
        upon request to:
      </p>
      <ul>
        <li>
          The healthcare educational institution with which I am associated for
          purposes of the services that CPNW is providing to me (“School”), for
          purposes of verifying my PII; and
        </li>
        <li>
          Any healthcare organization which has an agreement with CPNW
          authorizing them to obtain such information for clinical placement
          purposes (“Affiliated Organizations”), for clinical placement
          purposes.
        </li>
      </ul>
      <p>
        I understand I have the right to revoke this authorization by electronic
        request to CPNW at cpnw@cpnw.org. I understand the revocation will not
        apply to information that has already been released in response to this
        authorization.
      </p>
      <p>
        I further understand that once the PII I have authorized to be disclosed
        reaches the recipient, that person or organization may use or
        re-disclose it, and that CPNW has no control over or responsibility for
        such use or disclosure.
      </p>
      <p>
        By completing the following information and submitting this form to
        CPNW, I acknowledge that I have been informed of and consent to the
        terms and conditions of this Consent, and that CPNW will rely upon this
        Consent in disclosing my PII.
      </p>
      <h3>REFUND POLICY FOR STUDENT SELF PAY AND PROGRAM PAY</h3>
      <h4>Statement</h4>
      <p>
        CPNW understands circumstances arise which may interfere in the
        student’s preparation, decision to pursue or actual delivery of a
        clinical experience. If certain qualifications are met, CPNW can refund
        and close a student’s account and/or provide an adjustment to the
        programs invoice.
      </p>
      <ol>
        <li>
          CPNW provides on-boarding services to students prior to their clinical
          learning experience via a web-based application.
        </li>
        <li>
          Payment for services is collected at the time of registration for a
          student self-pay account.
        </li>
        <li>
          Services are rendered after student establishes a registered account
          with CPNW.
        </li>
      </ol>
      <h4>Conditions</h4>
      <p>
        A student or program is qualified to receive a refund or invoice
        adjustment of the CPNW account registration fee if the student has not
        received any CPNW Services. This includes but is not limited to
        eLearning Modules, Clinical Passport completion, or requested placement
        approval to a healthcare facility. The student self-pay refund must be
        requested within 30 days from payment. A program pay invoice adjustment
        must be requested prior to payment.
      </p>
      <h4>Procedure</h4>
      <ol>
        <li>
          The student and/or education program will contact{" "}
          <a href="mailto:support@cpnw.org">support@cpnw.org</a> to request a
          refund or invoice adjustment, stating reason for refund.
        </li>
        <li>Request will be submitted to the Executive Director for review.</li>
        <li>
          CPNW IT Support will provide the following necessary program and/or
          account information to the Executive Director for review. For example,
          Student Self Pay will require the following:
          <ul>
            <li>Username</li>
            <li>Transaction amount</li>
            <li>Transaction date</li>
            <li>PayPal transaction ID</li>
            <li>CPNW transaction ID</li>
          </ul>
        </li>
        <li>
          If refund conditions are satisfied, Executive Director will forward
          all information to the CPNW Treasurer to issue the refund or invoice
          adjustment.
        </li>
        <li>
          Self-pay refunds will be made to students within 7 business days upon
          Executive Director approval.
        </li>
        <li>
          Invoice Adjustments will be made to the program within 14 business
          days upon Executive Director approval.
        </li>
        <li>
          All student self-pay refund transactions will be assessed a 15%
          processing fee.
        </li>
        <li>Student account will be closed and archived.</li>
      </ol>
      <form>
        <div style={{ display: "table" }}>
          <CheckboxItem
            className={styles.checkbox}
            onClick={(isChecked) => setAgreed(!!isChecked)}
            description={"I AGREE"}
            isChecked={agreed}
          />
        </div>
        {error && <h5>Error or Already Consented</h5>}
        <Button
          text={loading ? "RECORD CONSENT ..." : "RECORD CONSENT"}
          onClick={(e) => onSubmit(e)}
          disabled={!agreed || loading}
        />
      </form>
    </div>
  );
}

export default ConsentPage;
