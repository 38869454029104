import React, {useState, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './PassportStudentInfo.module.css';

export default function PassportStudentInfo({student}){
    //tooltip hover state
    const [tooltip, setTooltip] = useState(false)
    //trunication state
    const [isTextTruncated, setIsTextTruncated] = useState(false);
    //ref for email (only value that gets trunicated)
    const emailRef = useRef(null);

    useEffect(() => {
        //on mount call function to check if trunication is present
        checkTextTruncation();
    }, []);

    const checkTextTruncation = () => {
        if(emailRef.current){
            setIsTextTruncated(emailRef.current.scrollWidth > emailRef.current.clientWidth);
        }
    };
    
return (
    <>
        <div className={styles.student_info}>
            <div className={styles.header}>
                <h3>{student.lastName}, {student.firstName}</h3>
            </div>

            <div className={styles.info_row}>
                <div className={styles.info_item}>
                    <p>School/Campus:</p>
                    <strong className={styles.info_data}>{student.school}</strong>
                </div>

                <div className={styles.info_item}>
                    <p>Program:</p>
                    <strong className={styles.info_data}>{student.program}</strong>
                </div>

                <div className={styles.info_item}>
                    <p>SID/EID:</p>
                    <strong className={styles.info_data}>{student.sidEid}</strong>
                </div>

                <div className={classNames(styles.info_item, styles.info_email, styles.tooltip_container)} onMouseEnter={() => isTextTruncated && setTooltip(true)} onMouseLeave={() => setTooltip(false)}>
                    <p>Email:</p>
                    <strong className={styles.elip_data} ref={emailRef}>{student.email}</strong>

                    <div className={classNames( tooltip ? styles.active : styles.hidden)}>
                        {student.email}
                    </div>
                </div>

                <div className={styles.info_item}>
                    <p>Contact Phone:</p>
                    <strong className={styles.info_data}>{student.phone}</strong>
                </div>

                <div className={styles.info_item}>
                    <p>Emergency Contact Name:</p>
                    <strong className={styles.info_data}>{student.eContact}</strong>
                </div>

                <div className={styles.info_item}>
                    <p>Emergency Contact Phone:</p>
                    <strong className={styles.info_data}>{student.eContactPhone}</strong>
                </div>
            </div>
        </div>
    </>
);
};

PassportStudentInfo.propTypes = {
    student: PropTypes.shape({
        lastName: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        school: PropTypes.string.isRequired,
        program: PropTypes.string.isRequired,
        sidEid: PropTypes.number.isRequired,
        email: PropTypes.string.isRequired,
        phone: PropTypes.string.isRequired,
        //emergency contact
        eContact: PropTypes.string.isRequired,
        eContactPhone: PropTypes.string.isRequired
    })
}