import React from 'react';
import PropTypes from "prop-types";

import styles from "./DashboardRadio.module.css";
import classNames from 'classnames';

export default function DashboardRadio({isSelected, onChange, value, label, disabled = false, containerClassName}){

return (
    <div className={classNames(styles.container, containerClassName)}>
      <input
        type="radio"
        id={value}
        checked={isSelected}
        onChange={() => onChange(value)}
        value={value}
        disabled={disabled}
      />
      {label && <label className={styles.radio_label} htmlFor={value}>{label}</label>}
    </div>
);
};

DashboardRadio.propTypes = {
    isSelected: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.string
}