import { Auth } from "auth";
import environment from "environment";
import { createContainer } from "unstated-next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  makeAuthedRequest,
  makeAuthedMultipartRequest,
} from "utils/makeAuthedRequest";

export function useCpnw() {
  /*
    Sample data:
    [
      {
          "facilityIDPK": 357,
          "facilityName": "CPNW HEALTHCARE"
      },
      {
          "facilityIDPK": 620,
          "facilityName": "CPNW MEDICAL CENTER"
      }
    ]
    */
  const queryClient = useQueryClient();

  const { data: siteRquirementsData, refetch: refetchSiteRequirements } =
    useQuery(
      ["SiteRequirementsGet"],
      async () =>
        await makeAuthedRequest(`/cpnw/SiteRequirements`, {
          method: "GET",
        }).then(async (res) => {
          if (res.ok) {
            return res.json();
          } else {
            const body = await res.json();
            throw new Error(body.message);
          }
        }),
      {
        enabled: false,
      }
    );

  const { data: learningModulesData, refetch: refetchLearningModules } =
    useQuery(
      ["LearningModulesGet"],
      async () =>
        await makeAuthedRequest(`/cpnw/LearningModules`, {
          method: "GET",
        }).then(async (res) => {
          if (res.ok) {
            return res.json();
          } else {
            const body = await res.json();
            throw new Error(body.message);
          }
        }),
        {
          enabled: false
        }
    );

  const { data: noticeLatestData, refetch: refetchNoticeLatest } = useQuery(
    ["NoticeLatestGet"],
    async () =>
      await makeAuthedRequest(`/cpnw/Notice/Latest`, {
        method: "GET",
      }).then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const body = await res.json();
          throw new Error(body.message);
        }
      }),
    {
      enabled: false,
    }
  );

  const takeQuiz = async (id) =>
    await makeAuthedRequest(`/cpnw/TakeQuiz?id=${id}`, {
      method: "GET",
    }).then(async (res) => {
      if (res.ok) {
        return res.json();
      } else {
        const body = await res.json();
        throw new Error(body.message);
      }
    });

  const { data: SMSProvidersData, refetch: refetchSMSProviders } = useQuery(
    ["SMSProvidersGet"],
    async () => {
      const res = await makeAuthedRequest(`/cpnw/SMSProviders`, {
        method: "GET",
      });

      if (res.ok) {
        const result = await res.json();
        return result;
      } else {
        const body = await res.json();
        throw new Error(body.message);
      }
    },
    {
      enabled: false,
    }
  );

  const { data: reportData, refetch: reportRefetch } = useQuery(
    ["ReportGet"],
    async () =>
      await makeAuthedRequest(`/cpnw/report`, {
        method: "GET",
      }).then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const body = await res.json();
          throw new Error(body.message);
        }
      }),
    {
      enabled: false, // Prevent automatic fetching
    }
  );

  const getExclusionReports = async (businessUnitId, dateRun) => {
    const res = await makeAuthedRequest(`/cpnw/ExclusionReport`, {
      method: "POST",
      body: JSON.stringify({
        businessUnitId: Number(businessUnitId),
        dateRun: dateRun,
      }),
    });

    if (res.ok) return res.json();

    const body = await res.json();
    throw new Error(body.message);
  };

  const getExclusionFilters = async () => {
    const res = await makeAuthedRequest(`/cpnw/Exclusions/Filters`, {
      method: "GET",
    });

    if (res.ok) return res.json();

    const body = await res.json();
    throw new Error(body.message);
  };

  const getPassports = async (studentContactId) => {
    const res = await makeAuthedRequest(
      `/cpnw/ContactPassport?id=${studentContactId}`,
      {
        method: "GET",
      }
    );

    if (res.ok) return res.json();

    const body = await res.json();
    throw new Error(body.message);
  };

  const deletePassports = async (studentContactId, fileName) => {
    const res = await makeAuthedRequest(`/cpnw/PassportDelete`, {
      method: "POST",
      body: JSON.stringify({
        contactId: studentContactId,
        fileName: fileName,
      }),
    });

    if (res.ok) return res.json();

    const body = await res.json();
    throw new Error(body.message);
  };

  const uploadPassports = async (studentContactId, files) => {
    var form = new FormData();
    form.append("contactId", studentContactId);
    for (const file of files) form.append("files", file);

    const res = await makeAuthedMultipartRequest(`/cpnw/PassportUpload`, form, {method: "POST"});

    if (res.ok) return res.json();

    const body = await res.json();
    throw new Error(body.message);
  };

  const downloadPassports = async (studentContactId, fileNames) => {
    const res = await makeAuthedRequest(`/cpnw/PassportDownload`, {
      method: "POST",
      body: JSON.stringify({
        contactId: studentContactId,
        fileNames: fileNames,
      }),
    });

    if (res.ok) {
      let blob = await res.blob();
      let blobUrl = window.URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", blobUrl.split("/").pop());
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      return;
    }

    const body = await res.json();
    throw new Error(body.message);
  };

  const assignmentAccept = async (assignmentId) => {
    const res = await makeAuthedRequest(`/cpnw/AssignmentAccept`, {
      method: "POST",
      body: JSON.stringify({ aid: assignmentId }),
    });

    if (res.ok) {
      reportRefetch();
      return res.json();
    } else {
      const body = await res.json();
      throw new Error(body.message);
    }
  };

  const assignmentReject = async (assignmentId, rejectReason) => {
    const res = await makeAuthedRequest(`/cpnw/AssignmentReject`, {
      method: "POST",
      body: JSON.stringify({ aid: assignmentId, reason: rejectReason }),
    });

    if (res.ok) {
      reportRefetch();
      return res.json();
    } else {
      const body = await res.json();
      throw new Error(body.message);
    }
  };

  return {
    siteRquirementsData,
    refetchSiteRequirements,

    learningModulesData,
    refetchLearningModules,

    noticeLatestData,
    refetchNoticeLatest,

    takeQuiz,

    SMSProvidersData,
    refetchSMSProviders,

    reportData,
    reportRefetch,
    getExclusionReports,
    getExclusionFilters,

    assignmentAccept,
    assignmentReject,
    getPassports,
    downloadPassports,
    uploadPassports,
    deletePassports,
  };
}
export const CpnwContainer = createContainer(useCpnw);
