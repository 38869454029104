import React from "react";
import PropTypes from "prop-types";
import styles from "./DashboardOIGSAMItem.module.css";

const DashboardOIGSAMItem = ({ item, itemWidth }) => {
  const { scanDate, nameInput, agency, scanResult, message, nameFound } = item;

  const {
    scanDateWidth,
    nameInputWidth,
    agencyWidth,
    scanResultWidth,
    messageWidth,
    nameFoundWidth,
  } = itemWidth;

  const itemStyle = {
    width: "calc(100% / 6)", // default width for each item
  };

  return (
    <div className={styles.container}>
      <div
        className={styles.item}
        style={{ width: scanDateWidth || itemStyle.width }}
      >
        <span>{scanDate}</span>
      </div>
      <div
        className={styles.item}
        style={{ width: nameInputWidth || itemStyle.width }}
      >
        <span>{nameInput}</span>
      </div>
      <div
        className={styles.item}
        style={{ width: agencyWidth || itemStyle.width }}
      >
        <span>{agency}</span>
      </div>
      <div
        className={styles.item}
        style={{ width: scanResultWidth || itemStyle.width }}
      >
        <span>{scanResult}</span>
      </div>
      <div
        className={styles.item}
        style={{ width: messageWidth || itemStyle.width }}
      >
        <span>{message}</span>
      </div>
      <div
        className={styles.item}
        style={{ width: nameFoundWidth || itemStyle.width }}
      >
        <span>{nameFound}</span>
      </div>
    </div>
  );
};

DashboardOIGSAMItem.propTypes = {
  item: PropTypes.shape({
    scanDate: PropTypes.string.isRequired,
    nameInput: PropTypes.string.isRequired,
    agency: PropTypes.string.isRequired,
    scanResult: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    nameFound: PropTypes.string.isRequired,
  }).isRequired,
  itemWidth: PropTypes.shape({
    scanDateWidth: PropTypes.number,
    nameInputWidth: PropTypes.number,
    agencyWidth: PropTypes.number,
    scanResultWidth: PropTypes.number,
    messageWidth: PropTypes.number,
    nameFoundWidth: PropTypes.number,
  }).isRequired,
};

export default DashboardOIGSAMItem;
