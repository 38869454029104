import { useEffect, useState, useMemo } from 'react';
import UsersTable from 'components/DashboardUsersTable/UsersTable';
import { AdminContainer } from 'hooks/useAdmin';
import styles from './AccountRequests.module.css';

const AccountRequests = () => {
	const { adminUsersData, updateUserAccountRequest, updateUserReturned } = AdminContainer.useContainer();

	const newUsers = useMemo(() => {
		return adminUsersData?.Pending ?? [];
	}, [adminUsersData]);

	const returnedUsers = useMemo(() => {
		return adminUsersData?.Returned ?? [];
	}, [adminUsersData]);

	const reasons = useMemo(() => {
		return adminUsersData?.ReturnReasonsListItems ?? [];
	}, [adminUsersData]);

	const [newUsersState, setNewUsers] = useState([]);
	const [returnedUsersState, setRetunredUsers] = useState([]);
	const [reasonsState, setReasons] = useState([]);

	const handleUpdateNewUsers = async updateUserSelections => {
		if (updateUserSelections === null || updateUserSelections === undefined)
			return;

		const convertedData = Object.keys(updateUserSelections)
			.filter(key => updateUserSelections[key].selectedValue && updateUserSelections[key].selectedValue !== 'clear')
			.map(key => ({
				pending: updateUserSelections[key].selectedValue,
				id: parseInt(key),
				reasonSelection: updateUserSelections[key].reason,
				reasonAdditional: updateUserSelections[key].additionalReason
			}));


		if (convertedData.length === 0) return;

		const usersUpdateData = await updateUserAccountRequest(convertedData);

		setNewUsers(usersUpdateData?.Pending ?? []);
	};

	const handleUpdateReturnedUsers = async updateUserSelections => {
		if (updateUserSelections === null || updateUserSelections === undefined)
			return;

		const convertedData = Object.keys(updateUserSelections)
			.filter(key => updateUserSelections[key].selectedValue && updateUserSelections[key].selectedValue !== 'clear')
			.map(key => ({
				returned: updateUserSelections[key].selectedValue,
				id: parseInt(key),
				reasonSelection: updateUserSelections[key].reason,
				reasonAdditional: updateUserSelections[key].additionalReason
			}));

		if (convertedData.length === 0) return;

		const usersUpdateData = await updateUserReturned(convertedData);

		setRetunredUsers(usersUpdateData?.Returned ?? []);
	};

	useEffect(() => {
		if (newUsers && newUsers.length > 0) {
			setNewUsers(newUsers);
		} else {
			setNewUsers([]);
		}

		// Cleanup function
		return () => {
			setNewUsers([]);
		};
	}, [newUsers]);

	useEffect(() => {
		if (returnedUsers && returnedUsers.length > 0) {
			setRetunredUsers(returnedUsers);
		} else {
			setRetunredUsers([]);
		}

		// Cleanup function
		return () => {
			setRetunredUsers([]);
		};
	}, [returnedUsers]);

	useEffect(() => {
		if (reasons && reasons.length > 0) {
			setReasons(reasons);
		} else {
			setReasons([]);
		}
	}, [reasons])

	const returnedDescriptionRender = (
		<p>When you return an account request to a user for revisions it will appear here. If a user doesn't respond, you may decline the request which deletes the record from the database.</p>
	);

	return (
		<>
			<section className={styles.newRequestSection}>
				<h3 className={styles.sectionHeading}>New Requests</h3>
				<UsersTable
					items={newUsersState ?? []}
					onUpdate={handleUpdateNewUsers}
					tableType='newRequest'
					reasonsData={reasonsState}
				/>
			</section>
			<section className={styles.returnedSection}>
				<h3 className={styles.sectionHeading}>Returned for Revisions</h3>
				<UsersTable
					items={returnedUsersState ?? []}
					onUpdate={handleUpdateReturnedUsers}
					description={returnedDescriptionRender}
					tableType='returned'
					reasonsData={reasonsState}
				/>
			</section>
		</>
	);
};

export default AccountRequests;
