import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";

import { Button } from "@cpnw/ui";
import styles from "./PassportMessageForm.module.css";

export default function PassportMessageForm({ messages = [], onSend }) {
  //text area toggle
  const [textAreaOpen, setTextAreaOpen] = useState(false);
  //text area content
  const [textAreaContent, setTextAreaContent] = useState("");
  //validation min char
  const [minCharValidation] = useState(0);
  //validation errors
  const [errors, setErrors] = useState([]);
  //ref for textarea
  const replyRef = useRef(null);
  //ref for errors
  const errorsRef = useRef(null);
  //last message ref
  const lastMessageRef = useRef(null);

  const handleReplyClick = () => {
    setTextAreaOpen(!textAreaOpen);
  };

  //use effect for scrolling text area into view if textAreaOpen is true
  useEffect(() => {
    if (textAreaOpen && replyRef.current) {
      replyRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [textAreaOpen]);

  useEffect(() => {
    if (errors && errorsRef.current) {
      errorsRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [errors]);

  useEffect(() => {
    if (lastMessageRef && lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [lastMessageRef]);

  //this may need to be adjusted based on the values stored in the db
  //or the shape of the date objects in the db
  const getDateString = () => {
    let today = new Date();
    //date
    let month = today.getMonth() + 1;
    let date = today.getDate();
    let year = today.getFullYear();
    let currentDate = `${month}/${date}/${year}`;
    return currentDate;
  };

  //thi smay need to be adjusted based on the values stored in the db
  //or the shape of the time objects in the db
  const getTimeString = () => {
    let today = new Date();
    //time
    let hours = today.getHours();
    let minutes = today.getMinutes();
    let seconds = today.getSeconds();
    //this may need to be adjusted, (CPNW is a northwest company time zone should only be PST);
    let currentTime = `${hours}:${minutes}:${seconds} PST`;
    return currentTime;
  };

  const handleDelete = () => {
    setTextAreaContent(null);
    setTextAreaOpen(!textAreaOpen);
    //clear errors
    setErrors([]);
  };

  const handleSendClick = (event) => {
    //handles traditional message send
    if (formValidation(textAreaContent)) {
      //clear errors
      setErrors([]);

      onSend(textAreaContent);
      setTextAreaContent("")
      setTextAreaOpen(false)
    } else {
      //error handle here
      console.log("Errors in textarea - form not submitted");
    }
  };

  const formValidation = (content) => {
    //validate form here
    let newErrors = [];
    if (!content) {
      newErrors.push(
        `Text area must not be empty. Length must be greater than ${minCharValidation}`
      );
    } else if (content.length < minCharValidation) {
      newErrors.push(
        `Text area must contain at least ${minCharValidation} characters.`
      );
    }
    // if(content.toLowerCase().includes('josh')) {
    //     newErrors.push('Text area must not contain the word josh')
    // }
    console.group("Errors:");
    console.log(newErrors);
    console.groupEnd();

    if (newErrors.length > 0) {
      setErrors(newErrors);
      return false;
    } else {
      return true;
    }
  };

  // useEffect(() => {
  //   console.log(`text area open? - ${textAreaOpen}`);
  // }, [textAreaOpen]);

  return (
    <>
      <div className={styles.message_form_container}>
        <h5>Messages:</h5>
        {messages.length > 0 ? (
          <div className={styles.message_list_container}>
            {messages.map((item, index) => {
              return (
                <div key={index}>
                  <p className={styles.sender_row}>
                    From: {item.name} on {item.date}
                  </p>
                  <div className={styles.message_reply_container}>
                    <p
                      ref={
                        messages.length - 1 === index ? lastMessageRef : null
                      }
                    >
                      {item.message}
                    </p>
                    {/* HR Break on everything except last item */}
                    {!(messages.length - 1 === index) && <hr />}
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className={styles.no_message_container}>
            <p>You have not recieved any messages yet.</p>
          </div>
        )}

        <div className={styles.message_reply_col}>
          {!textAreaOpen && (
            <p
              className={styles.custom_reply}
              onClick={() => handleReplyClick()}
            >
              Reply
            </p>
          )}
          {textAreaOpen && (
            <div ref={replyRef} className={styles.reply_area}>
              <textarea
                name="messageContent"
                className={styles.text_area}
                onChange={(e) => setTextAreaContent(e.target.value)}
              ></textarea>
              {textAreaOpen ? (
                <div className={styles.buttons_row}>
                  <Button
                    text={"SEND"}
                    type={"submit"}
                    className={styles.custom_button}
                    onClick={handleSendClick}
                  />
                  <span
                    className={styles.custom_delete_button}
                    onClick={() => handleDelete()}
                  >
                    Delete
                  </span>
                </div>
              ) : null}
              {/* ERRORS */}
              {errors.length > 0 && (
                <div className={styles.errors_container}>
                  <ul ref={errorsRef} className={styles.errors_list}>
                    {errors.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

PassportMessageForm.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      date: PropTypes.string,
      message: PropTypes.string,
    })
  ),
  onSend: PropTypes.func.isRequired,
};
