import React from "react";
import styles from "./DashboardPassportTableKey.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHourglassHalf,
  faMinus,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-tooltip";

//Basic modal with a blue background. Modal action bar can be used with modal to add btns.
const DashboardPassportTableKey = ({ onClick }) => {
  return (
    <>
      <div className={styles.tableKeyContainer}>
        <ul className={styles.keyContainer}>
          <li>Key: </li>
          <li>
            {" "}
            <FontAwesomeIcon
              icon={faPlus}
              className={styles.faPlusIcon}
              size="sm"
              id="complete-tooltip"
            />{" "}
            Complete
            <Tooltip style={{ backgroundColor: "#007e8d", color: "white"}} place="bottom"  anchorSelect="#complete-tooltip">
              Indicates a completed Module or PASSED OIG/SAM check
            </Tooltip>
          </li>
          <li>
            <FontAwesomeIcon
              icon={faMinus}
              className={styles.faMinusIcon}
              size="sm"
              id="incomplete-tooltip"
            />{" "}
            Incomplete
            <Tooltip style={{ backgroundColor: "#007e8d", color: "white"}} place="bottom"  anchorSelect="#incomplete-tooltip">
              Indicates an incomplete Module or FAILED OIG/SAM check
            </Tooltip>
          </li>
          <li>
            <FontAwesomeIcon
              icon={faHourglassHalf}
              className={styles.faHourGlassIcon}
              size="sm"
              id="expire-tooltip"
            />{" "}
            Expiring
            <Tooltip style={{ backgroundColor: "#007e8d", color: "white"}} place="bottom"  anchorSelect="#expire-tooltip">
              Indicates a module that is going to expire
            </Tooltip>
          </li>
        </ul>
        <div>
          <a
            data-reveal-id="reportCol"
            onClick={() => onClick()}
            className={styles.clickHereAnchor}
          >
            {" "}
            Click here to view{" "}
          </a>{" "}
          column names.
        </div>
      </div>
      <hr />
    </>
  );
};

DashboardPassportTableKey.propTypes = {};

export default DashboardPassportTableKey;
