import React from "react";
import classNames from "classnames";
import styles from "./DashboardClinicalRosterItem.module.css";
import PropTypes from "prop-types";

const DashboardClinicalRosterItem = ({
  onSelect,
  selected,
  item,
  onClickName,
}) => {
  const handleNameClick = (contact) => {
    onClickName(contact);
  };

  const handleCheckboxChange = () => {
    onSelect(item);
  };

  function formatDate(dateString) {
    // Check if item.startDate is not null or undefined
    if (dateString) {
      const utcDateString = dateString;

      // Convert the UTC date to a Date object
      const date = new Date(utcDateString);

      // Extract components
      const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      const day = String(date.getUTCDate()).padStart(2, '0');
      const year = date.getUTCFullYear();

      // Format to "MM-dd-yyyy"
      const formattedDate = `${month}/${day}/${year}`;
      return formattedDate;
    }
  }

  return (
    <tr
      key={`${item.id}`}
      className={classNames([styles.rosterItem], {
        [styles.selectedBackground]: selected,
      })}
    >
      <td className={styles.checkbox}>
        <input
          type="checkbox"
          checked={selected}
          onChange={handleCheckboxChange}
        />
      </td>
      <td>
        <a
          onClick={() => handleNameClick(item.contact)}
          className={classNames(
            styles.nameContentMain,
            selected ? styles.selectedNameContent : styles.nameContent
          )}
        >
          {item.name}
        </a>
      </td>
      <td>{item.location}</td>
      <td>{formatDate(item.startDate)}</td>
      <td>{formatDate(item.endDate)}</td>
      <td>{item.school}</td>
      <td>{item.program}</td>
      <td>{item.type}</td>
      <td style={{ display: "none" }}>{item.id}</td> {/* Hidden ID field */}
    </tr>
  );
};

DashboardClinicalRosterItem.propTypes = {
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  item: PropTypes.shape({
    id: PropTypes.number.isRequired, // Define the id field in the item shape
    name: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    school: PropTypes.string.isRequired,
    program: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
};

export default DashboardClinicalRosterItem;
