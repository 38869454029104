import React, { useEffect, useMemo, useState } from "react";
import styles from "./DocumentsPage.module.css";
import environment from "environment";
import { AdminContainer } from "hooks/useAdmin";
import { HeaderSection } from "@cpnw/ui";
import { useLocation } from 'react-router-dom';

export function DocumentsPage() {
  const location = useLocation();
  const {documentsData, refetchDocuments} = AdminContainer.useContainer()
  const [path, setPath] = useState(location.pathname);

  useEffect(() => {
    setPath(location.pathname);
  }, [location.pathname]);
  
  useEffect(()=>{
    refetchDocuments()
  },[])

  var type = path.split("/")[2]
  if(type == "AnnualMeeting"){
    type = "Annual Meeting"
  }

  const documents = useMemo(()=>{
    if(documentsData && documentsData.length > 0){
      return documentsData.find(a=>a.Type == type)?.Documents || []
    }
    return []
  }, [documentsData, type])

  return (
    <div className={styles.page}>
      <div className={styles.header}>
        <HeaderSection header={type + " Documents"} />
      </div>
      <div className={styles.pageContent}>
        {documents.map(a=>(
          <a href={a.DownloadPath} target="_blank">{a.Name}</a>
        ))}
      </div>
    </div>
  )
}

export default DocumentsPage;
