import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./DashboardSelectBoxWithValue.module.css";
import { TipBox } from "@cpnw/ui";

export default function DashboardSelectBoxWithValue({
  label,
  placeholder,
  tip,
  selected,
  onChange,
  options = [],
}) {
  const [selectedValue, setSelectedValue] = useState(selected);
  const [hover, setHover] = useState(false);

  // useEffect to update state when selected prop changes
  useEffect(() => {
    setSelectedValue(selected);
  }, [selected]);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    onChange(event.target.value);
  };

  return (
    <>
      <label htmlFor="select" className={classNames(styles.selectLabel)}>
        {label}
      </label>
      <select
        className={classNames(styles.selectBoxComponent)}
        onChange={handleChange}
        placeholder={placeholder}
        value={selectedValue}
        onMouseEnter={() => tip && setHover(true)}
        onMouseOut={() => tip && setHover(false)}
      >
        <option value="">{placeholder}</option>
        {options.map(({ key, value }, index) => (
          <option key={key} value={key}>
            {value}
          </option>
        ))}
      </select>
      {tip && (
        <div
          className={classNames(styles.tipContainer, {
            [styles.hover]: hover,
          })}
        >
          <TipBox className={styles.tipBox} tip={tip} />
        </div>
      )}
    </>
  );
}

DashboardSelectBoxWithValue.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  tip: PropTypes.string,
  selected: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
};
